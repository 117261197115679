import React from 'react';
import { RichGrid, RichHeader, RichIcon } from "../../CaazamUI";
import Moment from "moment-timezone";

const avgBasketVal = avgBasket => {
    if (avgBasket < 300) {
        return 'low';
    }
    else if (avgBasket < 500) {
        return 'medium';
    }
    else {
        return 'high';
    }
}

const totalSpendVal = tSpend => {
    if (tSpend === 0) {
        return 0;
    }
    else if (tSpend < 500) {
        return 10;
    }
    else if (tSpend < 1000) {
        return 20;
    }
    else if (tSpend < 1500) {
        return 30;
    }
    else if (tSpend < 2500) {
        return 40;
    }
    else if (tSpend < 5000) {
        return 50;
    }
    else if (tSpend < 10000) {
        return 60;
    }
    else if (tSpend < 15000) {
        return 70;
    }
    else if (tSpend < 25000) {
        return 80;
    }
    else if (tSpend < 50000) {
        return 90;
    }
    else {
        return 100;
    }
}

const FeedMetrics = props => {
    return (
        <>
            <UpperGrid {...props} />
            <br />
            <LowerGrid {...props} />
        </>
    );
}

const UpperGrid = props => {
    let { totalSpend, basketSize } = props;
    totalSpend = totalSpend || 0;
    let totalSpendStr = totalSpend.toLocaleString('en-US', { style: "currency", currency: "USD" }).split('.')[0];
    let maxBasketSizeStr = basketSize ? basketSize.max.toLocaleString('en-US', { style: "currency", currency: "USD" }).split('.')[0] : null;
    let avgBasketSizeStr = basketSize ? basketSize.avg.toLocaleString('en-US', { style: "currency", currency: "USD" }).split('.')[0] : null;
    return (
        <RichGrid>
            <RichGrid.Layout>
                <RichGrid.Box alignment="center">
                    <RichIcon interactive icon='spend-bar' value={totalSpendVal(totalSpend)} />
                    <RichHeader emphasized verticalAlign='top'>
                        Total Spend
                        <RichHeader.Subheader>
                            {totalSpendStr}
                        </RichHeader.Subheader>
                    </RichHeader>
                </RichGrid.Box>
                <RichGrid.Box alignment="center">
                    <RichIcon interactive icon='basket-selector' value={basketSize && avgBasketVal(basketSize.avg)} />
                    <RichHeader emphasized verticalAlign='top'>
                        Avg Basket
                                        <RichHeader.Subheader>
                            {avgBasketSizeStr ? avgBasketSizeStr : '—'}
                        </RichHeader.Subheader>
                    </RichHeader>
                </RichGrid.Box>
                <RichGrid.Box alignment="center">
                    <RichIcon interactive icon='basket' />
                    <RichHeader emphasized verticalAlign='top'>
                        Max Basket
                                        <RichHeader.Subheader>
                            {maxBasketSizeStr ? maxBasketSizeStr : '—'}
                        </RichHeader.Subheader>
                    </RichHeader>
                </RichGrid.Box>
            </RichGrid.Layout>
        </RichGrid>
    );
}

const LowerGrid = props => {
    return (
        <RichGrid gridSize={props.config.showSizeMetrics ? '5' : '3'}>
            <RichGrid.Layout>
                <RichGrid.Box alignment="center">
                    <RichIcon interactive icon='credit' value={props.loyaltyCredit ? `$${Math.round(props.loyaltyCredit)}` : '—'} title='Credit' /> <br />
                    <RichHeader light >Credit</RichHeader>
                </RichGrid.Box>
                <RichGrid.Box alignment="center">
                    <RichIcon interactive icon='calendar' value={props.lastPurchase && [Moment(props.lastPurchase.date).format('MMM'), Moment(props.lastPurchase.date).format('YYYY')]} /><br />
                    <RichHeader light >Last Purchase</RichHeader>
                </RichGrid.Box>

                {props.config.showSizeMetrics
                    ?
                    <React.Fragment>
                        <RichGrid.Box alignment="center">
                            <RichIcon interactive icon='bottomwear' value={props.customerSizes && props.customerSizes.bottom && props.customerSizes.bottom} /><br />
                            <RichHeader light >Bottom</RichHeader>
                        </RichGrid.Box>
                        <RichGrid.Box alignment="center">
                            <RichIcon interactive icon='topwear' value={props.customerSizes && props.customerSizes.top && props.customerSizes.top} /><br />
                            <RichHeader light >Top</RichHeader>
                        </RichGrid.Box>
                        <RichGrid.Box alignment="center">
                            <RichIcon interactive icon='shoe' value={props.customerSizes && props.customerSizes.shoes && props.customerSizes.shoes.slice(0, 2)} /><br />
                            <RichHeader light >Shoe</RichHeader>
                        </RichGrid.Box>
                    </React.Fragment>
                    :
                    <RichGrid.Box alignment="center">
                        <div style={{ width: '100px' }}></div>
                    </RichGrid.Box>
                }
            </RichGrid.Layout>
        </RichGrid>
    );
}

export default FeedMetrics;