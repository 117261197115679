import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Segment, Header, Image, Grid } from 'semantic-ui-react';

const gridRowStyle = { paddingBottom: '10px', paddingTop: '10px' };

class ProductSearchResults extends Component {
    state = {}
    static propTypes = {
        results: PropTypes.array.isRequired,      //results: The query results returned from the API
    };

    getAttachedMode = (index) => {
        const last = this.props.results.length - 1;
        if (last === 0 || index === last)
            return 'bottom'
        return true;
    }

    viewProduct = product => {
        this.props.onResultSelected({ pid: product.productId });
    }

    render() {
        let { results } = this.props;
        return (
            <>
                {results.map((result, index) => {
                    return (
                        <Segment key={index} attached={this.getAttachedMode(index)} onClick={() => this.viewProduct(result)} style={{ cursor: "pointer" }}>
                            <Grid>
                                <Grid.Row style={gridRowStyle}>
                                    <Grid.Column width="14" verticalAlign="middle">
                                        <Header size="small">
                                            {result.productName}
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column width="2" verticalAlign="middle" textAlign='right'>
                                        <Image src={result.productImageUrl} size="mini" />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    )
                })}
            </>
        );
    }
}

export default ProductSearchResults;