import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import ReactGA from 'react-ga';

import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';

const SignInPage = () => (
  <div>
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;
    
    event.preventDefault();

    this.props.firebase.doSignInUserWithEmailAndPassword(email, password)
      .then(userCredential => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
        ReactGA.set({ userId: userCredential.user.uid });
        ReactGA.event({
          category: 'User',
          action: 'sign_in'
        });
      })
      .catch(error => {
        this.setState({ error });
      });


  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';

    return (
      <Grid centered columns={2} padded doubling style={{height:'80vh'}} verticalAlign='middle'>
        <Grid.Column>
          <Header as="h2" textAlign="center">
            Sign In
          </Header>
          <Segment>
            <Form size="large" onSubmit={this.onSubmit}>
              <Form.Input
                name="email"
                value={email}
                fluid
                icon="user"
                iconPosition="left"
                placeholder="email address"
                onChange={this.onChange}
              />
              <Form.Input
                name="password"
                value={password}
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                onChange={this.onChange}
              />

              <Button color="orange" fluid size="large" disabled={isInvalid} type="submit">
                Sign In
              </Button>
            </Form>            
          </Segment>
          {error && <Message error>{error.message}</Message>}
          <PasswordForgetLink/>
        </Grid.Column>
      </Grid>

    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };


