import React from 'react';

import { Button, Loader } from 'semantic-ui-react';

const withLoading = (conditionFn) => (Component) => (props) =>
    <div>
        <Component {...props} />
        {conditionFn(props) && <Loader active inline='centered'/>}
    </div>

const withPaginated = (conditionFn) => (Component) => (props) =>
    <div>
        <Component {...props} />

        <div>
            {conditionFn(props) &&
                <div>
                    <div>Something went wrong...</div>
                    <Button
                        onClick={props.onPaginatedSearch}>
                        Try Again
                    </Button>
                </div>
            }
        </div>
    </div>

const withInfiniteScroll = (conditionFn) => (Component) =>
    class WithInfiniteScroll extends React.Component {
        componentDidMount() {
            window.addEventListener('scroll', this.onScroll, false);
        }

        componentWillUnmount() {
            window.removeEventListener('scroll', this.onScroll, false);
        }

        onScroll = () => conditionFn(this.props) && this.props.onPaginatedSearch();

        render() {
            return <Component {...this.props} />;
        }
    }

export { withLoading, withPaginated, withInfiniteScroll };