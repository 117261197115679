import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Button, Modal, Input, Label, Icon, Header, Segment } from "semantic-ui-react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ReportStatus, ReportStatusName } from '../../../constants/reports';

export default class StatusChangeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      urlCopied: false,
      isWorking: false,
      nextStatus: !props.revertStatus ? props.data.nextStatus() : props.data.previousStatus(),
      isComplete: false,
    }
  }
  /* 
    static getDerivedStateFromProps(nextProps, prevState) {
      return {
        nextStatus: !nextProps.revertStatus ? nextProps.data.nextStatus() : nextProps.data.previousStatus(),
      };
    } */

  static propTypes = {
    open: PropTypes.bool.isRequired,               // should modeal be open
    reportId: PropTypes.string.isRequired,         // the report id
    data: PropTypes.object.isRequired,             // the report meatdata
    handleStatusChange: PropTypes.func.isRequired, // status change was submitted
    handleCancel: PropTypes.func.isRequired,       // status change was cancelled
    revertStatus: PropTypes.bool,                  //revertStatus: Revert the status instead of "forward" it
    children: PropTypes.node,                      //children: Components to show inside the modal
  }

  inputOnChange = (e, data) => {
    this.setState({ inputValue: data.value });
  }

  handleCopyUrl = () => {
    this.setState({ urlCopied: true });
  }

  onStatusChangeCallback = error => {
    this.setState({ isWorking: false, isComplete: true });
    error && this.props.handleCancel();
  }

  action_onClick = () => {
    this.setState({ isWorking: true });
    this.props.handleStatusChange(this.props.reportId, this.state.nextStatus, this.state.inputValue, this.onStatusChangeCallback);
  }

  render() {
    let { open, data, handleCancel, revertStatus, children } = this.props;
    const { isWorking, nextStatus, isComplete } = this.state;
    let status = data.status;

    let showInput = !revertStatus && status === ReportStatus.open;
    let isInvalid = showInput && this.state.inputValue === '';

    return (
      <Modal
        size={children && !isComplete ? 'large' : 'tiny'}
        open={open}
        onClose={handleCancel}
        closeOnEscape={!isComplete}
        closeOnDimmerClick={!isComplete}
      >
        {!revertStatus && !isComplete && <Modal.Header>
          <h2 style={{ paddingBottom: '0px', marginBottom: '0px' }}>{data.statusHeader()}</h2>
          {children && <h4 style={{ marginTop: '0px', marginBottom: '4px' }}>{'Report for ' + data.name()}</h4>}
          <Label basic circular color={data.statusColor()}>{ReportStatusName[status]}</Label>
          <Icon name={`arrow ${'right'}`} color='grey' />
          <Label basic circular color={data.statusColor(nextStatus)}>{ReportStatusName[nextStatus]}</Label>
        </Modal.Header>
        }
        {revertStatus && !isComplete &&
          <Modal.Header>
            <div style={{ paddingBottom: '8px' }}>{"Revert this report to its previous status?"}</div>
            <Label basic circular color={data.statusColor(nextStatus)}>{ReportStatusName[nextStatus]}</Label>
            <Icon name={`arrow ${'left'}`} color='grey' />
            <Label basic circular color={data.statusColor()}>{ReportStatusName[status]}</Label>
          </Modal.Header>
        }
        <Modal.Content scrolling>
          {!isComplete &&
            <Modal.Description>
              {!children && <p>{'Report for ' + data.name()}</p>}

              {status === ReportStatus.new && <div>
                <CopyToClipboard
                  text={data.url()}
                  onCopy={this.handleCopyUrl}>
                  <span>{data.url()}</ span>
                </CopyToClipboard>
                {this.state.urlCopied && <Label size='small'> copied </Label>}
              </div>}

              {children}

              {showInput && !children && <Input
                placeholder='Please enter your name'
                onChange={this.inputOnChange}
                error={isInvalid}
              />}

              {showInput && children &&
                <Segment>
                  <Header size="medium">Please enter your name</Header>
                  <Input
                    placeholder='Enter your name here'
                    onChange={this.inputOnChange}
                    error={isInvalid}
                  />
                </Segment>
              }
            </Modal.Description>
          }
          {isComplete &&
            <Modal.Description>
              <center>
                <Header as='h2' icon color="green">
                  <Icon name='check circle outline' />
                  Success!
                  <Header.Subheader>
                    Report status changed successfully
                  </Header.Subheader>
                </Header>
              </center>
            </Modal.Description>
          }
        </Modal.Content>
        {!isComplete &&
          <Modal.Actions>
            <Button key='cancel' basic color='red' onClick={handleCancel} disabled={isWorking}>Cancel</Button>
            <Button disabled={isInvalid || isWorking} key='ok' loading={isWorking} onClick={this.action_onClick}>{revertStatus ? 'Revert' : data.statusAction()}</Button>
          </Modal.Actions>
        }
      </Modal>)
  }
}