import { PersonRoles } from '../../../constants/roles';

const matchVisitsCandidatesToSale = (sale, visits, visitToTransactionMatchingConfig) => {
    let saleTimestamp = sale.data.timestamp.toMillis();
    // TBD: for now finding the start and end indexes for matching sales - might be more efficeint way when sales/visits is large array
    let windowStart = saleTimestamp - visitToTransactionMatchingConfig.visit_before_transaction_match;
    let windowEnd = saleTimestamp + visitToTransactionMatchingConfig.visit_after_transaction_match;

    var visitsCandidates = visits.reduce((aggResult, visit) => {
        let visitTimestamp = visit.data.timestamp.toMillis();
        if (visitTimestamp >= windowStart && visitTimestamp <= windowEnd
            && visit.data.person.role !== PersonRoles.employee) {
            aggResult.push(visit);
        }
        return aggResult;
    }, [])

    //console.log(`For sale ${sale.id} at ${sale.data.date} found ${visitsCandidates.length} candidates from visits`);
    return visitsCandidates.map(visit => ({
        id: visit.id,
        strongMatch: !!sale.data.customerId && sale.data.customerId === visit.data.person.customerId,
    }));
}

const matchSalesCandidatesToVisits = (visit, sales, visitToTransactionMatchingConfig) => {
    let visitTimestamp = visit.data.timestamp.toMillis();
    let windowStart = visitTimestamp - visitToTransactionMatchingConfig.visit_after_transaction_match;
    let windowEnd = visitTimestamp + visitToTransactionMatchingConfig.visit_before_transaction_match;

    var salesCandidates = sales.reduce((aggResult, sale) => {
        let saleTimestamp = sale.data.timestamp.toMillis();
        if (saleTimestamp >= windowStart && saleTimestamp <= windowEnd)
            aggResult.push(sale);
        return aggResult;
    }, [])

    return salesCandidates.map(sale => ({
        id: sale.id,
        strongMatch: !!visit.data.person.customerId && sale.data.customerId === visit.data.person.customerId,
    }));
}

export { matchVisitsCandidatesToSale, matchSalesCandidatesToVisits };