import React from 'react'
import Firebase from '../../../components/Firebase';
import Moment from 'moment-timezone';

import { Header, Button, Modal, Icon } from "semantic-ui-react";

import { ReportStatus } from '../../../constants/reports';
import { PermissionLevels } from '../../../constants/permissions';

const AuthorizationRejectCode = {
    reportUnavailable: "unavailable",
    alreadySubmitted: "subbed",
    unauthorizedLocation: "loc-unauth",
    statusChanged: "stat-change",
}

class ReportAuth {
    constructor() {
        this.locationPermissions = null;
        this.authStatus = { isAuthorized: false, authRejectCode: AuthorizationRejectCode.reportUnavailable };
    }

    authroize(reportData) {

        var locationPermPromise = Promise.resolve();
        if (this.locationPermissions == null) {
            this.locationPermissions = {};
            locationPermPromise = Firebase.users.getAuthorizedLocations().then(locationPermissions => {
                if (locationPermissions && locationPermissions.length > 0) {
                    
                    locationPermissions.forEach(permission => {
                        this.locationPermissions[permission.id] = permission.permissionLevel;
                    });
                    return;
                }
            });
        } 

        return locationPermPromise.then(() => {
            let rLocationCode = reportData.scope.location.id;
            let permLevel = this.locationPermissions[rLocationCode];
            this.authStatus = { isAuthorized: true, authRejectCode: null };
            // if not admin and report isn't "open" or "archived" the user can't view the report
            if (permLevel !== PermissionLevels.admin && ![ReportStatus.open, ReportStatus.archived].includes(reportData.status)) {
                this.authStatus.isAuthorized = false;
                this.authStatus.authRejectCode = (reportData.status > ReportStatus.open && reportData.status < ReportStatus.archived) ? AuthorizationRejectCode.alreadySubmitted : AuthorizationRejectCode.reportUnavailable;
            }

            //Protects cross location viewing.
            //authState = { isAuthorized: Authorization.unauthorized, authRejectCode: AuthorizationRejectCode.unauthorizedLocation };
            return this.authStatus;
        })                
    }

    AuthMessage = (props) => (
        <Modal open={true} dimmer="blurring" size="mini">
            {this.authStatus.authRejectCode === AuthorizationRejectCode.alreadySubmitted && <Header icon='exclamation triangle' content='Report has been submitted' />}
            {this.authStatus.authRejectCode === AuthorizationRejectCode.reportUnavailable && <Header icon='exclamation triangle' content='Report is not available' />}
            {this.authStatus.authRejectCode === AuthorizationRejectCode.unauthorizedLocation && <Header icon='exclamation triangle' content='No access to this report' />}
            {this.authStatus.authRejectCode === AuthorizationRejectCode.statusChanged && <Header icon='exclamation triangle' content='Someone changed the status of this report' />}
            <Modal.Content>
                <Modal.Description>
                    {this.authStatus.authRejectCode === AuthorizationRejectCode.alreadySubmitted && <Header size="small">This report has already been submitted by {props.reportData.submittedBy} on {Moment(props.reportData.lastSaved.date.toDate()).format('l')}</Header>}
                    {this.authStatus.authRejectCode === AuthorizationRejectCode.reportUnavailable && <Header size="small">Come back later when the report is ready to be reviewed</Header>}
                    {this.authStatus.authRejectCode === AuthorizationRejectCode.unauthorizedLocation && <Header size="small">You are not authorized to view reports from {props.reportData.scope.location.name}</Header>}
                    {this.authStatus.authRejectCode === AuthorizationRejectCode.statusChanged && <Header size="small">Refresh the page to continue working on this report or go back to the reports list to work on another report.</Header>}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {props.action}
           </Modal.Actions>
        </Modal>
    )
}

export default ReportAuth;

export { AuthorizationRejectCode };