import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { CopyToClipboard } from 'react-copy-to-clipboard';

const inlineStyle = { display: "inline" }

class UUID extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCopied: false,
        }
    }

    static propTypes = {
        value: PropTypes.string.isRequired,      //value: The value of the uuid to show
    };

    render() {
        let { value } = this.props;
        const { isCopied } = this.state;
        return (
            <CopyToClipboard
                text={value}
                onCopy={() => this.setState({ isCopied: true })}
                style={inlineStyle}>
                <div>{value} {isCopied && <Icon color="green" name="clipboard check" />}</div>
            </CopyToClipboard>
        );
    }
}

export default UUID;