import React, { Component } from 'react';
import { Segment, Header } from "semantic-ui-react";

import { compose } from "recompose";
import { withFirebase } from "../../../Firebase";

import { PropTypes } from "prop-types"

import FeedbackSummaryForVisits from './reportFeedbackSummaryForVisits';
import FeedbackSummaryForSales from './reportFeedbackSummaryForSales';
import DeletedVisits from './deletedVisits';
import { ReportType } from '../../../../constants/reports';

class FeedbackView extends Component {
    static propTypes = {
        feedbackData: PropTypes.object.isRequired,    // Report's feedback metadata
        content: PropTypes.array.isRequired,         /// The visits/sales in the report for additional data
        deletedVisits: PropTypes.object,              /// The deleted visits
        reportId: PropTypes.string.isRequired,        /// The report ID to listen to feedback from
        reportData: PropTypes.object.isRequired,      /// The report data (including type)
    }

    render() {
        let { reportData, content, deletedVisits, feedbackData, notesData } = this.props;
        let { type } = reportData;

        return (
            <Segment basic>
                <Header as="h4" color="black">Feedback</Header>
                {type === ReportType.visits_with_sales && <FeedbackSummaryForVisits visits={content} feedbackData={feedbackData} notesData={notesData} reportMetadata={reportData} />}
                {type === ReportType.sales_with_visits && <FeedbackSummaryForSales sales={content} feedbackData={feedbackData} notesData={notesData} reportMetadata={reportData} />}
                {deletedVisits &&
                    <>
                        <Header as="h4" color="red">Deleted visits</Header>
                        <DeletedVisits visits={deletedVisits} />
                    </>
                }
            </Segment>
        );
    }
}

export default compose(
    withFirebase
)(FeedbackView);