import React from 'react';
import { Redirect } from 'react-router-dom';

import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';

const Landing = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      authUser ?
      <div>
        <h1>Landing</h1>
      </div> 
      : <Redirect to={ROUTES.HOME}/>
    )}
  </AuthUserContext.Consumer>
);
  
export default Landing;