const sortDate = (a, b) => {
    if (a.data.reportDate < b.data.reportDate) {
        return -1;
    }
    if (a.data.reportDate > b.data.reportDate) {
        return 1;
    }
    return 0;
}

const sortLocation = (a, b) => {
    if (a.data.reportLocation.name < b.data.reportLocation.name) {
        return -1;
    }
    if (a.data.reportLocation.name > b.data.reportLocation.name) {
        return 1;
    }
    return 0;
}

const sortSubmittedBy = (a, b) => {
    if (a.data.reportSubmitter < b.data.reportSubmitter) {
        return -1;
    }
    if (a.data.reportSubmitter > b.data.reportSubmitter) {
        return 1;
    }
    return 0;
}

const sortCustomerName = (a, b) => {
    if (a.data.customerName < b.data.customerName) {
        return -1;
    }
    if (a.data.customerName > b.data.customerName) {
        return 1;
    }
    return 0;
}

const sortSale = (a, b) => {
    if (!a.data.date) {
        return 1;
    }
    if (!b.data.date) {
        return -1;
    }
    if (a.data.date < b.data.date) {
        return -1;
    }
    if (a.data.date > b.data.date) {
        return 1;
    }
    return 0;
}

export { sortDate, sortLocation, sortSubmittedBy, sortCustomerName, sortSale };