import React, { Component } from 'react';
import { Segment, Loader, Message } from 'semantic-ui-react';

import { compose } from "recompose";
import { withAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";
import CompareLayout from './compareLayout';

class CompareVisits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visit_A: null,
            visit_B: null,
            isError: false,
            errorMsg: null
        }
        this.visitID_A = this.props.match.params.visitID_A;
        this.visitID_B = this.props.match.params.visitID_B;
    }

    componentDidMount() {
        let firebase = this.props.firebase;
        let options = { includeIdentifications: true };
        firebase.visits.fetchThis(this.visitID_A, options).then(visit_A => {
            this.setState({ visit_A: visit_A });
            return firebase.visits.fetchThis(this.visitID_B, options);
        }).then(visit_B => {
            this.setState({ visit_B: visit_B, isError: false, errorMsg: null });
        }).catch(error => {
            this.setState({ isError: true, errorMsg: error.message })
        });
    }

    render() {
        const { visit_A, visit_B, isError, errorMsg } = this.state;
        return (
            <Segment basic>
                <Loader active={(!visit_A || !visit_B) && !isError} />
                {isError && <ErrorMessage errorMsg={errorMsg} />}
                {!isError && visit_A && visit_B && <CompareLayout visit_A={visit_A} visit_B={visit_B} />}
            </Segment>
        );
    }
}

const ErrorMessage = props => (
    <Message negative>
        <Message.Header>
            OOPS! There was an error{" "}
            <span role="img" aria-label="sorry face">
                😕
            </span>
        </Message.Header>
        <Message.Content>
            {props.errorMsg}
        </Message.Content>
    </Message>
)

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withFirebase
)(CompareVisits);