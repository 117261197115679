import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./index.css";
import { compose } from "recompose";
import update from 'immutability-helper';

import { withAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";
import { withRouter } from "react-router-dom";
import { Icon, Table, Menu } from 'semantic-ui-react';
import ReportsTable from './reportsTable';

import { ReportStatus } from '../../../constants/reports';

import { ErrorMessage, NoResults } from '../common/reportListCommon';

const applyPagnationResult = result => prevState => ({
    reports: [...prevState.reports, ...result.reports],
    nextPage: result.nextPage,
    isError: false,
    isLoading: false
});

const applySetError = error => prevState => ({
    isError: true,
    isLoading: false,
    errorMsg: error,
});


class ArchivedReportsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            nextPage: null,
            isLoading: true,
            isError: false,
            errorMsg: "",
            showNewRepDialog: false,
            fieldError: { isDateError: false, isLocationError: true },
        }
        this.locations = {};
    }
    
    componentDidMount() {
        let { firebase } = this.props;
        var options = { count: 50  };
        firebase.users.getAuthorizedLocations().then(locationPermissions => {
            if (!locationPermissions || locationPermissions.length === 0) {
                throw new Error('User has no permissions for reports');
            } else {
                options.locations = locationPermissions.map(location => location.id);
                options.statusFilter = [ReportStatus.archived];
            }

            return firebase.reports.fetch(options);
        }).then(data => {
            this.setState(applyPagnationResult(data));
        }).catch(error => {
            console.error('Error loading reports list', error);
            this.setState(applySetError(error.message));
        });

        firebase.locations.fetch().then(locs => {
            locs.forEach(location => {
                this.locations[location.id] = location.data;
            });
        }).catch(error => {
            console.error(error);
            this.setState(applySetError(error.message));
        });
    }
    
    onPaginationSubmit = () => {
        if (!!this.state.nextPage) {
            this.setState({ isLoading: true });
            this.state
                .nextPage()
                .then(results => {
                    this.setState(applyPagnationResult(results));
                })
                .catch(error => {
                    console.error("Error fetching visits", error);
                    this.setState(applySetError(error.message));
                });
        }
    };

    statusRevertAction = (id, data, newStatus, onCompleteCallback) => {

        return this.props.firebase.reports.revert(id, newStatus).then(() => {
            let changeIndex = this.state.reports.findIndex(report => report.id === id);
            if (changeIndex >= 0) {
                let reportData = this.state.reports[changeIndex];
                reportData.data.status = newStatus;               
                let modifiedReportsArray = update(this.state.reports, { [changeIndex]: { data: { $set: reportData } } });
                onCompleteCallback();
                this.setState({ reports: modifiedReportsArray });
                return;
            } else {
                throw new Error(`Cannot revert status of report ${id} - report not found`);
            }
        }).catch(error => {
            onCompleteCallback(error);
            this.setState({ isError: true, errorMsg: error.message });
        });

    }

    flagReportAction = (reportId, message, remove) => {
        this.props.firebase.reports.flag(reportId, message, remove).then(flagState => {
            let index = this.state.reports.findIndex(r => r.id === reportId);
            if (index >= 0) {
                let modifiedReportsArray = update(this.state.reports, { [index]: { data: { flag: { $set: flagState.flag } } } });
                this.setState({ reports: modifiedReportsArray });
            }
        });
    }

    render() {
        const { nextPage, isLoading, isError, showNewRepDialog, reports } = this.state;
        return (
            <div>
                <br />
                {isError && (<ErrorMessage errorMsg={this.state.errorMsg} />)}
                <ReportsTable
                    reports={reports}
                    onStatusRevert={this.statusRevertAction}
                    isLoading={this.state.isLoading}
                    nextPage={nextPage}
                    onPaginatedSearch={this.onPaginationSubmit}
                    flagReport={this.flagReportAction}
                />

                {(reports.length === 0 && !isLoading && !isError) &&
                    <NoResults
                        header="No Archived Reports"
                        content='Once a report is archived it will be displayed here' />}

                <Table basic="very" attached="bottom">
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4'>
                                <Menu pagination>
                                    <Menu.Item as='a' disabled={!this.state.nextPage} onClick={this.onPaginationSubmit} icon>
                                        <Icon name='chevron down' />
                                    </Menu.Item>
                                </Menu>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
        );
    }
}



const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withRouter,
    withFirebase
)(ArchivedReportsList);