import React, { Component } from "react";

import { compose } from "recompose";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";

import { VisitsFeedTable } from "../Visit";
import "./home.css";
import { Header, Grid, Segment, Dropdown } from "semantic-ui-react";

const applyPagnationResult = result => prevState => ({
  visits: [...prevState.visits, ...result.entries],
  nextPage: result.nextPage,
  isError: false,
  isLoading: false
});

const applyNewVisitsResult = entries => prevState => ({
  visits: [...entries, ...prevState.visits],
  isError: false,
  isLoading: false
});

const applySetError = () => prevState => ({
  isError: true,
  isLoading: false
});

const EmptyTableView = props => {
  return (
    <Grid>
      <Grid.Column textAlign="center">
        <Segment>
          <Header as="h3">
            There are no store visits to display
            <br />
            New store visits will appear as they occur
          </Header>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visits: [],
      nextPage: null,
      isLoading: true,
      isError: false,
      locations: []
    };
  }

  componentDidMount() {
    let options = {};

    this.props.firebase.locations.fetch().then(locations => {
      this.setState({ locations: locations });
      if (locations.length === 1) {
        options.locationId = locations[0].id;
      }
      return this.props.firebase.visitsFeed.fetch(10, options);
    })
      .then(results => {
        this.setState(applyPagnationResult(results));
        this.listenToVisitsFeed(results.latest, options);
      })
      .catch(error => {
        this.setState(applySetError());
      });
  }

  componentWillUnmount() {
    !!this.newVisitsListener && this.newVisitsListener();
  }

  listenToVisitsFeed(latest, options) {
    this.newVisitsListener = this.props.firebase.visitsFeed.listen(
      latest,
      this.onVisitsAdded,
      this.onVisitUpdate,
      this.onVisitRemove,
      error => {
        this.setState(applySetError());
      },
      options
    );
  }

  onVisitsAdded = added => {
    // TODO: depending on where user is in scroll need to show new visit toaster
    this.setState(applyNewVisitsResult(added));
  };

  onVisitUpdate = update => {
    let newVisitsState = [...this.state.visits];
    let stateChnaged = false;
    update.forEach(item => {
      let index = this.state.visits.findIndex(visit => visit.id === item.id);
      if (index < 0) {
        console.error(
          `Received visit update for visit ${
          item.id
          } which is not found in current visits array`
        );
      } else {
        newVisitsState[index] = item;
        stateChnaged = true;
      }
    });

    if (stateChnaged) {
      this.setState({ visits: newVisitsState });
    }
  };

  onVisitRemove = removed => {
    let removedVisitIds = removed.map(item => item.id);
    let newVisitsState = this.state.visits.filter(
      visit => !removedVisitIds.includes(visit.id)
    );
    this.setState({ visits: newVisitsState });
  };

  onPaginationSubmit = () => {
    if (!!this.state.nextPage) {
      this.setState({ isLoading: true });
      this.state
        .nextPage()
        .then(results => {
          this.setState(applyPagnationResult(results));
        })
        .catch(error => {
          this.setState(applySetError());
        });
    }
  };

  render() {
    const { visits, locations, nextPage, isError, isLoading } = this.state;
    let defaultDropdown =
      locations.length === 1 ? locations[0].data.name : "All locations";
    return (
      <div>
        <Segment>
          <Grid columns={2} stackable>
            <Grid.Column>
              <Header as="h1">Latest Store Visits</Header>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" textAlign="right">
              <Dropdown placeholder={defaultDropdown} search disabled />
            </Grid.Column>
          </Grid>
        </Segment>
        {(visits.length > 0 || isLoading) && (
          <VisitsFeedTable
            list={visits}
            nextPage={nextPage}
            isLoading={isLoading}
            isError={isError}
            onPaginatedSearch={this.onPaginationSubmit}
            locations={locations}
          />
        )}
        {visits.length === 0 && !isLoading && <EmptyTableView />}
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(Home);
//test
