import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import Moment from "moment-timezone";

import "react-datepicker/dist/react-datepicker.css";


const Fields = {
    status: "status",
    location: "location",
    date: "date",
    custom: "custom",
}

const FieldFormatting = {
    "status": "circle outline",
    "location": "map marker",
    "date": "calendar times",
    "submitter": "circle outline",
    "customer": "user circle outline",
    "custom": "dont"
}

class TableFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            active: !!props.default,
            selectedFilter: props.default ? props.default : (props.field === Fields.date ? null : []),
        }
        this.renderDefault = true;
        props.default && props.onFilter(this.state.selectedFilter.length > 0 ? this.state.selectedFilter : null);
    }
    static propTypes = {
        field: PropTypes.string.isRequired,      //field: The filtered field
        options: (props, propName, componentName) => {
            if ((props['field'] !== Fields.date && (props[propName] === undefined || Object.prototype.toString.call(props[propName]) !== '[object Array]'))) {
                return new Error(`Prop 'field' of value '${props['field']}' requires an options prop of type array but instead got ${typeof (props[propName])}`);
            }
        },
        onFilter: PropTypes.func.isRequired,     //onFilter: What happens when filter selected
        default: PropTypes.array,      //default: Default filters to set ON
    };

    onFilterSelected = filter => {
        if (filter) {
            let filters = this.state.selectedFilter;
            if (!filters.includes(filter)) {
                filters.push(filter);
            }
            else {
                for (let i = 0; i < filters.length; i++) {
                    if (filters[i] === filter) {
                        filters.splice(i, 1);
                    }
                }
            }
            this.setState({ open: false, active: !!(filters.length > 0), selectedFilter: filters });
            this.props.onFilter(filters.length > 0 ? filters : null);
        }
        else {
            this.setState({ open: false, active: false, selectedFilter: this.props.field === Fields.date ? null : [] });
            this.props.onFilter(null);
        }
    }

    datepicker_onChange = dateInput => {

        this.setState({ selectedFilter: dateInput, active: true, open: false });
        let date = Moment(dateInput).format('YYYY-MM-DD');
        this.props.onFilter(date);
    }

    render() {
        let { options, field } = this.props;
        const { open, active, selectedFilter } = this.state;
        return (
            <Dropdown multiple icon={<Icon name="filter" color={active ? "orange" : "grey"} onClick={() => this.setState({ open: !open })} />}>
                <Dropdown.Menu open={open}>
                    <Dropdown.Divider />
                    <Dropdown.Header content='Filter by' />
                    <Dropdown.Menu scrolling>
                        <Dropdown.Item onClick={() => this.onFilterSelected(null)}><Icon name={FieldFormatting[field]} />Clear selection</Dropdown.Item>
                        {field !== Fields.date && options.map(option => (
                            <Dropdown.Item key={option.value} {...option} onClick={() => this.onFilterSelected(option.value)} style={!!selectedFilter.includes(option.value) ? { color: "#FE9A76" } : { color: "black" }} />
                        ))}
                        {
                            field === Fields.date &&
                            <Dropdown.Item>
                                <DatePicker
                                    inline
                                    showTimeSelect={false}
                                    selected={selectedFilter}
                                    onChange={this.datepicker_onChange}
                                    maxDate={new Date()}
                                    open
                                />
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default TableFilter;