import React, { Component } from "react";
import { compose } from "recompose";

import { withAdminAuthorization } from "../Session";
import { withFirebase } from "../Firebase";

import { VisitsTable } from "../Visit";

import { Header, Grid, Segment, Form, Icon, Button, } from "semantic-ui-react";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from "moment-timezone";
import queryString from 'query-string';
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from 'react-tooltip';

import * as ROUTES from "../../constants/routes";

import './visitBrowser.css';

const allLocationsValue = 'all';

const applyPagnationResult = result => prevState => ({
    visits: [...prevState.visits, ...result.visits],
    nextPage: result.nextPage,
    isError: false,
    isLoading: false
});

const applySetError = () => prevState => ({
    isError: true,
    isLoading: false
});

const EmptyTableView = props => {
    return (
        <Grid>
            <Grid.Column textAlign="center">
                <Segment>
                    <Header as="h3">
                        No visits to display
                    </Header>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

class VisitBroswer extends Component {
    constructor(props) {
        super(props);

        let today = new Date();

        this.state = {
            visits: [],
            nextPage: null,
            isLoading: false,
            isError: false,

            locations: [],
            locationOptions: [],
            locationFilter: null,

            startDate: new Date('2018-11-01T00:00:00'),
            endDate: today,

            userFilter: queryString.parse(this.props.location.search).uuid ? queryString.parse(this.props.location.search).uuid : '',

            comapreWith: null,
            copiedLink: false,
        };
    }

    componentDidMount() {
        this.props.firebase.locations.fetch().then(locations => {
            var locationOptions = locations.map(location => ({ value: location.id, text: location.data.name }));
            if (locations.length > 1) {
                locationOptions.unshift({ value: allLocationsValue, text: 'All Locations' });
            }
            this.setState({ locations: locations, locationOptions: locationOptions, locationFilter: (locationOptions.length > 0) ? locationOptions[0].value : null });
        }).catch(error => {
            console.error("Error fetching locations", error);
            this.setState(applySetError());
        });
        if (this.state.userFilter) {
            this.fetchVisits();
        }
    }

    onPaginationSubmit = () => {

        if (!!this.state.nextPage) {
            this.setState({ isLoading: true });
            this.state
                .nextPage()
                .then(results => {
                    this.setState(applyPagnationResult(results));
                })
                .catch(error => {
                    this.setState(applySetError());
                });
        }
    };

    onLocationSelect = (event, data) => {
        this.setState({ locationFilter: data.value });
    }

    handleStartDateChange = (date) => {
        this.setState({ startDate: date });
    }

    handleEndDateChange = (date) => {
        this.setState({ endDate: date });
    }

    handleUserUuidInput = (event, data) => {
        this.setState({ userFilter: data.value });
    }

    fetchVisits = () => {
        this.setState({ visits: [], isLoading: true, copiedLink: false });

        let startDateString = Moment(this.state.startDate).format('YYYY-MM-DD');
        let endDateString = Moment(this.state.endDate).format('YYYY-MM-DD');

        let options = {
            locationId: this.state.locationFilter !== 'all' ? this.state.locationFilter : null,
            uuid: this.state.userFilter || null,
            startDate: Moment.tz(startDateString, 'America/Los_Angeles').utc().toDate(),
            endDate: Moment.tz(endDateString, 'America/Los_Angeles').endOf('day').utc().toDate(),
            includeIdentifications: true,
        }
        this.props.firebase.visits.fetch(10, options).then(results => {
            this.setState(applyPagnationResult(results));
        }).catch(error => {
            this.setState(applySetError());
            console.error('VisitsBroswer:', error);
        })
    }

    setVisitCompared = visitID => {
        this.setState({ comapreWith: visitID });
    }

    render() {
        const { visits, locations, locationOptions, locationFilter, nextPage, isError, isLoading, comapreWith, userFilter, copiedLink } = this.state;
        let now = new Date();
        const { startDate, endDate } = this.state;

        return (
            <div>
                <Segment attached="bottom">
                    <Header as="h1">Visits Browser</Header>
                    <Form>
                        <Form.Group>
                            <Form.Dropdown
                                onChange={this.onLocationSelect}
                                defaultValue="all"
                                selection
                                options={locationOptions}
                                label="Location" />
                            <Form.Field>
                                <label>From</label>
                                <DatePicker
                                    className='visitDatePicker'
                                    selected={startDate}
                                    selectsStart
                                    startDate={startDate}
                                    maxDate={now}
                                    onChange={this.handleStartDateChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>To</label>
                                <DatePicker
                                    className='visitDatePicker'
                                    selected={endDate}
                                    selectsEnd
                                    startDate={this.state.startDate}
                                    endDate={endDate}
                                    onChange={this.handleEndDateChange}
                                    minDate={startDate}
                                    maxDate={now}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Input
                                placeholder='User Uuid'
                                onChange={this.handleUserUuidInput}
                                value={userFilter}
                            />
                            <Form.Field>
                                <span>
                                    <Button color="orange" onClick={this.fetchVisits} disabled={!userFilter && (locationFilter === allLocationsValue || locationFilter === null)}> Go </Button>
                                    <CopyToClipboard text={`${window.location.protocol}//${window.location.hostname}${ROUTES.VISITS_BROWSER}?uuid=${userFilter}`} onCopy={() => this.setState({ copiedLink: true })}>
                                        <Icon disabled={!userFilter} name="chain" style={userFilter ? { cursor: "pointer" } : { cursor: "default" }} color={copiedLink ? "green" : null} data-tip={userFilter && "Copy link to clipboard"} />
                                    </CopyToClipboard>
                                    <ReactTooltip effect="solid" place="right" />
                                </span>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Segment>
                {(visits.length > 0 || isLoading) && (
                    <VisitsTable
                        visits={visits}
                        isLoading={isLoading}
                        isError={isError}
                        nextPage={nextPage}
                        onPaginatedSearch={this.onPaginationSubmit}
                        locations={locations}
                        allowComparison={true}
                        setVisitCompared={this.setVisitCompared}
                        compareWith={comapreWith}
                    />
                )}
                {visits.length === 0 && !isLoading && <EmptyTableView />}
            </div>
        );
    }
}

export default compose(
    withAdminAuthorization,
    withFirebase
)(VisitBroswer);
//test
