import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Moment from "moment-timezone";

import { Table } from 'semantic-ui-react';

class ViewMissedVisitItem extends Component {
    static propTypes = {
        missedVisitData: PropTypes.object.isRequired,      //missedVisitData: The data of the missed visit entry
    };
    render() {
        const { missedVisitData: mvData } = this.props;
        return (
            <Table.Row textAlign="center">
                <Table.Cell>{!!mvData.reportDate && Moment(mvData.reportDate.toDate()).tz(mvData.reportLocation.timezone).format('l')}</Table.Cell>
                <Table.Cell>{!!mvData.reportLocation && mvData.reportLocation.name}</Table.Cell>
                <Table.Cell>{mvData.submittedBy}</Table.Cell>
                <Table.Cell>{mvData.customerName}</Table.Cell>
                <Table.Cell>{!!mvData.date && !!mvData.reportLocation && Moment(mvData.date).tz(mvData.reportLocation.timezone).format('l LT')}</Table.Cell>
                <Table.Cell>{mvData.note}</Table.Cell>
            </Table.Row>
        );
    }
}

export default ViewMissedVisitItem;