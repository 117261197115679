const ReportStatus = {
    new: '00_new',
    open: '10_open',
    complete: '20_complete',
    archived: '30_archived',
}

const ReportType = {
    visits_with_sales: 'visits_with_sales',
    sales_with_visits: 'sales_with_visits',
}

const ReportStatusName = {
    '00_new': 'new',
    '10_open': 'open',
    '20_complete': 'complete',
    '30_archived': 'archived',
}

const ReportStatusColor = {
    '00_new': 'grey',
    '10_open': 'orange',
    '20_complete': 'green',
    '30_archived': 'black',
}

// TODO: depricate this when new reports are added to the table using a listener.
const reportDateSorter = (a, b) => {
    let aDate = a.data.scope.timeScope.from.toMillis(), bDate = b.data.scope.timeScope.from.toMillis();
    let diff = bDate - aDate;
    if (diff !== 0) {
        return diff;
    } else {
        if (a.data.scope.location < b.data.scope.location)
            return -1;
        else if (a.data.scope.location > b.data.scope.location)
            return 1;
        else
            return 0;
    }
}



export { ReportStatus, ReportType, ReportStatusName, ReportStatusColor, reportDateSorter };