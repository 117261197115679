import app from 'firebase/app';
import 'firebase/firestore';

const COLL_NAME_VISITS = 'visits';
const COLL_NAME_HISTORY = "visitHistory";
const COLL_NAME_OPS = "visitOps";

class VisitsData {
  constructor() {
    this.db = app.firestore().collection(COLL_NAME_VISITS);
    this.historydb = app.firestore().collection(COLL_NAME_HISTORY);
    this.opsdb = app.firestore().collection(COLL_NAME_OPS);
  }

  fetchLatestByUuid = uuid => {
    let query = this.db;
    return query.where('person.uuid', '==', uuid)
      .orderBy("timestamp", "desc")
      .get()
      .then(snapshot => {
        if (snapshot.docs.length > 0)
          return Promise.resolve(snapshot.docs[0].id);
        else
          return Promise.resolve(null);
      });
  }

  fetchThis = (visitID, options) => {
    let visitObj = {};
    let query = this.db;

    return query.doc(visitID).get().then(doc => {
      if (doc.exists) {
        visitObj = { id: doc.id, data: doc.data() };
        if (options && options.includeIdentifications) {
          let idents = this.getIdentifications(visitID);
          return Promise.resolve(idents);
        } else {
          return Promise.resolve({});
        }
      }
      else {
        throw new Error(`"${visitID}" is not a valid visit ID`)
      }
    }).then(idents => {
      visitObj.data.identifications = idents;
      return visitObj;
    }).catch(error => {
      console.error(`Error fetchThis():`, error);
      return Promise.reject(error);
    });
  }

  fetch = (count, options) => {
    return this._fetchPaginated(count, options, null);
  }

  _fetchPaginated = (count, options, cursor) => {
    var nextPage = null, latest;
    var entries = [];
    let query = this.db;

    if (options && options.uuid) {
      query = query.where('person.uuid', '==', options.uuid);
    } else if (options && options.locationId) {
      query = query.where('location.id', '==', options.locationId);
    }

    if (options && options.startDate) {
      query = query.where('timestamp', '>=', options.startDate);
    }

    if (options && options.endDate) {
      query = query.where('timestamp', '<=', options.endDate);
    }

    if (options && options.descendingOrder) {
      query = query.orderBy('timestamp', 'desc');
    }
    else {
      query = query.orderBy('timestamp', 'asc');
    }

    if (cursor) {
      query = query.startAfter(cursor);
    }

    if (count) {
      query = query.limit(count);
    }

    return query.get().then(snapshot => {
      entries = snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
      if (snapshot.size === count) {
        let lastItem = snapshot.docs[snapshot.size - 1];
        nextPage = () => this._fetchPaginated(count, options, lastItem);
      }
      latest = snapshot.docs[0];
      if (options && options.includeIdentifications) {
        let idPromises = entries.map(entry => this.getIdentifications(entry.id));
        return Promise.all(idPromises);
      } else {
        return Promise.all([]);
      }
    }).then(idResults => {
      if (idResults && idResults.length > 0) {
        entries.forEach((entry, index) => {
          entry.data.identifications = idResults[index];
        })
      }

      return { visits: entries, nextPage: nextPage, latest: latest };
    })
      .catch(error => {
        console.error(`Error fetchPaginatedVisits():`, error);
        return Promise.reject(error);
      });

  }

  listenToVisits(options, onVisitsUpdate, onError) {

    let query = this.db;
    let _options = Object.assign({},options);

    if (_options.uuid) {
      query = query.where('person.uuid', '==', _options.uuid);
    } else if (_options.locationId) {
      query = query.where('location.id', '==', _options.locationId);
    }

    if (_options.startDate) {
      query = query.where('timestamp', '>=', _options.startDate);
    }

    if (_options.endDate) {
      query = query.where('timestamp', '<=', _options.endDate);
    }

    if (_options.descendingOrder) {
      query = query.orderBy('timestamp', 'desc');
    }

    if (_options.count) {
      query = query.limit(_options.count);
    }

    let listener = query.onSnapshot(querySS => {
      var updates = [];
      var identificationPromises = [];
      querySS.docChanges().forEach(change => {

        updates.push({
          id: change.doc.id,
          data: change.doc.data(),
          type: change.type,
        });

        if (_options.includeIdentifications) {
          if (change.type === 'added' || change.type === 'modified') {
            identificationPromises.push(this.getIdentifications(change.doc.id));
          } else { //change.type === 'modified'
            identificationPromises.push(Promise.resolve());
          }
        }

      })
      return Promise.all(identificationPromises).then(idResults => {
        idResults.forEach((res, index) => {
          updates[index].data.identifications = res;
        })
        onVisitsUpdate(updates);
      })
    }, onError);

    return listener;    
  }

  getIdentifications(visitId) {
    return this.db.doc(visitId).collection('visitIdentifications').get().then(querySnapshot => {
      return querySnapshot.docs.map(doc => doc.data());
    })
      .catch(error => {
        throw error;
      });
  }

  getSalesCandidates(visitId) {
    return this.db.doc(visitId).collection('visitSalesCandidates').get().then(querySnapshot => {
      return querySnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
    })
      .catch(error => {
        throw error;
      });
  }

  listenToDeletedVisits = (scope, onVisit) => {
    return this.historydb
      .where('location.id', '==', scope.location.id)
      .where('timestamp', '>=', scope.timeScope.from)
      .where('timestamp', '<=', scope.timeScope.to)
      .onSnapshot(qSnap => {
        let delVisits = {}, metadataPromises = [];
        qSnap.forEach(doc => {
          delVisits[doc.id] = doc.data();
          let delOpId = doc.data().operationId;
          metadataPromises.push(this.getDeleteOpData(delOpId).then(opData => {
            return Promise.resolve({ visitId: doc.id, opData: opData });
          }))

        });

        Promise.all(metadataPromises).then(allOpData => {
          allOpData.forEach(result => {
            delVisits[result.visitId].deletedBy = result.opData.requestedBy || result.opData.metadata.requestedBy;
          })
          onVisit(delVisits);
        });
      }, error => {
        console.error('VisitsData.listenToDeletedVisits(): this user might not have permissions to visit history', error);
      });
  }

  getDeleteOpData = opId => {
    return this.opsdb.doc(opId).get().then(doc=>{
      if (doc.exists)
        return doc.data();
      
      return null;
    });
  }

  /*getOpedVisits = (reportId, operationType) => {
    let deletedVisits = [];
    let query = this.opsdb.where('reportId', '==', reportId).where('operationCode', '==', operationType);
    return query.get().then(snapshot => {
      deletedVisits = snapshot.docs.map(doc => ({ meta: doc.data() }));
      return deletedVisits;
    }).then(() => {
      let historyData = deletedVisits.map(operation => this.fetchThisFromHistory(operation.meta.visitId, { includeIdentifications: true }));
      return Promise.all(historyData);
    }).then(historyData => {
      deletedVisits.forEach((visit, index) => {
        deletedVisits[index].data = historyData[index];
      });
      return deletedVisits;
    }).catch(error => {
      console.error(error);
      throw error;
    });
  }*/

  /*fetchThisFromHistory = (visitID, options) => {
    let visitObj = {};
    let query = this.historydb;
    return query.doc(visitID).get().then(doc => {
      visitObj = doc.data();
      if (options && options.includeIdentifications) {
        let idents = this.getDeletedIdentifications(visitID);
        return Promise.resolve(idents);
      } else {
        return Promise.resolve({});
      }
    }).then(idents => {
      visitObj.identifications = idents;
      return visitObj;
    }).catch(error => {
      console.error(`Error fetchThis():`, error);
      return Promise.reject(error);
    });
  }

  getDeletedIdentifications(visitId) {
    return this.historydb.doc(visitId).collection('visitIdentifications').get().then(querySnapshot => {
      return querySnapshot.docs.map(doc => doc.data());
    })
      .catch(error => {
        throw error;
      });
  }*/

}

export default VisitsData;
