import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { compose } from "recompose";

import ReactGA from 'react-ga';

import { withAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";
import * as ROUTES from '../../../constants/routes';
import { ReportStatus, ReportType } from '../../../constants/reports';
import TransactionReport from '../TransactionReport'
import VisitReport from '../VisitReport'
import { Button, Icon } from "semantic-ui-react";

import ReportAuth from '../common/reportAuth';
import { ErrorMessage } from "../common/reportListCommon";

const applySetError = error => prevState => ({
    isError: true,
    errorMsg: error
});

class ReportView extends Component {
    constructor(props) {
        super(props);
        this.reportId = this.props.match.params.reportId;
        this.auth = new ReportAuth();

        this.state = {
            reportData: null,
            authStatus: true,
            isError: false,
        }
    }

    componentDidMount() {
        const firebase = this.props.firebase;
        let reportData;

        firebase.reports.fetchThis(this.reportId).then(data => {
            reportData = data;
            return this.auth.authroize(reportData);
        }).then(authResult => {
            if (authResult.isAuthorized) {
                this.setState({ reportData: reportData, authStatus: true });
                this.listenToReportMetadata = firebase.reports.listenToMetadata(this.reportId, this.onReportMetadataUpdate.bind(this), this.onListenError.bind(this));
            } else {
                this.setState({ reportData: reportData, authStatus: false });
            }
        }).catch(error => {
            console.error(error);
            this.setState(applySetError(error));
        });
    }

    componentWillUnmount() {
        this.listenToReportMetadata && this.listenToReportMetadata();
    }

    statusChangeAction = (reportId, newStatus, options, onComplete) => {

        // Remove the report metadata listner before updating the report status as the report is closing
        // THis is to prevent the auth modal to activate as the new state might result in authStatus=false
        this.listenToReportMetadata && this.listenToReportMetadata();

        if (newStatus === ReportStatus.complete) {
            this.reportSubmitEvent(options.submittedBy);
        }
        
        return this.props.firebase.reports.update(reportId, newStatus, options).then(() => {
            onComplete();
            this.props.history.push(ROUTES.REPORTS);
        }).catch(error => {
            onComplete(error);
            this.setState({ isError: true, errorMsg: error.message });
        });
    }

    onReportMetadataUpdate(update) {
        // after initial load of report only these feilds can be chnaged:
        // status
        // lastSaved
        // submittedBy

        let updatedData = this.state.reportData;

        updatedData.status = update.status;
        updatedData.lastSaved = update.lastSaved;
        updatedData.submittedBy = update.submittedBy;

        return this.auth.authroize(updatedData).then(authResult => {
            if (authResult.isAuthorized) {
                this.setState({ reportData: updatedData, authStatus: true });
            }
            else {
                this.setState({ reportData: updatedData, authStatus: false });
                this.listenToReportMetadata(); // detach
            }
        })
    }

    onListenError(error) {
        console.error(error);
        this.setState(applySetError(error));
    }

    reportSubmitEvent(submittedBy) {
        ReactGA.event({
            category: 'Report',
            action: 'submit',
            label: submittedBy,
          });
    }

    render() {
        const { reportData, authStatus, isError, errorMsg } = this.state;
        const AuthMessage = this.auth.AuthMessage;

        const showVisitReport =
            authStatus
            && reportData
            && (reportData.type === ReportType.visits_with_sales ||
                reportData.status === ReportStatus.new);

        const showTransReport =
                authStatus
                && reportData
                && reportData.type === ReportType.sales_with_visits
                && reportData.status !== ReportStatus.new;

        return (
            <div>                
                {isError && (<ErrorMessage errorMsg={errorMsg} />)}
                {showVisitReport && <VisitReport reportId={this.reportId} reportData={reportData} statusUpdateDelegate={this.statusChangeAction} /> }
                {showTransReport && <TransactionReport reportId={this.reportId} reportData={reportData} statusUpdateDelegate={this.statusChangeAction} /> }
                {!authStatus &&
                    <AuthMessage
                        reportData={reportData}
                        action={<Button color='orange' as={Link} to={ROUTES.REPORTS}>
                            <Icon name='arrow left' /> Back
                      </Button>}
                    />}
            </div>
        )
    }
}

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withFirebase
)(ReportView);
