import React, { Component } from 'react';
import { withAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import "./searchCustomer.css";

import { Segment, Message, Loader, Input, Icon } from 'semantic-ui-react';

import CustomerSearchResults from './results';

class CustomerSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '',
            searchResults: null,
            isLoading: false,
            isError: false,
            errorMsg: null,
            inputError: '',
            searchStyle: { marginLeft: "25%", marginRight: "25%", },
        }
    }

    onQueryChange = ({ target: input }) => {
        this.setState({ query: input.value });
    }

    search = e => {
        const API = this.props.firebase.caazamAPI;
        const qs = this.state.query;

        if (qs.length > 1) {
            this.setState({ isLoading: true, inputError: '' });
            API.searchPerson(qs).then(response => {
                if (response.length === 1) {
                    let customer = response[0];
                    if (customer.uuid)
                        this.props.onResultSelected({ uuid: customer.uuid });

                    else
                        this.props.onResultSelected({ cid: customer.customerData.customerId });

                }
                else
                    this.setState({ searchResults: response, isLoading: false, isError: false, errorMsg: null, inputError: '', });
            }).catch(error => {
                console.error(error);
                this.setState({ isLoading: false, isError: true, errorMsg: error.message, inputError: '' });
            });
        }
        else {
            this.setState({ inputError: 'Search query must contain at least 2 characters' });
        }

        //Prevents refresh b/c of form submit
        e.preventDefault();
        return false;
    }

    render() {
        const { query, searchResults, isLoading, isError, errorMsg, inputError, searchStyle } = this.state;
        return (
            <Segment padded basic className="search" style={searchStyle}>
                <Segment attached={(searchResults && searchResults.length !== 0) || isLoading ? "top" : false}>
                    <form onSubmit={this.search} >
                        <Input
                            transparent
                            value={query}
                            onChange={this.onQueryChange}
                            placeholder="Search customer by name"
                            icon="search"
                            iconPosition="left"
                            error={inputError !== ''}
                            fluid />
                    </form>
                    {inputError !== '' && <h5 style={{ color: "red" }}>{inputError}</h5>}
                </Segment>
                {isLoading &&
                    <Segment attached="bottom">
                        <Loader active={isLoading} inline='centered'>Searching</Loader>
                    </Segment>
                }
                {
                    !isLoading && !isError && searchResults &&
                    <>
                        <CustomerSearchResults results={searchResults} {...this.props} />
                    </>
                }
                {searchResults && searchResults.length === 0 && <NoResultsMessage />}
                {isError && <ErrorMessage errorMsg={errorMsg} />}
            </Segment>
        );
    }
}

const NoResultsMessage = props => (
    <Message info >
        <Message.Header>
            <Icon name="search" />
            We couldn't find any customers matching this search
        </Message.Header>
        <Message.Content>
            To narrow down your search try to include more details like middle name and family name.
        </Message.Content>
    </Message>
)

const ErrorMessage = props => (
    <Message negative>
        <Message.Header>
            OOPS! There was an error{" "}
            <span role="img" aria-label="sorry face">
                😕
            </span>
        </Message.Header>
        <Message.Content>
            {props.errorMsg}
        </Message.Content>
    </Message>
)

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withRouter,
    withFirebase
)(CustomerSearch);