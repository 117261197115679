import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Divider, Icon, Segment } from 'semantic-ui-react';
import IdentificationsList from '../VisitView/identificationsList';

class FacesCompare extends Component {
    static propTypes = {
        facesA: PropTypes.array.isRequired,               //facesA: First visit's faces
        facesB: PropTypes.array.isRequired,               //facesB: Second visit's faces
        maxConfidenceA: PropTypes.number.isRequired,      //maxConfidenceA: First max conf
        maxConfidenceB: PropTypes.number.isRequired,      //maxConfidenceB: Second max conf
    };
    render() {
        let { facesA, facesB, maxConfidenceA, maxConfidenceB } = this.props;
        return (
            <Grid.Row centered>
                <FacesComp faces={facesA} maxConfidence={maxConfidenceA} />
                <Grid.Column width="1">
                    <Divider vertical><Icon name="user" /></Divider>
                </Grid.Column>
                <FacesComp faces={facesB} maxConfidence={maxConfidenceB} />
            </Grid.Row>
        );
    }
}

const FacesComp = props => (
    <Grid.Column>
        <Segment>
            <IdentificationsList list={props.faces} maxConfidence={props.maxConfidence} />
        </Segment>
    </Grid.Column>
)

export default FacesCompare;