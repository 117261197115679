import React, { Component } from 'react';
import { compose } from 'recompose';

import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';

import { withAuthorization, AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import { Grid, Header, Segment, Checkbox } from 'semantic-ui-react';

const applyUserData = (data) => (prevState) => ({
  userData: data
});

const applyUserNotificationData = (data) => (prevState) => ({
  isNotificationEnabled: data,
  isError: false,
  isLoading: false,
});

const applySetError = () => (prevState) => ({
  isError: true,
  isLoading: false,
});

class AccountPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isNotificationEnabled: false,
      isLoading: true,
      isError: false,
      userData: null,
    };
  };

  componentDidMount() {
    this.props.firebase.user().then(userData => {
      this.setState(applyUserData(userData));
      return this.props.firebase.userGetNotifications();
    }).then(notifications => {
      this.setState(applyUserNotificationData(notifications));
    }).catch(error => {
      console.error('Error fetching visits', error);
      this.setState(applySetError());
    })
  }

  onNotificationsToggle= () => {
    let newValue = !this.state.isNotificationEnabled;
    this.props.firebase.userSetNotifications(newValue);
    this.setState(applyUserNotificationData(newValue));
  }

  render() {
    let { isLoading, userData, isNotificationEnabled } = this.state;

    let locations ='';
    if (!!userData && !!userData.permissions) {
      locations = Object.keys(userData.permissions).join(', ');
    }

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <Grid container style={{height:'80vh'}} verticalAlign='middle'>
            <Grid.Column>
              <Segment>
                <Header as='h1'>My Account</Header>
                {<Grid columns={2}>
                  <Grid.Column>
                    <Header as='h4'>Email</Header>
                  </Grid.Column>
                  <Grid.Column>
                    {!isLoading && this.state.userData.email}
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h4'>Locations</Header>
                  </Grid.Column>
                  <Grid.Column>
                    {!isLoading && locations}
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h4'>Notifications</Header>
                  </Grid.Column>
                  <Grid.Column>
                    {!isLoading && <Checkbox slider checked={isNotificationEnabled} onChange={this.onNotificationsToggle}/>}
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h4'>Version</Header>
                  </Grid.Column>
                  <Grid.Column>
                    {process.env.REACT_APP_VERSION}
                  </Grid.Column>
                </Grid>}
              </Segment>
            </Grid.Column>
          </Grid>
        )}
      </AuthUserContext.Consumer>
    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),  
  withFirebase
)(AccountPage);