import app from 'firebase/app';
import 'firebase/firestore';
import { PersonRoles } from '../../constants/roles';

const COLL_NAME_VISITS_FEED = 'visitsFeed'

class VisitsFeedData {
    constructor() {
        this.db = app.firestore().collection(COLL_NAME_VISITS_FEED);
    }

    transformVisitsForDisplay(vData) {
        if (vData.lastPurchase) vData.lastPurchase.date = vData.lastPurchase.timestamp.toDate();
        if (vData.lastVisit) vData.lastVisit.date = vData.lastVisit.timestamp.toDate();
        return vData;
    }

    fetch(count, options) {
        return this._fetchPaginated(count, options, null);
    }

    _fetchPaginated(count, options, cursor) {

        let nextPage = null;
        let query = this.db;

        if (!options || !options.allRoles) {
            query = query.where('person.role', '==', PersonRoles.default);
        }

        if (!!options && !!options.locationId) {
            query = query.where('location.id', '==', options.locationId);
        }

        query = query.orderBy('timestamp', 'desc');

        if (cursor) {
            query = query.startAfter(cursor);
        }

        return query.limit(count).get().then(snapshot => {
            let entries = snapshot.docs.map(doc => { return { id: doc.id, data: this.transformVisitsForDisplay(doc.data()) } });
            if (snapshot.size === count) {
                let lastItem = snapshot.docs[snapshot.size - 1];
                nextPage = () => this._fetchPaginated(count, options, lastItem);
            }
            return { entries: entries, nextPage: nextPage, latest: snapshot.docs[0] };
        }).catch(error => {
            console.log(`Error _fetchPaginatedVisitsFeed():`, error);
            return Promise.reject(error);
        });

    }

    listen(after, onNewVisits, onUpdate, onDelete, onError, options) {
        let query = this.db;

        if (!options || !options.allRoles) {
            query = query.where('person.role', '==', PersonRoles.default);
        }

        if (!!options && !!options.locationId) {
            query = query.where('location.id', '==', options.locationId);
        }

        query = query.orderBy('timestamp', 'desc');

        if (!!after) {
            query = query.endBefore(after);
        }

        return query.onSnapshot(snapshot => {
            let added = [], updated = [], removed = [];
            snapshot.docChanges().forEach(change => {
                if (change.type === 'added') {
                    added.push({ id: change.doc.id, data: this.transformVisitsForDisplay(change.doc.data()) });
                } else if (change.type === 'modified') {
                    updated.push({ id: change.doc.id, data: this.transformVisitsForDisplay(change.doc.data()) });
                } else if (change.type === 'removed') {
                    removed.push({ id: change.doc.id, data: this.transformVisitsForDisplay(change.doc.data()) });
                }
            })

            if (removed.length > 0) {
                onDelete(removed);
            }
            if (added.length > 0) {
                onNewVisits(added);
            }
            if (updated.length > 0) {
                onUpdate(updated);
            }

        }, error => {
            console.error(`Error listenToVisitsFeed():`, error);
            onError(error);
        });
    }
}

export default VisitsFeedData;