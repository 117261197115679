import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Segment, Header, Image, Grid } from 'semantic-ui-react';

const gridRowStyle = { paddingBottom: '10px', paddingTop: '10px' };

class CustomerSearchResults extends Component {
    state = {}
    static propTypes = {
        results: PropTypes.array.isRequired,      //results: The query results returned from the API
    };

    getAttachedMode = (index) => {
        const last = this.props.results.length - 1;
        if (last === 0 || index === last)
            return 'bottom'
        return true;
    }

    viewCustomer = customer => {
        if (customer.uuid) {
            this.props.onResultSelected({ uuid: customer.uuid });
        }
        else {
            this.props.onResultSelected({ cid: customer.customerData.customerId });
        }
    }

    render() {
        let { results } = this.props;
        return (
            <>
                {results.map((result, index) => {
                    let customerData = result.customerData;
                    return (
                        <Segment key={index} attached={this.getAttachedMode(index)} onClick={() => this.viewCustomer(result)} style={{ cursor: "pointer" }}>
                            <Grid>
                                <Grid.Row style={gridRowStyle}>
                                    <Grid.Column width="12" verticalAlign="middle">
                                        <Header size="small">
                                            {customerData.customerName} &nbsp;
                                            {result.uuid && <Image src={process.env.PUBLIC_URL + "/caazam_logo_orange.png"} style={{ width: "20px", marginTop: "-5px" }} rounded />}
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column width="4" verticalAlign="middle" textAlign='right'>
                                        {customerData.customerStoreCode}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    )
                })}
            </>
        );
    }
}

export default CustomerSearchResults;