import React, { Component } from 'react';
import { Table, Input, Button } from 'semantic-ui-react';

class AddVisistItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: { customerName: '', isManual: true, note: '' },
        }
    }

    onNameEdit = e => {
        const name = e.target.value;
        let c = Object.assign({}, this.state.customer);
        c.customerName = name;
        this.setState({ customer: c })
    }

    onNoteEdit = e => {
        const note = e.target.value;
        let c = Object.assign({}, this.state.customer);
        c.note = note;
        this.setState({ customer: c });
    }

    customerSetOnClick = () => {
        const { customer } = this.state;
        const { id } = this.props;
        customer.isInStore = true;
        this.props.setEntry(customer, id);
        this.setState({
            customer: { customerName: '', isManual: true, note: '' },
        });
    }
    //<Note compact editMode notes={{ text: customer.note }} onEdit={this.onNoteEdit} />
    render() {
        const { customer } = this.state;
        return (
            <Table.Row>
                <Table.Cell width='4'><Input fluid placeholder="Enter customer's full name" value={customer.customerName} onChange={this.onNameEdit} /></Table.Cell>
                <Table.Cell width='2'/>
                <Table.Cell width='8'><Input fluid placeholder="Add a note (optional)" value={customer.note} onChange={this.onNoteEdit} /></Table.Cell>
                <Table.Cell width='2'>
                    <Button fluid
                        disabled={!customer.customerName || (!!customer.customerName && customer.customerName === '')}
                        onClick={this.customerSetOnClick}
                    >Add</Button>
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default AddVisistItem;