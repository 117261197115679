import 'firebase/firebase-remote-config';

class RemoteConfig {
    constructor(firebaseApp) {
        this.remoteConfigStore = firebaseApp.remoteConfig();

        if (process.env.NODE_ENV === 'development') {
            console.log('Remote config running in dev mode');
            this.remoteConfigStore.settings = {
                minimumFetchIntervalMillis: 0, //hour = 3600000
            };
        }

        this.remoteConfigStore.defaultConfig = ({
            'welcome_message': 'Welcome',
            'visit_after_transaction_match': 3600000,
            'visit_before_transaction_match': 7200000,
            'show_crm_no_data_warning' : false,
            "allow_anon_sales_as_candidates": false,
        });

        ///////////////////////////////////////////////////////////////
        this.remoteConfigStore.fetchAndActivate().then(isActivated => {
            console.log('Remote config updated store:', isActivated);
        }).catch(error => {
            console.error(error);
        });
    }

    getStore() {
        return this.remoteConfigStore;
    }

    get showCRMNoDataWarning() {
        return this.remoteConfigStore.getBoolean('show_crm_no_data_warning');
    }

    get allowAnonSalesAsCandidates() {
        return this.remoteConfigStore.getBoolean('allow_anon_sales_as_candidates');
    }

    get visitToTransactionMatching() {
        let visit_after_transaction_match = this.remoteConfigStore.getNumber('visit_after_transaction_match');
        let visit_before_transaction_match = this.remoteConfigStore.getNumber('visit_before_transaction_match');

        return { visit_after_transaction_match, visit_before_transaction_match }
    }
}

export default RemoteConfig;