import React, { useState } from 'react';

import { Modal, Image } from 'semantic-ui-react';
import { RichGrid, RichImage, RichHeader } from '../../CaazamUI';

import Moment from "moment-timezone";


const RecentItem = props => {
    const [isRecentModalOpen, setRecentModalOpenState] = useState(false);
    const { product, person } = props;
    let productName = product.name;
    if (productName.split(' ').length > 4) { //Improve logic
        productName = productName.split(' ');
        productName.splice(4)
        productName[productName.length - 1] = productName[productName.length - 1].substring(0, 1)
        productName = productName.join(' ');
        productName += '...';
    }
    return (
        <RichGrid.Box>
            <RichImage src={product.imageUrl} inline softload placeholderDirection="portrait" size='x-small' onClick={() => setRecentModalOpenState(true)}>
                <RichImage.Content>
                    <RichImage.Description>
                        {productName} {product.size}
                        <div style={{ fontSize: '12px', display: 'block', fontWeight: '600' }}>{product.amount.toLocaleString('en-US', { style: "currency", currency: "USD" })}</div>
                    </RichImage.Description>
                </RichImage.Content>
            </RichImage>
            <Modal
                open={isRecentModalOpen}
                closeOnEscape={true}
                closeOnDimmerClick={true}
                onClose={() => setRecentModalOpenState(false)}>
                <Modal.Header>{person.name}</Modal.Header>
                <Modal.Content image>
                    <Image src={product.imageUrl} size='medium' wrapped />
                    <Modal.Description>
                        <RichHeader>{product.name}</RichHeader>
                        <br />
                        <p>Purchased: {Moment(product.date).format('l')}, {product.store}</p>
                        <p>Amount: {product.amount.toLocaleString('en-US', { style: "currency", currency: "USD" })}</p>
                        {product.size && <p>Size: {product.size}</p>}
                        {product.color && <p>Color: {product.color}</p>}
                        {product.link && <p><a href={product.link} target="_blank" rel="noopener noreferrer" className='VisitNameLink'>View on ruti.com</a></p>}
                        {product.saleLink && <p><a href={product.saleLink} target="_blank" rel="noopener noreferrer" className='VisitNameLink'>View sale info</a></p>}
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </RichGrid.Box>
    );
}

export default RecentItem;