import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

const CustomerNotesView = (props) => {
    return (
        <Segment>
            <Header sub dividing>NOTES</Header>
            <p>
                {props.userDescription}
                <br />
                {props.userNotes}
            </p>
        </Segment>
    );
}

export default CustomerNotesView;