import React, { Component, useState } from 'react';
import { compose } from "recompose";
import { withAdminAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";

import { Segment, Header, List, Icon, Placeholder, Modal, Button } from 'semantic-ui-react';
import StatusFlare from '../../UI/StatusFlare';

const Status = {
    OK: 'ok',
    Problem: 'problem',
    Test: 'test',
    Unknown: 'unknown',
}

const getStatusCode = status => {
    return {
        'connected': Status.OK,
        'disconnected': Status.Problem,
        'problem': Status.Test,
        'system error': Status.Unknown,
    }[status];
}

const HandCursorStyle = { cursor: "pointer" }; // I know, it doesn't make sense that the hand cursor is called pointer... Blame W3C
const ThreeDigitInputStyle = { width: '2em', textAlign: 'center', border: 'none', borderBottom: 'solid 1px rgba(242, 113, 28, 0.75)', outline: 'none', appearance: 'none', fontSize: '20px', color: '#3a3a3a', };
const DotStyle = { verticalAlign: 'bottom', marginLeft: '2px', marginRight: '2px', fontSize: '20px' };

const LocationGroup = props => {
    let devicesString = "";
    if (Array.isArray(props.children)) devicesString = `${props.children.length} ${props.children.length === 1 ? 'device' : 'devices'}`;
    else if (props.children) devicesString = `1 device`;
    else devicesString = `No devices set in this location`;
    return (
        <Segment color="orange" textAlign="center" compact style={{ display: 'inline-block', margin: '5px' }}>
            <Header size="large" textAlign="center">
                {props.locationName}
                <Header.Subheader>
                    {devicesString}
                </Header.Subheader>
            </Header>
            {props.children}
        </Segment>
    )
}

const setIpAddressBlock = (newBlock, callback) => {
    //I use regex to test if the string contains numbers only and then check if that number is between 0-255.
    if ((/^\d+$/).test(newBlock) && newBlock >= 0 && newBlock <= 255) {
        callback(newBlock);
    }
}

const SystemItem = props => {
    const deviceData = props.device.data;
    const ipBlocks = deviceData.ipAddr.split('.');
    /*
    * Attention! The follwoing useStates should be at the top level of this function.
    * For more info visit: https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
    */
    const [box1TextState, setBox1TextState] = useState(ipBlocks[0]);
    const [box2TextState, setBox2TextState] = useState(ipBlocks[1]);
    const [box3TextState, setBox3TextState] = useState(ipBlocks[2]);
    const [box4TextState, setBox4TextState] = useState(ipBlocks[3]);
    const [isChangeIpModalOpen, setStateIsChangeIpModalOpen] = useState(false);

    let fullIP = `${box1TextState}.${box2TextState}.${box3TextState}.${box4TextState}`;

    return (
        <Segment compact style={{ display: 'inline-block', margin: '5px', width: '250px' }} textAlign="left">
            <Header size="medium" textAlign="center">
                {props.device.id}
                <Header.Subheader>
                    {deviceData.ipAddr} &nbsp;
                    <Modal trigger={<span style={HandCursorStyle} onClick={() => setStateIsChangeIpModalOpen(true)}><Icon name="pen square" fitted /></span>} open={isChangeIpModalOpen} centered={false} size="tiny">
                        <Modal.Header>Set new IP Address for this device</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Header as="h4"></Header>
                                <center>
                                    <span>
                                        <input value={box1TextState} style={ThreeDigitInputStyle} maxLength="3" onChange={e => setIpAddressBlock(e.target.value, setBox1TextState)} />
                                        <span style={DotStyle}>.</span>
                                        <input value={box2TextState} style={ThreeDigitInputStyle} maxLength="3" onChange={e => setIpAddressBlock(e.target.value, setBox2TextState)} />
                                        <span style={DotStyle}>.</span>
                                        <input value={box3TextState} style={ThreeDigitInputStyle} maxLength="3" onChange={e => setIpAddressBlock(e.target.value, setBox3TextState)} />
                                        <span style={DotStyle}>.</span>
                                        <input value={box4TextState} style={ThreeDigitInputStyle} maxLength="3" onChange={e => setIpAddressBlock(e.target.value, setBox4TextState)} />
                                    </span>
                                </center>
                                <h5>Wrong IP Address will cause the service to disconnect from this device. Are you sure?</h5>
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => setStateIsChangeIpModalOpen(false)}><Icon name='remove' />Cancel</Button>
                            <Button color='orange' basic onClick={() => { props.onNewIpSet(props.device, fullIP, setStateIsChangeIpModalOpen); }}><Icon name='checkmark' />Set new IP Address</Button>
                        </Modal.Actions>
                    </Modal>
                </Header.Subheader>
            </Header>
            <List>
                <List.Item><StatusFlare status={deviceData.status ? getStatusCode(deviceData.status.ctrlStatus) : Status.Unknown}><h3>Control</h3></StatusFlare></List.Item>
                <List.Item><StatusFlare status={deviceData.status ? getStatusCode(deviceData.status.rtspStatus) : Status.Unknown}><h3>RTSP</h3></StatusFlare></List.Item>
            </List>
        </Segment>
    );
}

const RowPlaceHolder = () => {
    return (
        <Segment color="orange" compact style={{ display: 'inline-block', margin: '5px' }}>
            <Header size="large" textAlign="center">
                <Placeholder><Placeholder.Line length='full' /></Placeholder>
                <Header.Subheader>
                    <Placeholder style={{ marginLeft: '35%' }}><Placeholder.Line length='medium' /></Placeholder>
                </Header.Subheader>
            </Header>
            {/*[1, 2, 3, 4, 5, 6].map(index => (
                <Placeholder style={{ height: 150, width: 150, display: 'inline-block', margin: 5 }} key={index}>
                    <Placeholder.Image />
                </Placeholder>
            ))*/}
            <Placeholder style={{ height: 150, width: 250, display: 'inline-block', margin: 5 }}>
                <Placeholder.Image />
            </Placeholder>
        </Segment>
    );
}

class SystemStatusPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputSources: {},
            locationsData: {},
            isLoading: true,
            isError: false,
        }

        this.statusListeners = [];
    }

    onStatusChanged = update => {
        let locations = this.state.inputSources;
        locations[update.locationId].forEach((dvc, index) => {
            if (dvc.id === update.deviceId) {
                locations[update.locationId][index].data.status = update.status;
            }
        });
        this.setState({ inputSources: locations });
    }

    onStatusListenerError = data => {
        let locations = this.state.inputSources;
        locations[data.locationId].forEach((dvc, index) => {
            if (dvc.id === data.deviceId) {
                locations[data.locationId][index].data.status = { ctrlStatus: 'system error', rtspStatus: 'system error' };
                console.error(data.error);
            }
        });
        this.setState({ inputSources: locations });
    }

    componentDidMount() {
        let { firebase } = this.props;
        let categorizedData = {}
        firebase.systemStatus.fetchAllInputSources().then(data => {
            //Categorize devices by location
            data.forEach(device => {
                if (categorizedData[device.data.locationId] && Array.isArray(categorizedData[device.data.locationId])) {
                    categorizedData[device.data.locationId].push(device);
                }
                else {
                    categorizedData[device.data.locationId] = [];
                    categorizedData[device.data.locationId].push(device);
                }
            });
            //Array promises to fetch the locations of each device CATEGORY
            const locations = Object.keys(categorizedData).map(locationId => firebase.locations.fetchThis(locationId));
            return Promise.all(locations);
        }).then(locs => {
            let locsData = {};
            locs.forEach(singleLocation => {
                locsData[singleLocation.id] = singleLocation.data;
            });
            this.setState({ inputSources: categorizedData, locationsData: locsData, isLoading: false, isError: false });

            //Loop on data again to start listening to the status
            Object.keys(categorizedData).forEach(locationId => {
                categorizedData[locationId].forEach(device => {
                    this.statusListeners.push(firebase.systemStatus.listenToStatusChange(device.id, device.data.locationId, this.onStatusChanged, this.onStatusListenerError));
                });
            });
        }).catch(error => {
            //this.setState(applySetError(error.message));
            console.error(error);
        });
    }

    componentWillUnmount() {
        this.statusListeners.forEach(unsubscribe => {
            unsubscribe();
        });
    }

    setIPAddress = (device, newIP, showModalCallback) => {
        let { firebase } = this.props;
        firebase.systemStatus.updateDeviceIP(device.id, device.data.locationId, newIP).then(isOK => {
            if (isOK) {
                showModalCallback(false);
                let locations = this.state.inputSources;
                locations[device.data.locationId].forEach((dvc, index) => {
                    if (dvc.id === device.id) {
                        locations[device.data.locationId][index].data.ipAddr = newIP;
                    }
                });
                this.setState({ inputSources: locations });
            }
        });
    }
    render() {
        const { isLoading, isError, inputSources, locationsData } = this.state;
        return (
            <Segment basic>
                <Header as="h1">System Status</Header>
                {isLoading && !isError && <div><RowPlaceHolder /> <RowPlaceHolder /> <RowPlaceHolder /> <RowPlaceHolder /> <RowPlaceHolder /> <RowPlaceHolder /> <RowPlaceHolder /> <RowPlaceHolder /><RowPlaceHolder /> <RowPlaceHolder /> <RowPlaceHolder /> <RowPlaceHolder /> <RowPlaceHolder /> <RowPlaceHolder /> <RowPlaceHolder /> <RowPlaceHolder /></div>}
                {!isLoading && !isError &&
                    < div >
                        {Object.keys(inputSources).map((locationId, index) => (
                            <LocationGroup locationName={locationsData[locationId].name} key={index}>
                                {inputSources[locationId].map(device => (
                                    <SystemItem onIPChange={this.setIPAddress} device={device} key={device.id} onNewIpSet={this.setIPAddress} />
                                ))}
                            </LocationGroup>
                        ))}
                    </div>
                }
            </Segment>
        );
    }
}

export default compose(
    withAdminAuthorization,
    withFirebase
)(SystemStatusPage);

/*
<LocationGroup locationName="Berkeley Store">
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-12" }} status={{ ctrl: Status.Problem, rtsp: Status.Problem }} />
                        </LocationGroup>
                        <LocationGroup locationName="San Francisco Store">
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-524" }} status={{ ctrl: Status.OK, rtsp: Status.OK }} />
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-365" }} status={{ ctrl: Status.OK, rtsp: Status.OK }} />
                        </LocationGroup>
                        <LocationGroup locationName="NY Madison Store"></LocationGroup>
                        <LocationGroup locationName="Venice Store">
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-42" }} status={{ ctrl: Status.OK, rtsp: Status.Offline }} />
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-7" }} status={{ ctrl: Status.OK, rtsp: Status.Offline }} />
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-9" }} status={{ ctrl: Status.OK, rtsp: Status.Offline }} />
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-10" }} status={{ ctrl: Status.OK, rtsp: Status.Offline }} />
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-654" }} status={{ ctrl: Status.OK, rtsp: Status.Offline }} />
                        </LocationGroup>
                        <LocationGroup locationName="Palo Alto Store">
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-1" }} status={{ ctrl: Status.OK, rtsp: Status.Problem }} />
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-2" }} status={{ ctrl: Status.OK, rtsp: Status.OK }} />
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-3" }} status={{ ctrl: Status.Problem, rtsp: Status.Problem }} />
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-4" }} status={{ ctrl: Status.Offline, rtsp: Status.Offline }} />
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-5" }} status={{ ctrl: Status.OK, rtsp: Status.OK }} />
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-17" }} status={{ ctrl: Status.OK, rtsp: Status.OK }} />
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-21" }} status={{ ctrl: Status.OK, rtsp: Status.OK }} />
                            <SystemItem onIPChange={this.setIPAddress} device={{ id: "device-22" }} status={{ ctrl: Status.OK, rtsp: Status.OK }} />
                        </LocationGroup>
*/