import React from 'react';
import "./style/statusFlare.scss"

const StatusFlare = props => {
    let status = 'unknown';
    if (props.ok) {
        status = 'ok';
    }
    if (props.problem) {
        status = 'problem';
    }
    if (props.test) {
        status = 'test';
    }
    if (props.unknown) {
        status = 'unknown';
    }

    if (props.status) {
        status = props.status;
    }

    //<StatusFlare ok label="Operational" />

    return (
        <div className="flare-wrapper">
            <div className={"flare " + status}></div>
            <span className="status">{props.children ? props.children : props.label}</span>
        </div>
    );
}

export default StatusFlare;