import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import "./styles/richImage.scss"
import "./styles/placeholder.scss";
import UIUtil from '../InterfaceUtilites';

const DefinedClassNames = {
    sharp: "sharp",
    staticContent: "static",
    inline: "inline",
}
const ImageSizes = [
    "fluid",        //default
    "xxx-large",
    "xx-large",
    "x-large",
    "large",
    "medium",
    "small",
    "x-small",
    "xx-small",
    "xxx-small",
]

const PlaceholderRatios = [
    "square",       //[1/1]
    "landscape",    //[4/3] default
    "portrait",     //[3/4]
]

const ManagedImage = props => {
    let elementManagedClasses = "";

    let { src, sharp, staticContent, style, alt = "" } = props;
    if (sharp) {
        elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.sharp);
    }
    if (staticContent) {
        elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.staticContent);
    }
    let _style = style ? style : {};
    _style.background = 'lightgrey';
    return (
        <img className={`rich-image${elementManagedClasses}`} src={src} alt={alt} onLoad={props.onImageLoaded} style={_style} onError={props.onImageLoadError} />
    )
}

const Content = props => {
    let elementManagedClasses = "";

    const { sharp, staticContent } = props;
    if (sharp) {
        elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.sharp);
    }
    return (
        <div className={`${staticContent ? 'static' : 'image'}-overlay${elementManagedClasses}`}>
            {props.children}
        </div>
    );
}

const Description = props => {
    return (
        <div className="rich-image-description">{props.children}</div>
    )
}

const Placeholder = props => {
    let wrapperManagedClasses = "";
    let elementManagedClasses = "";

    const { sharp, placeholderDirection = PlaceholderRatios[1], placeholderStyle } = props;
    if (sharp) {
        wrapperManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.sharp);
    }
    if (placeholderDirection) {
        elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, placeholderDirection);
    }
    return (
        <div className={`image-placeholder${wrapperManagedClasses}`} style={placeholderStyle}>
            <div className={`image-box-holder${elementManagedClasses}`}></div>
        </div>
    );
}

class RichImage extends Component {
    static Content = Content;
    static Description = Description;

    static propTypes = {
        src: PropTypes.string,                                    //src: Image source
        alt: PropTypes.string,                                    //alt: The alternate property of the image
        sharp: PropTypes.bool,                                    //sharp: Sharp corners
        size: PropTypes.oneOf(ImageSizes),                        //size: Predefined image size
        inline: PropTypes.bool,                                   //inline: Disables margins
        softload: PropTypes.bool,                                 //softload: Displays a placeholder until the image is loaded
        placeholderDirection: PropTypes.oneOf(PlaceholderRatios),     //placeholderRatio: The ratio of the placeholder (softload)
        placeholderStyle: PropTypes.object,                       //placeholderStyle: Styling for the placeholder
        fallbackImage: PropTypes.string,                          //fallbackImage: Image to view in case the component cannot load the [src]
    };

    state = {
        loaded: false,
        isError: false,
    }

    onImageLoaded = ({ target: img }) => {
        this.setState({ loaded: true, isError: false });
    }

    onImageLoadError = ev => {
        this.setState({ isError: true, loaded: true });
        const defaultFallbackImage = process.env.PUBLIC_URL + '/image_not_found_404.png';
        if (this.props.fallbackImage) {
            ev.target.src = this.props.fallbackImage;
        }
        else {
            //ev.target.src = defaultFallbackImage;
            ev.target.display = 'none';
        }
    }

    render() {
        let elementManagedClasses = "";

        let { src, size = ImageSizes[0], inline, softload, ...rest } = this.props;
        const { loaded, isError } = this.state;

        if (!src) {
            rest.staticContent = true;
        }

        if (isError) {
            rest.staticContent = true;
        }

        const childrenWithProps = React.Children.map(this.props.children, child =>
            React.cloneElement(child, { ...rest })
        );

        elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, size);
        if (inline) {
            elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.inline);
        }

        return (
            <div className={`image-wrapper${elementManagedClasses}`} onClick={() => this.props.onClick()}>
                <ManagedImage src={src} onImageLoaded={this.onImageLoaded} onImageLoadError={this.onImageLoadError} {...rest} />
                {!!softload ? (!!loaded || !src ? childrenWithProps : /*<Placeholder {...rest} />*/ null) : childrenWithProps}
            </div>
        );
    }
}

export default RichImage;