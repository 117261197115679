import React from 'react';
import { Grid } from 'semantic-ui-react';

import { CustomerView } from "../UI";

import './visitFeed.css';


const VisitView = (props) => (
    <Grid.Column width={16}>
        <CustomerView.CustomerHeader {...props} color='grey' />
        <Grid stackable columns={2}>
            {props.productHistory && props.productHistory.length > 0 &&
                <Grid.Column width={8} stretched>
                    <CustomerView.CustomerRecentPurchases {...props} />
                </Grid.Column>
            }
            {props.productRecommendations && props.productRecommendations.length > 0 &&
                <Grid.Column width={8} stretched>
                    <CustomerView.CustomerRecommendations {...props} />
                </Grid.Column>
            }
            <Grid.Column width={8} stretched>
                <CustomerView.CustomerNotesView {...props} />
            </Grid.Column>
            <Grid.Column width={8} stretched>
                <CustomerView.CustomerDetailsView {...props} />
            </Grid.Column>
        </Grid>
    </Grid.Column>
);


export default VisitView;