import React from 'react';
import { Header, Image, Grid, Segment, Modal, Label } from 'semantic-ui-react';

const MAX_PRODUCT_COLUMNS = 5;

const CustomerRecommendations = (props) => {
    const { maxItems = MAX_PRODUCT_COLUMNS } = props;
    return (
        <Segment>
            <Header sub dividing>She May Also Like {props.beta ? <Label color="orange" horizontal>BETA</Label> : null}</Header>
            <Grid columns={maxItems} padded>
                <Grid.Row >
                    {props.productRecommendations.slice(0, maxItems).map((product, index) =>
                        <Grid.Column key={index} textAlign='center' className='productColumn' style={{ cursor: "pointer" }}>
                            <Modal trigger={<Image src={product.imageUrl} fluid className='productImage' />}>
                                <Modal.Content image>
                                    <Image src={product.imageUrl} size='medium' wrapped />
                                    <Modal.Description>
                                        <Header>{product.name}</Header>
                                        <p>Price: <PriceDisplay price={product.price} final_price={product.final_price} /></p>
                                        {product.link && <p><a href={product.link} target="_blank" rel="noopener noreferrer" className='VisitNameLink'>View on ruti.com</a></p>}
                                    </Modal.Description>
                                </Modal.Content>
                            </Modal>
                            <div className='productText'>
                                {product.name}
                                <br />
                                <PriceDisplay price={product.price} final_price={product.final_price} />
                            </div>
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

const PriceDisplay = props => {
    const { price, final_price } = props;
    const isOnSale = !!final_price && price !== final_price;
    if (!isOnSale) {
        return <>{price.toLocaleString('en-US', { style: "currency", currency: "USD" })}</>
    } else {
        return (
            <>
                <s>{price.toLocaleString('en-US', { style: "currency", currency: "USD" })}</s>
                {' '}
                {final_price.toLocaleString('en-US', { style: "currency", currency: "USD" })}
            </>
        )
    }
}

export default CustomerRecommendations;