import React from "react";
import { compose } from "recompose";
import { withAuthorization } from "../Session";
import { detect } from 'detect-browser';

import { Menu, Message } from 'semantic-ui-react';

const UnsupportedBrowserToast = props => {
    const browser = detect();
    return (
        <>
            {
                props.authUser && browser.name !== 'chrome' &&
                <Menu fixed='top' style={{ marginTop: '40px' }}>
                    <Message color='orange'  style={{ width: '100%', textAlign: 'center' }}>
                        <Message.Header>
                            <strong>Warning!</strong> This browser does not support customer visit notifications. Please switch to Chrome.
                            </Message.Header>
                    </Message>
                </Menu>
            }
        </>
    );
}

export default compose(
    withAuthorization(() => true),
)(UnsupportedBrowserToast);