import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import UIUtil from '../InterfaceUtilites';

import './style/richHeader.scss';

const DefinedClassNames = {
    header: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
    ],
    textAlign: [
        'rtl',
        'ltr',
        'center',
    ],
    verticalAlign: [
        'top',
        'bottom',
    ],
    light: 'light',
    emphasizedSubHeader: 'emphasized',
    emphasizedWrapper: 'emphasize',
}

const Subheader = props => {
    let elementManagedClasses = "";
    const { emphasized } = props;
    if (emphasized) {
        elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.emphasizedSubHeader);
    }
    return (
        <div className={`rich-subheader${elementManagedClasses}`}>{props.children}</div>
    )
}

class RichHeader extends Component {
    static propTypes = {
        header: PropTypes.oneOf(DefinedClassNames.header),            //header: Styles the header same as h1/h2/.../h6
        textAlign: PropTypes.oneOf(DefinedClassNames.textAlign),      //textAlign: Alignes the text to specified side
        color: PropTypes.string,                                      //color: Text color in any format (hash, string, rgb/a, etc...)
        light: PropTypes.bool,                                        //light: Styles the main text with a light font weight
        emphasized: PropTypes.bool,                                   //emphasized: Emphasizes the subheader more then the main main text
        verticalAlign: PropTypes.oneOf(DefinedClassNames.verticalAlign),                             //verticalAlign: Aligns the header closer to top or bottom
    };

    static Subheader = Subheader;

    render() {
        let elementManagedClasses = "";
        const { header, textAlign, color, children, light, verticalAlign, ...rest } = this.props;
        if (header) {
            elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, header);
        }
        if (textAlign) {
            elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, textAlign);
        }
        if (light) {
            elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.light);
        }
        if (verticalAlign) {
            elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, verticalAlign);
        }
        if (rest.emphasized) {
            elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.emphasizedWrapper);
        }

        let style = {};
        if (color) {
            style.color = color;
        }

        const childrenWithProps = React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { ...rest });
            }
            else {
                return child;
            }
        });
        return (
            <div className={`rich-header${elementManagedClasses}`} style={style}>{childrenWithProps}</div>
        );
    }
}

export default RichHeader;