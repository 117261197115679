
import 'firebase/messaging';
import 'firebase/database';
import 'firebase/firestore';
import { detect } from 'detect-browser';

const CAAZAM_MESSAGING_SW_FILE = '/caazam-messaging-sw.js';
const CAAZAM_MESSAGING_SW_SCOPE = process.env.PUBLIC_URL + '/caazam-messaging-scope';
const NEW_VISIT_PUSH_NOTIFICATION = 'NEW_VISIT';

class CaazamMessaging {
    constructor(firebaseApp) {
        this.onTokenRefresh = null;
        this.currentToken = null;
        this.uuid = null;
        this.registration = null;

        this.browserInfo = detect();
        const notificationCondition = !process.env.REACT_APP_NOTIFICATIONS_OFF && this.browserInfo.name === 'chrome';

        if (firebaseApp.messaging.isSupported() && notificationCondition) {
            this.messaging = firebaseApp.messaging();
            this.rtdb = firebaseApp.database();
            this.messaging.usePublicVapidKey(process.env.REACT_APP_MESSAGING_PUBLIC_KEY);
            this.getSWRegistration().then(registration => {

                // tell firebase messaging we want to use this service worker
                this.messaging.useServiceWorker(registration);
                this.messaging.onMessage(this.onNewMessage);

                firebaseApp.auth().onAuthStateChanged(authUser => {
                    console.log(`messaging onAuthStateChanged(): ${authUser ? authUser.uid : 'none'}`);
                    if (!!authUser) {
                        console.log(`User ${authUser.uid} logged in - starting messaging services`);
                        this.uuid = authUser.uid;
                        this.init();
                        firebaseApp.firestore().collection('users').doc(this.uuid).get().then(userDoc => {
                            if (userDoc.exists) {
                                let userData = userDoc.data();
                                console.log(`messaging onAuthStateChanged(): ${this.uuid} default location == ${userData.defaultLocation}`);
                                this.setNotificationLocationLabel(userData.defaultLocation || null);
                            }
                        })
                    } else {
                        this.uuid = null;
                        this.setNotificationLocationLabel(null);
                    }
                });
            });
        } else {
            console.log(`messaging not supported on this browser (${this.browserInfo.name} ${this.browserInfo.version}) or configured OFF`);
        }
    }

    getSWRegistration() {
        // NOTE: we want to use our own custom SW (and not firebase messaging default one) so we can access it later to send foreground notifications

        if (this.registration) {
            return this.waitForRegistrationToActivate(this.registration);
        }

        // prepare SW URL
        var swURL = `${CAAZAM_MESSAGING_SW_FILE}?messagingId=` + encodeURIComponent(process.env.REACT_APP_MESSAGING_SENDER_ID)
        if (process.env.REACT_APP_GA_ID) {
            swURL = swURL + '&analyticsId=' + encodeURIComponent(process.env.REACT_APP_GA_ID);
        }

        return window.navigator.serviceWorker.register(swURL, { scope: CAAZAM_MESSAGING_SW_SCOPE }).catch(error => {
            console.error(`Failed registering service worker with scope ${CAAZAM_MESSAGING_SW_SCOPE}`, error);
        }).then(registration => {
            console.log(`New service worker ${registration.scope} is registered`);
            return this.waitForRegistrationToActivate(registration).then(() => {
                console.log(`Service worker ${registration.scope} is active and will update`);
                this.registration = registration;
                registration.update();
                return registration;
            }).catch(error => {
                console.error(`Service worker ${registration.scope} did not activate`, error);
                return;
            });
        });
    }

    waitForRegistrationToActivate(registration) {
        const serviceWorker =
            registration.installing || registration.waiting || registration.active;

        return new Promise((resolve, reject) => {
            if (!serviceWorker) {
                // This is a rare scenario but has occured in firefox
                reject('no service worker in this registration');
                return;
            }
            // Because the Promise function is called on next tick there is a
            // small chance that the worker became active or redundant already.
            if (serviceWorker.state === 'activated') {
                console.log(`Service worker ${registration.scope} is activated`);
                resolve(registration);
                return;
            }

            if (serviceWorker.state === 'redundant') {
                reject('serviceWorker.state === redundant');
                return;
            }

            const stateChangeListener = () => {
                if (serviceWorker.state === 'activated') {
                    console.log(`Service worker ${registration.scope} is activated`);
                    resolve(registration);
                } else if (serviceWorker.state === 'redundant') {
                    reject('serviceWorker.state === redundant');
                } else {
                    console.log(`Service worker ${registration.scope} - still waiting to activate`);
                    // Return early and wait to next state change
                    return;
                }
                serviceWorker.removeEventListener('statechange', stateChangeListener);
            };
            serviceWorker.addEventListener('statechange', stateChangeListener);
        });
    }

    setNotificationLocationLabel(label) {
        return this.getSWRegistration().then(registration=>{
        registration.active.postMessage({ type: 'location_update', value: label });
        })        
    }

    sendTokenToServer(token) {
        if (!!this.uuid) {
            console.log(this.browserInfo)
            let tokenInfo = {
                metadata: {
                    appVersion: process.env.REACT_APP_VERSION,
                    lastUpdate: new Date().toISOString(),
                    browser: this.browserInfo || {},
                },
            }
            return this.rtdb.ref('notifications').child(this.uuid).child('deviceTokens').child(token).set(tokenInfo).then(()=>{
                //for migrating to new token structure
                this.rtdb.ref('notifications').child(this.uuid).child('lastUpdate').remove();
                this.rtdb.ref('notifications').child(this.uuid).child('userMessagingToken').remove();
                return;
            });
        } else {
            console.error(`Trying to set messaging token to server without valid uuid`);
            return Promise.resolve();
        }
    }

    removeTokenFromServer(token) {
        return this.rtdb.ref('notifications').child(this.uuid).child('deviceTokens').child(token).remove();
    }

    setMessagingToken() {
        this.messaging.getToken().then(currentToken => {
            if (!!currentToken) {
                this.currentToken = currentToken;
                this.sendTokenToServer(currentToken);
                console.log('User messaging token is set in server');
            } else {
                throw new Error(`No messaging token avaible for user ${this.uuid}`);
            }
        }).catch(error => {
            if (!!this.currentToken)
                this.removeTokenFromServer(this.currentToken);
            console.error('An error occurred while retrieving Insatnce token id', error);
        })
    }

    init() {
        Notification.requestPermission().then(value => {
            if (value === 'granted') {
                console.log('Notification permission granted.');
                this.setMessagingToken();
                return;
            } else {
                console.error('Notification Permission wasn\'t granted', value);
                return;
            }
        }).catch((err) => {
            console.error('Unable to get user permission for notifications', err);
            return;
        });

        this.onTokenRefresh = this.messaging.onTokenRefresh(() => {
            console.log('Token refreshed event handler fired');
            this.setMessagingToken();
        });
        console.log('Messaging onTokenRefresh listner is set');
    }

    stop() {
        console.log(`Stopping messaging services for ${this.uuid}`);
        !!this.onTokenRefresh && this.onTokenRefresh();
        if (!!this.uuid && !!this.currentToken) {
            return this.removeTokenFromServer(this.currentToken).then(() => {
                console.log('User messaging token deleted from server');
            });
        } else {
            return Promise.resolve();
        }
    }

    onNewMessage(payload) {
        console.log('Received foreground message ', payload);
        // default notification data
        var notificationTitle = 'New Visit';
        var notificationOptions = {
            icon: '/caazam_logo.png',
        };

        // customized visit notification data
        if (!!payload.data && payload.data.type === NEW_VISIT_PUSH_NOTIFICATION) {
            console.log('message type is ', payload.data.type);
            notificationTitle = `${payload.data.name} Entered the ${payload.data.location}`;
            notificationOptions.body = `at ${payload.data.localDate}\nTotal purchase $${payload.data.totalSpend}\nOwner is ${payload.data.owner}`;
            notificationOptions.icon = payload.data.icon;
            notificationOptions.image = '/caazam_logo_364x184.png';
            notificationOptions.requireInteraction = true;
            notificationOptions.actions = [
                {
                    action: 'open-feed-action',
                    title: 'View',
                },
                {
                    action: 'close-action',
                    title: 'Close',
                },
            ];
        }

        if (Notification.permission === "granted") {
            /* NOTE: we're using the service worker to show the notification and not the Notification API
            ** This is becasue the Notification API doesn't allow custom actions
            */
            window.navigator.serviceWorker.getRegistration(CAAZAM_MESSAGING_SW_SCOPE).then(registration => {
                return registration.showNotification(notificationTitle, notificationOptions);
            })

            /* this is how sending using Notificaiton API would look like:
            // If it's okay let's create a notification
            var notification = new Notification(notificationTitle, notificationOptions);
    
            notification.onclick = function (event) {
                console.log('FG notification was clicked', event.notification, event.action);
                event.preventDefault(); // prevent the browser from focusing the Notification's tab
                notification.close();
            }
            notification.onclose = function (event) {
                console.log('FG notification was closed', event.notification);
            }
            */
        }

    }
}

export default CaazamMessaging;

/*

for oath playground: https://www.googleapis.com/auth/firebase.messaging
curl -X POST -H "Authorization: Bearer ya29.GlswB0zcTBt533lodNb8ljZr2qLYYIVY7SRU5axzKWIh6MsDv_k6OfTgtWOqWAJWnmxgiepX0f_MyK0rbHD3_SfBTtUpyPSL8FtGFwK8t_DYnaT01vyhgg8MpgQv" -H "Content-Type: application/json" -d '{
    "message": {
        "token": "dMMuxkjity8:APA91bHHVyvc43dUJmSNJr3DpyI-zi7BjbX6ZUVzsEx1zsMj9VNHbGL7Z6-kt6sPh1FSbYH1awIFunx-2UuH7nzxjXFrPOSm6qg1lxP6NIsFpLcBDdth4ObdvZTqoCnufg_LavW9eZ15",
        "notification": {
            "title": "This is a test ",
            "body": "Please click me",
        }        
    }
    }' "https://fcm.googleapis.com/v1/projects/caazam-retail-ruti/messages:send"
*/

/*

        "webpush": {
            "notification": {
                "requireInteraction": "true",
                "image": "/caazam_logo.png",
                "icon": "https://storage.googleapis.com/thumbnails.caazam-retail-ruti.caazam.com/DB173DFB-13C7-47A9-8827-8AB8FBEBB696%2Ffaces%2F8da0f881-c6d0-4131-bef3-846359418882.jpg?GoogleAccessId=caazam-url-generator%40caazam-retail-ruti.iam.gserviceaccount.com&Expires=1735689600&Signature=aSFCK6tk4MQsLZsygIerdL7FRlYU2jO8zn1G7QxMm3wAJRPvQxyaG4WLydPiIx0tTOneV%2B8nCxq06BHwF5r9LWfxr28MkYn5PXb4%2B3cIPujENvKg0PmDQVMar5HftqQZF9NAABFriRh3M7dZTF1ff2LjJon04bnb36%2FYmm3%2B7SnuG3lF19je%2BjLRdV7dogkMpLH4JjLl%2BKrMmuUZUWGMaqt2lUTCqLeme7iTO%2Bga4i7jBMs%2FzBJ8sQ%2Ba1wF%2FwAu6FG2dmy0Xz%2BAlMrU%2FQmCnvDYAgtfU%2FbxCZba%2FiFcgTxIYm85skvMSkMcfWy3jTqk0wB2G9AJIYC05G%2BChoKI8jA%3D%3D"
            },
            "fcm_options" : {
                "link": "https://dev-caazam-notifications.firebaseapp.com/home"
            }
        }

*/