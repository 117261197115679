import React from 'react';
import { Header, Image, Grid, Segment, Icon } from 'semantic-ui-react';

const CustomerHeaderData = (props) => {
    return (
        <Segment>
            <Grid columns={2} verticalAlign='middle'>
                <Grid.Column textAlign='center'>
                    {props.header}
                </Grid.Column>
                <Grid.Column textAlign='center' style={{fontSize :'1.2em'}}>
                    <strong>
                        {props.data}
                        </strong>
                </Grid.Column>
            </Grid>
        </Segment>
    );
}

const CustomerHeader = (props) => {

    let { totalSpend, basketSize, owner } = props;
    let ownerStr = owner ? `Owner is ${owner}` : 'Owner is not assigned';

    totalSpend = totalSpend || 0;
    let totalSpendStr = totalSpend.toLocaleString('en-US', { style: "currency", currency: "USD" });
    
    let maxBasketSizeStr = basketSize ? basketSize.max.toLocaleString('en-US', { style: "currency", currency: "USD" }) : null;
    let avgBasketSizeStr = basketSize ? basketSize.avg.toLocaleString('en-US', { style: "currency", currency: "USD" }) : null;

    let nameComponent = props.detailsLink ? <a href={props.detailsLink} target="_blank" rel="noopener noreferrer" className='VisitNameLink'>{props.person.name}</a> : <>{props.person.name}</>;

    return (
        <Grid doubling>
            <Grid.Row className='visitHeader'>
                <Grid.Column computer={2} tablet={2} mobile={4} textAlign="center" verticalAlign="middle">
                    {props.thumbnails ? <Image src={props.thumbnails[0]} size='small' avatar /> : <div className="no-image-icon-background"><Icon name="user" size="huge" color="orange" /></div>}
                </Grid.Column>
                <Grid.Column computer={14} tablet={14} mobile={12}>
                    <Grid stackable>
                        <Grid.Row columns={1}>
                            <Grid.Column textAlign='left'>
                                <Header as='h3'>{nameComponent} {props.location && <> entered the {props.location.name} at {props.moment.format('l, LT')}</>}</Header>
                                {!!ownerStr && ownerStr}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={3}>
                            <Grid.Column>
                                <CustomerHeaderData header='TOTAL SPEND' data={totalSpendStr} />
                            </Grid.Column>
                            {avgBasketSizeStr &&
                                <Grid.Column>
                                    <CustomerHeaderData header='AVG. BASKET' data={avgBasketSizeStr} />
                                </Grid.Column>
                            }
                            {maxBasketSizeStr &&
                                <Grid.Column>
                                    <CustomerHeaderData header='MAX. BASKET' data={maxBasketSizeStr} />
                                </Grid.Column>
                            }
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default CustomerHeader;