import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Table } from 'semantic-ui-react';
import MissedVisistItem from './missedVisitsItem';

class MissedVisitsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            manualInputList: null,
        }
    }

    static propTypes = {
        missedCustomers: PropTypes.array.isRequired,      //missedCustomers: The data of all the missed customers
        timezone: PropTypes.string.isRequired,            //timezone: Used to calculate times
    };

    render() {
        let { missedCustomers, timezone, setEntry, unsetEntry } = this.props;

        return (
            <Table.Body>
                {
                    missedCustomers.map((customer, index) => (
                        <MissedVisistItem key={index} id={index} customer={customer} timezone={timezone} setEntry={setEntry} unsetEntry={unsetEntry} manualInput={customer.isManual} />
                    ))
                }
            </Table.Body>
        );
    }
}

export default MissedVisitsList;