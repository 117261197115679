import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import update from 'immutability-helper';
import { PropTypes } from 'prop-types';

import "./index.css";

import { compose } from "recompose";
import { withAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";

import { ReportStatus } from '../../../constants/reports';

import { Message, Segment, Loader } from "semantic-ui-react";

import { matchVisitsCandidatesToSale } from '../common/matchCandidates';
import ReportBar from '../common/reportHeaderFooter'
import ChangeStatusModal from '../common/changeStatusModal';
import TransactionReportTable from "./TransactionReportTable";
import FeedbackView from "../common/FeedbackView";
import { ErrorMessage } from "../common/reportListCommon";

const applyReportData = (data) => ({
    reportData: data,
    canEdit: data.status === ReportStatus.new || data.status === ReportStatus.complete,
    canEditFeedback: data.status !== ReportStatus.archived,
});

const applyTransactionData = (transactions, candidates) => prevState => ({
    transactions: transactions,
    candidates: candidates,
    isError: false,
    isLoading: false
});

const applySetError = error => prevState => ({
    isError: true,
    isLoading: false,
    errorMsg: error
});

class TransactionReport extends Component {
    constructor(props) {
        super(props);
        
        let { reportId, reportData } = props;        
        this.reportId = reportId;

        this.visits = {};

        this.state = {
            reportData: null,
            transactions: [],
            candidates: {},
            feedbackData: {},

            filter: {},

            canEdit: false,
            canEditFeedback: true,

            isError: false,
            isLoading: true,
            errorMsg: '',

            isFbsShown: false, //Fbs = Feedback summery
            isChangeStatusModalOpen: false,
        }

        this.state = Object.assign(this.state, applyReportData(reportData));

        this.visitToTransactionMatchingConfig = this.props.firebase.remoteConfigStore.visitToTransactionMatching;
    }

    static propTypes = {
        reportData: PropTypes.object.isRequired,
        statusUpdateDelegate: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { firebase, reportData } = this.props;
        this.listenToContent = firebase.reports.listenToContent(reportData.scope, this.onVisitsUpdate.bind(this), this.onTransactionsUpdate.bind(this), this.onListenError.bind(this));
        this.listenToFeedback = firebase.reports.visitFeedback.listenToFeedback(this.reportId, this.onFeedbackUpdate.bind(this));
    }

    componentWillUnmount() {
        this.listenToContent && this.listenToContent();
        this.listenToFeedback && this.listenToFeedback();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return applyReportData(nextProps.reportData);
    }

    onVisitsUpdate(updates) {
        updates.forEach(update => {
            switch (update.type) {
                case 'added':
                    let newVisit = { id: update.id, data: update.data }
                    this.visits[newVisit.id] = (newVisit);
                    break;
                case 'modified':
                    let updateVisit = { id: update.id, data: update.data }
                    this.visits[updateVisit.id] = (updateVisit);
                    break;
                case 'removed':
                    let deleteVisitId = update.id;
                    if (this.visits[deleteVisitId]) {
                        delete this.visits[deleteVisitId];
                    }
                    break;
                default:
                    break;
            }
        });

        let newCandidates = this.state.transactions.reduce((agg, trans) => {
            agg[trans.id] = matchVisitsCandidatesToSale(trans, Object.values(this.visits), this.visitToTransactionMatchingConfig);
            return agg;
        }, {});

        var candidatesUpdate = this.state.candidates;
        Object.keys(this.state.candidates).forEach(transId => {
            // NOTE: we will only update candidates for a transaciton if a visit was removed or added but not if its data has been modified
            if (this.state.candidates[transId].length !== newCandidates[transId].length) {
                candidatesUpdate = update(candidatesUpdate, { [transId]: { $set: newCandidates[transId] } });
            }
        })
        this.setState({ candidates: candidatesUpdate });
    }

    onTransactionsUpdate(updates) {
        let transToAdd = [];
        let transToModify = [];
        let transToDelete = [];
        let candidatesUpdate = {}

        updates.forEach(update => {
            switch (update.type) {
                case 'added':
                    let newTrans = { id: update.id, data: update.data }
                    transToAdd.push(newTrans);
                    candidatesUpdate[newTrans.id] = matchVisitsCandidatesToSale(newTrans, Object.values(this.visits), this.visitToTransactionMatchingConfig);
                    break;
                case 'modified':
                    let updateTrans = { id: update.id, data: update.data }
                    transToModify.push(updateTrans);
                    candidatesUpdate[updateTrans.id] = matchVisitsCandidatesToSale(updateTrans, Object.values(this.visits), this.visitToTransactionMatchingConfig);
                    break;
                case 'removed':
                    let deleteTransId = update.id;
                    transToDelete.push(deleteTransId);
                    candidatesUpdate[deleteTransId] = null;
                    break;
                default:
                    break;
            }
        })

        var newTrans = this.state.transactions;
        transToDelete.forEach(transId => {
            let index = newTrans.findIndex(t => t.id === transId);
            if (index >= 0)
                newTrans = update(newTrans, { $splice: [[index, 1]] });
        });
        transToModify.forEach(trans => {
            let index = newTrans.findIndex(t => t.id === trans.id);
            if (index >= 0)
                newTrans = update(newTrans, { [index]: { $set: trans } })
            else
                newTrans = update(newTrans, { $push: [trans] });
        });
        newTrans = update(newTrans, { $push: transToAdd });

        var newCandidates = this.state.candidates;
        Object.keys(candidatesUpdate).forEach(transId => {
            newCandidates = update(newCandidates, { [transId]: { $set: candidatesUpdate[transId] } });
        })

        this.setState(applyTransactionData(newTrans, newCandidates));
    }

    onFeedbackUpdate(querySnapshot) {
        querySnapshot.docChanges().forEach(change => {
            if (change.type === 'added') {
                let feedbackData = update(this.state.feedbackData, { [change.doc.id]: { $set: change.doc.data() } });
                this.setState({ feedbackData });
            }
            if (change.type === 'modified') {
                let feedbackData = update(this.state.feedbackData, { [change.doc.id]: { $set: change.doc.data() } });
                this.setState({ feedbackData });

            }
            if (change.type === 'removed') {
                let feedbackData = update(this.state.feedbackData, { $unset: [change.doc.id] });
                this.setState({ feedbackData });
            }
        });
    }

    onListenError(error) {
        console.error(error);
        this.setState(applySetError(error.message));
    }

    getVisitData(visitId) {
        return this.visits[visitId].data;
    }

    onFilterToggle(options) {
        console.log('filter');
    }

    handleChangeStatusModal = () => {
        this.setState({ isChangeStatusModalOpen: true });
    }

    toggleFeedbackSummary = () => {
        this.setState({ isFbsShown: !this.state.isFbsShown });
    }

    handleStatusChange = (reportId, newStatus, inputValue, onComplete) => {

        const currentStatus = this.state.reportData.status;
        let options = {};
        if (currentStatus === ReportStatus.open) {
            options.submittedBy = inputValue || null;
        }

        this.props.statusUpdateDelegate(reportId, newStatus, options, onComplete);
    }

    onChangeStatusModalCancel = () => {
        this.setState({ isChangeStatusModalOpen: false });
    }

    render() {
        const { transactions, candidates, reportData, isLoading, isFbsShown, feedbackData, canEdit, canEditFeedback, filter, isError, isChangeStatusModalOpen } = this.state;

        const ReportBarCtrl = (props) => {
            return (
                <ReportBar.Controls
                    isFeedbackDisabled={Object.keys(feedbackData).length === 0 && (feedbackData).constructor === Object}
                    reportData={reportData}
                    filter={filter}
                    onFilterToggle={this.onFilterToggle}
                    handleChangeStatusModal={this.handleChangeStatusModal}
                    toggleFeedbackSummary={this.toggleFeedbackSummary}
                    isError={isError} />

            )
        }

        return (
            <div>
                {
                    reportData && !isLoading &&
                    <ReportBar>
                        <ReportBar.Metadata reportData={reportData} contentCount={transactions.length} />
                        <ReportBarCtrl />
                    </ReportBar>
                }
                {isError && (<ErrorMessage errorMsg={this.state.errorMsg} />)}
                {(transactions.length === 0 && !isLoading && !isError) && <NoResults />}
                {isFbsShown && <FeedbackView reportId={this.reportId} reportData={reportData} feedbackData={feedbackData}  notesData={{}} content={transactions} deletedVisits={{}}  />}
                {isLoading && <Loader active>Loading transactions...</Loader>}
                <TransactionReportTable
                    transactions={transactions}
                    visitCandidates={candidates}
                    visitDataDelegate={(visitId) => this.getVisitData(visitId)}
                    reportData={reportData}
                    isLoading={isLoading}
                    editMode={canEdit}
                    feedbackEdit={canEditFeedback}
                    reportId={this.reportId}
                    feedbackData={feedbackData}
                    notesData={{}}
                    onPreventAction={this.onPreventAction}
                    compareWith={null}
                    setVisitCompared={this.setVisitCompared}
                />
                {
                    reportData && !isLoading &&
                    <ReportBar>
                        <ReportBarCtrl />
                    </ReportBar>
                }
                {reportData && <ChangeStatusModal
                    open={isChangeStatusModalOpen}
                    data={reportData}
                    handleCancel={this.onChangeStatusModalCancel}
                    handleStatusChange={this.handleStatusChange}
                    reportId={this.reportId} />}
            </div>
        )
    }
}

const NoResults = props => {
    return (
        <Segment basic>
            <Message info>
                <Message.Header>
                    No transactions
                </Message.Header>
                <Message.Content>
                    No transactions recorded in this location on this date
                </Message.Content>
            </Message>
        </Segment>
    );
}

const condition = authUser => !!authUser;

export default compose(
    withRouter,
    withAuthorization(condition),
    withFirebase
)(TransactionReport);
