import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Table, Input, Icon, Button } from 'semantic-ui-react';
import { Note } from "../../UI";

import Moment from "moment-timezone";

class MissedVisistItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: props.customer ? props.customer : { customerName: '', isManual: true },
            edited: false,
            isInStoreConfirmed: !!props.isManual,
        }
    }

    static propTypes = {
        customer: (props, propName, componentName) => {
            if ((!props['manualInput'] && (props[propName] === undefined))) {
                return new Error(`Prop 'manualInput' is set to false thus a customer prop of type object is required but instead got ${typeof (props[propName])}`);
            }
        },                                             //customer: The customer's data to view
        timezone: PropTypes.string.isRequired,         //timezone: Used to calculate times
        manualInput: PropTypes.bool,                   //manualInput: Allows the user to add a missing visit manually
    };

    onNameEdit = e => {
        const name = e.target.value;
        let c = Object.assign({}, this.state.customer);
        c.customerName = name;
        this.setState({ customer: c, edited: true })
    }

    onNoteEdit = note => {
        let c = Object.assign({}, this.state.customer);
        c.note = note;
        this.setState({ customer: c, edited: true }, () => this.customerSetOnClick());
    }

    customerSetOnClick = () => {
        const { customer, isInStoreConfirmed } = this.state;
        const { id } = this.props;
        customer.isInStore = isInStoreConfirmed;
        this.props.setEntry(customer, id);
        this.setState({ edited: false });
    }

    customerUnsetOnClick = () => {
        const { id } = this.props;
        this.props.unsetEntry(id);
    }

    confirmCustomer = () => {
        const { isInStoreConfirmed } = this.state;
        this.setState({ isInStoreConfirmed: !isInStoreConfirmed }, () => this.customerSetOnClick());
    }

    render() {
        let { timezone, manualInput } = this.props;
        const { customer, edited, isInStoreConfirmed } = this.state;
        if (manualInput) {
            return (
                <Table.Row warning>
                    <Table.Cell width='4'><Input fluid placeholder="Enter customer's full name" value={customer.customerName} onChange={this.onNameEdit} /></Table.Cell>
                    <Table.Cell width='2' textAlign='center'><Icon name='asterisk' /></Table.Cell>
                    <Table.Cell width='8'><Note silent compact editMode notes={{ text: customer.note }} onEdit={this.onNoteEdit} /></Table.Cell>
                    <Table.Cell width='2' >
                        {!edited &&
                            <Button fluid basic onClick={this.customerUnsetOnClick}>Remove</Button>
                        }
                        {edited &&
                            <Button fluid
                                disabled={!customer.customerName || (!!customer.customerName && customer.customerName === '')}
                                basic={!customer.customerName || (!!customer.customerName && customer.customerName === '')}
                                onClick={this.customerSetOnClick}
                            >Save</Button>
                        }
                    </Table.Cell>
                    {/* <Table.Cell textAlign="right">
                        {!edited &&
                            <Button
                                disabled={!customer.customerName || (!!customer.customerName && customer.customerName === '')}
                                basic
                                onClick={this.customerUnsetOnClick}
                            >Remove</Button>
                        }
                        {edited &&
                            <Button
                                disabled={!customer.customerName || (!!customer.customerName && customer.customerName === '')}
                                basic={!customer.customerName || (!!customer.customerName && customer.customerName === '')}
                                onClick={this.customerSetOnClick}
                            >Save</Button>
                        }
                    </Table.Cell> */}
                </Table.Row>
            );
        }

        return (
            <Table.Row warning={isInStoreConfirmed}>
                <Table.Cell width='4'>{customer.customerName}</Table.Cell>
                <Table.Cell width='2'textAlign='center'>{Moment(customer.date).tz(timezone).format('LT')}</Table.Cell>
                <Table.Cell width='8'>{isInStoreConfirmed && <Note silent compact editMode notes={{ text: customer.note }} onEdit={this.onNoteEdit} />}</Table.Cell>
                {/* <Table.Cell textAlign="center"><Checkbox onChange={this.confirmCustomer} checked={isInStoreConfirmed} /></Table.Cell> */}
                <Table.Cell width='2'>
                    <Button fluid basic={isInStoreConfirmed} onClick={this.confirmCustomer} >{isInStoreConfirmed ? 'Remove' : 'Add'}</Button>
                </Table.Cell>
            </Table.Row>
        );
    }
}

export default MissedVisistItem;