import React, { Component } from 'react';
import { compose } from "recompose";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";

import VisitView from "./VisitView";
import { Segment, Header, Message } from 'semantic-ui-react';

class VisitPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visit: null,
            isError: false,
            errorMsg: null,
        }
    }

    componentDidMount() {
        let visitID = this.props.match.params.visitID;
        let firebase = this.props.firebase;
        let options = { includeIdentifications: true };
        firebase.visits.fetchThis(visitID, options).then(visit => {
            this.setState({ visit: visit, isError: false, errorMsg: null });
        }).catch(error => {
            this.setState({ isError: true, errorMsg: error.message });
        });
    }

    render() {
        const { visit, isError, errorMsg } = this.state;
        return (
            <Segment basic>
                <Header as="h2">
                    Visit Details
                </Header>
                {visit && !isError && <VisitView visit={visit} />}
                {isError && <ErrorMessage errorMsg={errorMsg} />}
            </Segment>
        );
    }
}

const ErrorMessage = props => (
    <Message negative>
        <Message.Header>
            OOPS! There was an error{" "}
            <span role="img" aria-label="sorry face">
                😕
            </span>
        </Message.Header>
        <Message.Content>
            {props.errorMsg}
        </Message.Content>
    </Message>
)

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withFirebase
)(VisitPage);