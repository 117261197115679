class ClassManager {
    appendClass(namesString, className) {
        if (namesString || namesString === "") {
            let arrClasses = namesString.split(' ');
            if (className && typeof className === 'string') {
                arrClasses.push(className);
                return this.purifyClassesArray(arrClasses).join(' ');
            }
            else {
                console.error("appendClass expects a 'className' parameter of type 'string'");
            }
        }
        else {
            console.error("appendClass expects a 'namesString' parameter of type 'string' which contains the current classes of the element");
        }
    }

    removeClass(namesString, className) {
        if (namesString || namesString === "") {
            let arrClasses = namesString.split(' ');
            if (className && typeof className === 'string') {
                arrClasses = this.purifyClassesArray(arrClasses);
                let index = arrClasses.indexOf(className);
                if (index > -1) {
                    arrClasses.splice(index, 1);
                }
                return arrClasses;
            }
            else {
                console.error("appendClass expects a 'className' parameter of type 'string'");
            }
        }
        else {
            console.error("appendClass expects a 'namesString' parameter of type 'string' which contains the current classes of the element");
        }
    }

    purifyClassesArray(classeArray, returnAsSet) {
        if (returnAsSet)
            return new Set(classeArray)
        return Array.from(new Set(classeArray));
    }
}

export default ClassManager;