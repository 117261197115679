import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Label, Table, Button, Icon, Header, Dropdown, Modal, Input } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import ReactTooltip from 'react-tooltip'

import { AuthUserContext } from '../../Session'
import { ReportStatusName } from '../../../constants/reports';

import StatusChangeModal from '../common/changeStatusModal';

class ReportListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statusChangeModalOpen: false,
            showDeleteModal: false,
            showFlagModal: false,
            revertStatus: false,
            flagMessage: (props.data && props.data.flag && props.data.flag.message) ? props.data.flag.message : "",
        }
    }

    static propTypes = {
        id: PropTypes.string.isRequired,              // the report id
        data: PropTypes.object.isRequired,            // the report meatdata
        onStatusChange: PropTypes.func,               // status change was requested
        onStatusRevert: PropTypes.func,               // reverse status change was requested
        onDelete: PropTypes.func,                     // delete action
        flagReport: PropTypes.func.isRequired,        //flagData: Is the report flagged
    }

    handleCopyUrl = (url) => {
        toast.success(<div>Report URL Copied to Clipboard:<br />{url} </div>, { hideProgressBar: true, position: 'top-center' });
    }


    handleStatusChangeModalOpen = () => {
        this.setState({ statusChangeModalOpen: true, revertStatus: false });
    }

    handleStatusChangeModalClosed = () => {
        this.setState({ statusChangeModalOpen: false })
    }

    handleStatusRevertModalOpen = () => {
        this.setState({ statusChangeModalOpen: true, revertStatus: true });
    }

    handleStatusChange = (reportId, nextStatus, inputText, onComplete) => {
        let { id, data, onStatusChange } = this.props;

        const callback = (error) => {
            onComplete(error);
            this.setState({ statusChangeModalOpen: false })
        }

        onStatusChange(id, data, nextStatus, inputText, callback);
    }

    handleStatusRevert = (reportId, nextStatus, inputText, onComplete) => {
        let { id, data, onStatusRevert } = this.props;

        const callback = (error) => {
            onComplete(error);
            this.setState({ statusChangeModalOpen: false })
        }

        onStatusRevert(id, data, nextStatus, callback);
    }

    onDeleteClick = () => {
        this.setState({ showDeleteModal: true });
    }

    handleDeleteCancel = () => {
        this.setState({ showDeleteModal: false });
    }

    handleDelete = () => {
        this.props.onDelete(this.props.id);
        this.setState({ showDeleteModal: false });
    }

    handleFlagModalShow = () => {
        this.setState({ showFlagModal: true });
    }

    handleFlagCancel = () => {
        this.setState({ showFlagModal: false });
    }

    handleFlag = () => {
        this.props.flagReport(this.props.id, this.state.flagMessage);
        this.setState({ showFlagModal: false });
    }

    handleFlagRemove = () => {
        this.setState({ showFlagModal: false, flagMessage: '' });
        this.props.flagReport(this.props.id, null, true);
    }


    render() {
        let { id, data, onDelete, onStatusChange } = this.props;
        let { revertStatus, flagMessage } = this.state;
        let scope = data.scope;
        let locationScope = scope.location;
        let reportUrl = data.url();

        ReactTooltip.rebuild() //Makes sure the tool-tip shows with new flags

        const reportMoment = Moment(scope.timeScope.from.toDate()).tz(locationScope.timezone);
        const today = Moment(new Date()).tz(locationScope.timezone);
        let isToday = reportMoment.isSame(today,'day');

        const reportCreateMoment = Moment(data.created.date.toDate());

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <Table.Row warning={reportCreateMoment.isAfter(Moment().subtract(5,'minute'))} error={authUser.isAdmin && data.flag && data.flag.isSet}>
                        <Table.Cell>
                            <Label basic circular color={data.statusColor()}>{ReportStatusName[data.status]}</Label>
                        </Table.Cell>
                        <Table.Cell>
                            <Header as='h4'>
                                <Header.Content>
                                    {reportMoment.format('l')}
                                    {isToday && <Label color='orange' size='small'>Today</Label>}
                                </Header.Content>
                            </Header>
                        </Table.Cell>
                        <Table.Cell>
                            <Header as='h4'>
                                <Header.Content>
                                    {locationScope.name + ", " + locationScope.region}
                                    {authUser.isAdmin && ((data.flag && data.flag.isSet) || flagMessage) && <Icon name='flag' color="orange" data-tip={flagMessage} />}
                                    <ReactTooltip effect="solid" delayHide={100} />
                                </Header.Content>
                            </Header>
                        </Table.Cell>
                        <Table.Cell>
                            {data.submittedBy}
                        </Table.Cell>
                        <Table.Cell textAlign='right' width="2">
                            <Link to={`/reports/${id}`}>
                                <Button>VIEW <Icon name="arrow circle right" /></Button>
                            </Link>
                        </Table.Cell>
                        <Table.Cell >
                            {authUser.isAdmin && <Dropdown item icon='ellipsis vertical' direction='left'>
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <CopyToClipboard text={reportUrl}
                                            onCopy={() => this.handleCopyUrl(reportUrl)}>
                                            <div><Icon name='chain' /> Copy URL</div>
                                        </CopyToClipboard>
                                    </Dropdown.Item>
                                    {onStatusChange && <Dropdown.Item onClick={this.handleStatusChangeModalOpen}>
                                        <Icon name='arrow right' /> {data.statusAction()}
                                    </Dropdown.Item>}
                                    {data.canRevert() && <Dropdown.Item onClick={this.handleStatusRevertModalOpen}><Icon name='undo' /> Revert</Dropdown.Item>}
                                    <Dropdown.Item onClick={this.handleFlagModalShow} ><Icon name='flag outline' /> Flag</Dropdown.Item>
                                    {onDelete &&
                                        <>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={this.onDeleteClick} style={{ color: 'red' }}><Icon name='trash' /> Delete</Dropdown.Item>
                                     </>}
                                </Dropdown.Menu>
                            </Dropdown>}
                        </Table.Cell>
                        {this.state.statusChangeModalOpen && <StatusChangeModal
                            open={true}
                            reportId={id}
                            data={data}
                            handleStatusChange={!revertStatus ? this.handleStatusChange : this.handleStatusRevert}
                            handleCancel={this.handleStatusChangeModalClosed}
                            revertStatus={revertStatus} />
                        }

                        <Modal open={this.state.showDeleteModal} size="small">
                            <Modal.Header>Are you sure?</Modal.Header>
                            <Modal.Content>
                                <p><b>This action will delete the report including it's feedback entries and cannot be reversed.</b><br />The visits related to this report will not be deleted.</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={this.handleDeleteCancel}>Cancel</Button>
                                <Button
                                    onClick={this.handleDelete}
                                    negative
                                    color="red"
                                    labelPosition='left'
                                    icon='trash'
                                    content='Delete'
                                />
                            </Modal.Actions>
                        </Modal>

                        <Modal open={this.state.showFlagModal} size="small">
                            <Modal.Header>Flag this report</Modal.Header>
                            <Modal.Content>
                                <p>Enter a note to show with the flag (optional)</p>
                                <Input
                                    value={flagMessage}
                                    fluid placeholder='Enter your note here'
                                    onChange={e => this.setState({ flagMessage: e.target.value })}
                                />
                            </Modal.Content>
                            <Modal.Actions>
                                <Button onClick={this.handleFlagCancel}>Cancel</Button>
                                <Button
                                    onClick={this.handleFlagRemove}
                                    color="black"
                                    labelPosition='left'
                                    icon='flag outline'
                                    content='Unflag'
                                />
                                <Button
                                    onClick={this.handleFlag}
                                    color="orange"
                                    labelPosition='left'
                                    icon='flag'
                                    content='Flag'
                                />
                            </Modal.Actions>
                        </Modal>
                    </Table.Row>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

export default ReportListItem;