import React from 'react';
//import { PropTypes } from 'prop-types';
import { Segment } from 'semantic-ui-react';
import TransactionReportTableItem from './TransactionReportTableItem';

class TransactionReportTable extends React.Component {

    render() {
        let { transactions, visitCandidates, visitDataDelegate, reportData, reportId, feedbackData, notesData, feedbackEdit, editMode, setVisitCompared, compareWith } = this.props;

        return (
            <div>
                {transactions.slice(0).reverse().map(trans => {
                    return (
                        <Segment basic key={trans.id}>
                            <TransactionReportTableItem
                                sale={trans}
                                visitsCandidates={visitCandidates[trans.id] || []}
                                visitDataDelegate={visitDataDelegate}
                                reportId={reportId}
                                reportFeedback={feedbackData}
                                editMode={editMode}
                                feedbackEdit={feedbackEdit}
                                reportData={reportData}
                                allowComparison={transactions.length > 1}
                                setVisitCompared={setVisitCompared}
                                compareWith={compareWith}
                            />
                        </Segment>
                    )
                })}
            </div>
        );
    }
}

export default TransactionReportTable;