import app from 'firebase/app';
import 'firebase/firestore';

const COLLECTION_USERS = "users";

class UsersData {
    constructor(props) {
        this.db = app.firestore().collection(COLLECTION_USERS);
        this.auth = app.auth();
    }

    fetchUserData = () => {
        return this.db.doc(this.auth.currentUser.uid).get().then(userDoc => {
            return userDoc.data();
        });
    }

    getAuthorizedLocations = () => {
        return this.fetchUserData().then(userData => {
            return Object.keys(userData.permissions).map(key => ({ id: key, permissionLevel: userData.permissions[key] }));
        });
    }
}

export default UsersData;