import app from 'firebase/app';
import 'firebase/firestore';

const COLL_NAME_LOCATIONS = 'locations'

class LocationsData {
    constructor() {
        this.db = app.firestore().collection(COLL_NAME_LOCATIONS);
        this.auth = app.auth();
    }

    /*
    *Fetches all locations the current user is assigned to
    */
    fetch = () => {
        if (!this.auth.currentUser) {
            return null;
        } else return this.db.where('assignedUsers', 'array-contains', this.auth.currentUser.uid).get().then(snapshot => {
            return snapshot.docs.map(doc => { return { id: doc.id, data: doc.data() } });
        });
    }

    fetchThis = locationId => {
        let query = this.db.doc(locationId);
        return query.get().then(doc => {
            if (doc.exists) {
                return { id: doc.id, data: doc.data() };
            } else {
                // doc.data() will be undefined in this case
                throw new Error(`No location corresponding to this location id: ${locationId}`);
            }
        });
    }
}

export default LocationsData;