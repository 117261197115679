import React from 'react';
import { Segment, Message } from 'semantic-ui-react';

const ErrorMessage = props => {
    return (
        <Segment basic>
            <Message negative>
                <Message.Header>
                    OOPS! There was an error{" "}
                    <span role="img" aria-label="sorry face">
                        😕
                    </span>
                </Message.Header>
                <Message.Content>
                    {props.errorMsg}
                </Message.Content>
            </Message>
        </Segment>
    );
}

const NoResults = props => {
    return (
        <Segment basic>
            <Message
                success
                icon='check circle outline'
                header={props.header}
                content={props.content}
            />
        </Segment>
    );
}

export { ErrorMessage, NoResults };