import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import UIUtil from '../InterfaceUtilites';


import './styles/richGrid.scss';

const DefinedClassNames = {
    tight: "tight",
    gridSizes: {
        '1': 'one-col',
        '2': 'two-col',
        '3': 'three-col',
        '4': 'four-col',
        '5': 'five-col',
        '6': 'six-col',
        '7': 'seven-col',
        '8': 'eight-col',
        '9': 'nine-col',
        '10': 'ten-col',
        '11': 'eleven-col',
        '12': 'twelve-col',
        '13': 'thirteen-col',
        '14': 'fourteen-col',
        '15': 'fifteen-col',
        '16': 'sixteen-col',
    },
    alignment: {
        'left': 'ltr',
        'right': 'rtl',
        'center': 'center'
    },
    padded: 'padded',
    alternating: 'alternating',
}

const GridHeader = props => {
    return (
        <div className="grid-header">{props.children}</div>
    );
}

const GridBox = props => {
    let elementManagedClasses = "";

    const { alignment, padded, alternating } = props;
    if (alignment) {
        elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.alignment[alignment]);
    }
    if (padded) {
        elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.padded);
    }
    if (alternating) {
        elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.alternating);
    }
    return (
        <div className={`grid-box${elementManagedClasses}`}>
            {props.children}
        </div>
    );
}

const GridLayout = props => {
    let elementManagedClasses = "";

    const { tight, gridSize = '3', children, rowCount, ...rest } = props;
    if (tight) {
        elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.tight);
    }
    if (gridSize) {
        elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.gridSizes[gridSize]);
    }

    let style = {};
    if (rowCount) {
        let rowCountString = '';
        for (let row = 0; row < rowCount; row++) {
            rowCountString += '1fr ';
        }
        style.gridTemplateRows = rowCountString;
    }

    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, { ...rest })
    );
    return (
        <div className={`rich-grid${elementManagedClasses}`} style={style}>
            {childrenWithProps}
        </div>
    );
}

class RichGrid extends Component {

    static propTypes = {
        tight: PropTypes.bool,           //tight: Disables space between columns
        padded: PropTypes.bool,          //padded: Adds 0.3em padding
        rowCount: PropTypes.number,      //rowCount: Sets fixed number of rows
        //gridSize
        //alignment
    };

    static Header = GridHeader;
    static Layout = GridLayout;
    static Box = GridBox;
    render() {
        const { children, ...rest } = this.props;
        const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, { ...rest })
        );
        return (
            <div>
                {childrenWithProps}
            </div>
        );
    }
}

export default RichGrid;