import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';

import { Menu, Image, Icon, Dropdown } from 'semantic-ui-react'

import './navigation.css'

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth isAdmin={authUser.isAdmin} /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
);

class NavigationAuth extends Component {
  state = {}

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    const { activeItem } = this.state;
    const { isAdmin } = this.props;

    return (
      <Menu fixed='top'>

        <Menu.Item className='logo'>
          <Image src={process.env.PUBLIC_URL + "/caazam_logo.png"} size='mini' rounded />
        </Menu.Item>

        <Menu.Item as={Link} to={ROUTES.HOME}
          name='Home'
          active={activeItem === 'Home'}
          onClick={this.handleItemClick}>
          Home
        </Menu.Item>

        <Menu.Item as={Link} to={ROUTES.REPORTS}
          name='Reports'
          active={activeItem === 'Reports'}
          onClick={this.handleItemClick}>
          Reports
        </Menu.Item>

        <Menu.Item as={Link} to={ROUTES.CUSTOMER_PROFILE}
          name='Customers'
          active={activeItem === 'Customers'}
          onClick={this.handleItemClick}>
          Customers
        </Menu.Item>

        <Menu.Menu position='right'>
          <Dropdown item icon='user' direction='left'>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to={ROUTES.ACCOUNT} text='Account' />
              <Dropdown.Divider />
              <Dropdown.Item>
                <SignOutButton />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {isAdmin &&
            <Menu.Item as={Link} to={ROUTES.ADMIN}
              name='Admin'
              active={activeItem === 'Admin'}
              onClick={this.handleItemClick}>
              <Icon name='cog' />
            </Menu.Item>}

          <Menu.Item className='logo'>
            <Image src={process.env.PUBLIC_URL + "/customer_logo.png"} size='tiny' rounded />
          </Menu.Item>

        </Menu.Menu>
      </Menu>

    )
  }
}

const NavigationNonAuth = () => (
  <Menu>
    <Menu.Item className='logo'>
      <Image src={process.env.PUBLIC_URL + "/caazam_logo.png"} size='mini' rounded />
    </Menu.Item>
    <Menu.Menu position='right'>
      <Menu.Item className='logo'>
        <Image src={process.env.PUBLIC_URL + "/customer_logo.png"} size='tiny' rounded />
      </Menu.Item>
    </Menu.Menu>
  </Menu>
)

export default Navigation;