import React from 'react';
import { compose } from 'recompose';

import AuthUserContext from './context';
import withAuthorization from './withAuthorization';

const withAdminAuthorization = (Component) => {
  const WithAdminAuthorization = (props) => (
    <AuthUserContext.Consumer>
      {authUser =>
        (authUser.isAdmin) ? <Component {...props} /> : 'NOT AUTHORIZED. Please go back'
      }
    </AuthUserContext.Consumer>
  );

  return compose(
    withAuthorization(authUser => !!authUser)
  )(WithAdminAuthorization);
};

export default withAdminAuthorization;