import React from 'react';
import { Header, Grid, Segment } from 'semantic-ui-react';

import moment from "moment";

const CustomerDetailsView = (props) => {

    var lastPurchaseMoment, lastPurchaseDate, lastPurchaseDateAgo, lastPurchaseTotal;
    if (!!props.lastPurchase) {
        lastPurchaseMoment = moment(props.lastPurchase.date); // local timezone
        if (props.lastPurchase.totalAmount) {
            lastPurchaseTotal = props.lastPurchase.totalAmount.toLocaleString('en-US', { style: "currency", currency: "USD" });
        }
    }

    if (!!lastPurchaseMoment) {
        lastPurchaseDate = lastPurchaseMoment.format('l');
        lastPurchaseDateAgo = lastPurchaseMoment.fromNow();
    }

    var lastVisitMoment, lastVisitDate, lastVisitDateAgo;
    if (!!props.lastVisit) {
        lastVisitMoment = moment(props.lastVisit.date); // local timezone
    }
    if (!!lastVisitMoment) {
        lastVisitDate = lastVisitMoment.format('l');
        lastVisitDateAgo = lastVisitMoment.fromNow();
    }

    let loyaltyCredit = (!!props.loyaltyCredit) ? props.loyaltyCredit.toLocaleString('en-US', { style: "currency", currency: "USD" }) : null;

    return (
        <Segment>
            <Header sub dividing>DETAILS</Header>
            <Grid columns={2}>
                <Grid.Row className='visitDetailsRow'>
                    <Grid.Column>
                        <div>LAST PURCHASE</div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className='visitDetailsData'>
                            {lastPurchaseDateAgo}
                        </div>
                        <br />
                        <div className='visitDetailsSubData'>
                            {lastPurchaseDate}, {lastPurchaseTotal}
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className='visitDetailsRow'>
                    <Grid.Column>
                        <div>LAST VISIT</div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className='visitDetailsData'>
                            {lastVisitDateAgo}
                        </div>
                        <br />
                        <div className='visitDetailsSubData'>
                            {lastVisitDate}, {props.lastVisit && props.lastVisit.locationName}
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className='visitDetailsRow'>
                    <Grid.Column>
                        <div>LOYALTY CREDIT</div>
                    </Grid.Column>
                    <Grid.Column>
                        <div className='visitDetailsData'>
                            {loyaltyCredit}
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment >
    );
}

export default CustomerDetailsView;