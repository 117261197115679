import app from 'firebase/app';
import 'firebase/firestore';

const COLL_NAME_SALES = 'sales';

class SalesData {
    constructor() {
        this.db = app.firestore().collection(COLL_NAME_SALES);
    }

    fetch = (options) => {
        let query = this.db;

        if (options && options.locationId) {
            query = query.where('locationId', '==', options.locationId);
        }

        if (options && options.startDate) {
            query = query.where('timestamp', '>=', options.startDate);
        }

        if (options && options.endDate) {
            query = query.where('timestamp', '<=', options.endDate);
        }

        return query.get().then(snapshot => {
            return snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    listenToSales(options, onSalesUpdate, onError) {

        let query = this.db;
        let _options = Object.assign({},options);

        if (_options.locationId) {
            query = query.where('locationId', '==', _options.locationId);
        }

        if (_options.startDate) {
            query = query.where('timestamp', '>=', _options.startDate);
        }

        if (_options.endDate) {
            query = query.where('timestamp', '<=', _options.endDate);
        }
        let listener = query.onSnapshot(querySS => {
            var updates = [];
            querySS.docChanges().forEach(change => {
              
              updates.push({
                id: change.doc.id,
                data: change.doc.data(),
                type: change.type,
              });
            });

            onSalesUpdate(updates);
        }, onError);

        return listener;
    }
}

export default SalesData