import React, { Component } from 'react';
import { compose } from 'recompose';

import moment from 'moment-timezone';

import { withLoading, withPaginated, withInfiniteScroll } from '../Lists';

import { Grid } from 'semantic-ui-react';
import VisitFeedItem from './visitsFeedItem';

const getTimeZone = (locations, id) => {
    let location = locations.find(element => element.id === id);
    return (!!location) ? location.data.timezone : null;
}

class VisitsFeedTable extends Component {

    render() {
        let { list, config } = this.props;
        let locations = this.props.locations;

        return (
            <Grid padded >
                {list.map((visit, index, visitsArray) => {
                    // convert visit datetime to location timezone
                    visit.data.moment = moment(visit.data.timestamp.toDate());
                    let timezone = getTimeZone(locations, visit.data.location.id);
                    if (timezone) {
                        visit.data.moment = visit.data.moment.tz(timezone);
                    }
                    let divider = null;
                    if (index > 0) {
                        let nextVisit = visitsArray[index - 1].data;
                        let nextVisitMoment = moment(nextVisit.timestamp.toDate());
                        let nextTimezone = getTimeZone(locations, nextVisit.location.id);
                        if (nextTimezone) {
                            nextVisitMoment = nextVisitMoment.tz(nextTimezone);
                        }
                        if (visit.data.moment.date() !== nextVisitMoment.date()) {
                            divider = visit.data.moment.format('l');
                        }
                    }

                    return (
                        <Grid.Row key={visit.id}>
                            <VisitFeedItem {...visit.data} config={config} />
                        </Grid.Row>
                    );
                }
                )}
            </Grid>
        )
    }
}

const paginatedCondition = props =>
    props.page !== null && !props.isLoading && props.isError;

const infiniteScrollCondition = props =>
    (window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 500)
    && props.list.length
    && !props.isLoading
    && !props.isError;

const loadingCondition = props =>
    props.isLoading;


export default compose(
    withPaginated(paginatedCondition),
    withInfiniteScroll(infiniteScrollCondition),
    withLoading(loadingCondition)
)(VisitsFeedTable);