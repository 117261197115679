import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Grid } from 'semantic-ui-react';

import ImageCompare from "./imageCompare";
import FacesCompare from './facesCompare';
import MetadataCompare from './metadataCompare';

class CompareLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    static propTypes = {
        visit_A: PropTypes.object.isRequired,      //visit_A: First visit to compare
        visit_B: PropTypes.object.isRequired,      //visit_B: Second visit to compare
    };

    render() {
        let { visit_A, visit_B } = this.props;
        let data_A = visit_A.data;
        let data_B = visit_B.data;

        let A_hasMoreIdents = data_A.identifications.length > data_B.identifications.length;

        return (
            <Grid columns="3">
                <MetadataCompare
                    visitID_A={visit_A.id}
                    visitID_B={visit_B.id}
                    person_A={data_A.person}
                    person_B={data_B.person}
                    location_A={data_A.location}
                    location_B={data_B.location}
                    timestamp_A={data_A.timestamp}
                    timestamp_B={data_B.timestamp}
                    confidence_A={{ max: data_A.visitConfidence, average: data_A.averageConfidence }}
                    confidence_B={{ max: data_B.visitConfidence, average: data_B.averageConfidence }}
                />

                <FacesCompare
                    facesA={data_A.identifications}
                    facesB={data_B.identifications}
                    maxConfidenceA={data_A.visitConfidence}
                    maxConfidenceB={data_B.visitConfidence}
                />

                {(A_hasMoreIdents ? data_A : data_B).identifications.map((ident, index) => (
                    <ImageCompare
                        imageA={
                            A_hasMoreIdents
                                ? ident
                                : index < data_A.identifications.length
                                    ? data_A.identifications[index]
                                    : null}
                        imageB={
                            !A_hasMoreIdents
                                ? ident
                                : index < data_B.identifications.length
                                    ? data_B.identifications[index]
                                    : null}
                        key={index} />
                ))}
            </Grid>
        );
    }
}

export default CompareLayout;