import React, { Component } from 'react';
import { Button, Segment, Header, Form, Select, Checkbox } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import Moment from 'moment-timezone';

import "react-datepicker/dist/react-datepicker.css";

class GenerateReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: Moment().subtract(1, 'day').toDate(),
            allLocationsEnabled: true,
            isLocationError: false,
            isDateError: false,
            isWorking: false,
        }
        this.selectedLocationId = "";
        let locs = this.props.locations;
        this.selectLocation = [];
        for (var key in locs) {
            if (locs.hasOwnProperty(key)) {
                this.selectLocation.push({ key: key, text: locs[key].name, value: key });
            }
        }
    }

    locationDrpDown_onChange = (e, data) => {
        this.selectedLocationId = data.value;
        if (data.value === "")
            this.setState({ isLocationError: true });
        else
            this.setState({ isLocationError: false });
    }
    datepicker_onChange = date => {
        this.setState({ date: date });
        if (!date || isNaN(Date.parse(date)))
            this.setState({ isDateError: true });
        else
            this.setState({ isDateError: false });
    }

    allLocationsCheckbox = (e, data) => {
        if (data.checked) {
            this.setState({ allLocationsEnabled: true, isLocationError: false });
        } else {
            let locationError = this.selectedLocationId === '';
            this.setState({ allLocationsEnabled: false, isLocationError: locationError });
        }
    }

    _generate = () => {
        if (!this.state.isDateError && !this.state.isLocationError) {
            this.setState({ isWorking: true });
            let selectedLocations = [];
            if (this.state.allLocationsEnabled) {
                selectedLocations = Object.keys(this.props.locations);
            } else {
                selectedLocations = [this.selectedLocationId];
            }
            return this.props.generate(selectedLocations, this.state.date, this.props.user).catch(error => {
                this.setState({ isWorking: false });
            });
        }
    }

    render() {

        let { date, allLocationsEnabled, isDateError, isLocationError, isWorking } = this.state;
        return (
            <Segment basic>
                <Form>
                    <Form.Group>
                        <Form.Field>
                            <Header as="h5">Generate new report</Header>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field error={isDateError}>
                            <label>Date</label>
                            <DatePicker
                                showTimeSelect={false}
                                selected={date}
                                onChange={this.datepicker_onChange}
                                maxDate={new Date()}
                            />
                        </Form.Field>
                        <Form.Field error={isLocationError}>
                            <Checkbox label='All Locations' defaultChecked={allLocationsEnabled} onChange={this.allLocationsCheckbox} />
                            <Select
                                options={this.selectLocation}
                                disabled={allLocationsEnabled}
                                placeholder='Location'
                                search
                                onChange={this.locationDrpDown_onChange} />
                        </Form.Field>
                        <Form.Field>
                            <label>&zwnj;</label>
                            <Button onClick={this._generate} color="orange" loading={isWorking} disabled={isWorking}>Create</Button>
                        </Form.Field>
                        <Form.Field>
                            <label>&zwnj;</label>
                            <Button onClick={this.props.onCancel} disabled={isWorking}>Cancel</Button>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>
        );
    }
}

export default GenerateReport;