import React, { Component } from 'react';
import { Segment, Tab, Grid, Header, Icon, Menu } from 'semantic-ui-react';
import ActiveReportsList from './reportsListActive';
import ArchivedReportsList from './reportsListArchived';

class ReportsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {}

        this.panes = [
            {
                menuItem: (
                    <Menu.Item key='active'>
                        <Icon name="dot circle outline" />Active
                    </Menu.Item>
                ),
                render: () =>
                    <Tab.Pane>
                        <ActiveReportsList key="active" />
                    </Tab.Pane>
            },
            {
                menuItem: (
                    <Menu.Item key='archive'>
                        <Icon name="archive" />Archive
                    </Menu.Item>
                ),
                render: () =>
                    <Tab.Pane>
                        <ArchivedReportsList key="archive" />
                    </Tab.Pane>
            },
        ];
    }

    render() {
        return (
            <Segment basic>
                <Segment basic>
                    <Grid columns={1} stackable>
                        <Grid.Column>
                            <Header as="h1">Store Visit Reports</Header>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Tab panes={this.panes} />
            </Segment>
        );
    }
}

export default ReportsPage;