import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Divider, Icon, Segment, Header, Label } from 'semantic-ui-react';
import Moment from "moment-timezone";

import { PersonRoles } from "../../../constants/roles";
import UUID from '../../UI/uuid';

class MetadataCompare extends Component {
    static propTypes = {
        visitID_A: PropTypes.string.isRequired,
        person_A: PropTypes.object.isRequired,
        location_A: PropTypes.object.isRequired,
        timestamp_A: PropTypes.object.isRequired,
        visitID_B: PropTypes.string.isRequired,
        person_B: PropTypes.object.isRequired,
        location_B: PropTypes.object.isRequired,
        timestamp_B: PropTypes.object.isRequired,
        confidence_A: PropTypes.object.isRequired,
        confidence_B: PropTypes.object.isRequired,
    };
    render() {
        let { visitID_A, visitID_B, person_A, person_B, location_A, location_B, timestamp_A, timestamp_B, confidence_A, confidence_B } = this.props;
        let visitA_Moment = Moment(timestamp_A.toDate()).tz(location_A.timezone);
        let visitB_Moment = Moment(timestamp_B.toDate()).tz(location_B.timezone);
        return (
            <Grid.Row centered>
                <Grid.Column>
                    <Grid.Row>
                        <Segment.Group>
                            <Segment color='violet'>
                                <Header size="medium">{person_A.name}</Header>
                            </Segment>
                            <Segment>
                                <Header size="small">
                                    {visitA_Moment.format('LLLL')}
                                    <Header.Subheader>At {location_A.name + ", " + location_A.region}</Header.Subheader>
                                </Header>
                                <Label color={person_A.role === PersonRoles.default ? 'orange' : null}>{person_A.role}</Label>
                                <Label>max {parseFloat(Math.round(confidence_A.max * 10000) / 100).toFixed(2) + "%"}</Label>
                                <Label>avg {parseFloat(Math.round(confidence_A.average * 10000) / 100).toFixed(2) + "%"}</Label>
                            </Segment>
                            <Segment>
                                <Header size="tiny">
                                    <Header.Subheader><strong>Visit ID: </strong>{visitID_A}</Header.Subheader>
                                    <Header.Subheader><strong>UUID: </strong><UUID value={person_A.uuid} /></Header.Subheader>
                                </Header>
                            </Segment>
                        </Segment.Group>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width="1">
                    <Divider vertical><Icon name="address card" /></Divider>
                </Grid.Column>
                <Grid.Column>
                    <Grid.Row>
                        <Segment.Group>
                            <Segment color='violet'>
                                <Header size="medium">{person_B.name}</Header>
                            </Segment>
                            <Segment>
                                <Header size="small">
                                    {visitB_Moment.format('LLLL')}
                                    <Header.Subheader>At {location_B.name + ", " + location_B.region}</Header.Subheader>
                                </Header>
                                <Label color={person_B.role === PersonRoles.default ? 'orange' : null}>{person_B.role}</Label>
                                <Label>max {parseFloat(Math.round(confidence_B.max * 10000) / 100).toFixed(2) + "%"}</Label>
                                <Label>avg {parseFloat(Math.round(confidence_B.average * 10000) / 100).toFixed(2) + "%"}</Label>
                            </Segment>
                            <Segment>
                                <Header size="tiny">
                                    <Header.Subheader><strong>Visit ID: </strong>{visitID_B}</Header.Subheader>
                                    <Header.Subheader><strong>UUID: </strong><UUID value={person_B.uuid} /></Header.Subheader>
                                </Header>
                            </Segment>
                        </Segment.Group>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        );
    }
}

export default MetadataCompare;