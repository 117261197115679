import React, { Component } from "react";
import { compose } from "recompose";

import { withAdminAuthorization } from "../Session";
import { withFirebase } from "../Firebase";

import { VisitsTable } from "../Visit";

import { Header, Grid, Segment, Divider } from "semantic-ui-react";

const applyPagnationResult = result => prevState => ({
    visits: [...prevState.visits, ...result.visits],
    nextPage: result.nextPage,
    isError: false,
    isLoading: false
});

const applySetError = () => prevState => ({
    isError: true,
    isLoading: false
});

const EmptyTableView = props => {
    return (
        <Grid>
            <Grid.Column textAlign="center">
                <Segment>
                    <Header as="h3">
                        No visits to display
                    </Header>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

class CustomerVisitsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visits: [],
            nextPage: null,
            isLoading: false,
            isError: false,

            customerUuid: this.props.uuid,

            comapreWith: null,
        };
    }

    componentDidMount() {
        if (this.state.customerUuid) {
            this.fetchVisits();
        }
    }

    onPaginationSubmit = () => {
        if (!!this.state.nextPage) {
            this.setState({ isLoading: true });
            this.state
                .nextPage()
                .then(results => {
                    this.setState(applyPagnationResult(results));
                })
                .catch(error => {
                    this.setState(applySetError());
                });
        }
    };

    fetchVisits = () => {
        this.setState({ visits: [], isLoading: true });

        let options = {
            uuid: this.state.customerUuid,
            includeIdentifications: true,
            descendingOrder: true,
        }
        this.props.firebase.visits.fetch(10, options).then(results => {
            this.setState(applyPagnationResult(results));
        }).catch(error => {
            this.setState(applySetError());
            console.error('VisitsBroswer:', error);
        })
    }

    setVisitCompared = visitID => {
        this.setState({ comapreWith: visitID });
    }

    render() {
        const { visits, nextPage, isError, isLoading, comapreWith } = this.state;

        return (
            <>
                <br />
                <Divider horizontal>Previous visits</Divider>
                {(visits.length > 0 || isLoading) && (
                    <VisitsTable
                        visits={visits}
                        isLoading={isLoading}
                        isError={isError}
                        nextPage={nextPage}
                        onPaginatedSearch={this.onPaginationSubmit}
                        allowComparison={true}
                        setVisitCompared={this.setVisitCompared}
                        compareWith={comapreWith}
                    />
                )}
                {visits.length === 0 && !isLoading && <EmptyTableView />}
            </>
        );
    }
}

export default compose(
    withAdminAuthorization,
    withFirebase
)(CustomerVisitsView);