import React, { Component } from 'react';
import PropTypes from 'prop-types';
import "./index.css";
import { compose } from "recompose";
import update from 'immutability-helper';

import { withAuthorization, AuthUserContext } from "../../Session";
import { withFirebase } from "../../Firebase";
import { withRouter } from "react-router-dom";
import { Button, Icon, Table, Menu } from 'semantic-ui-react';
import ReportsTable from './reportsTable';
import GenerateReport from './generateReport';
import Moment from 'moment-timezone';

import { ReportStatus, reportDateSorter, ReportType } from '../../../constants/reports';
import * as ROUTES from '../../../constants/routes';

import { ErrorMessage, NoResults } from '../common/reportListCommon';

const applyReports = reports => prevState => ({
    reports: reports,
    isError: false,
    isLoading: false
});

const applySetError = error => prevState => ({
    isError: true,
    isLoading: false,
    errorMsg: error.message,
});

const statusFilter = (isAdmin)  => {
    return isAdmin ? [ReportStatus.new, ReportStatus.open, ReportStatus.complete] : [ReportStatus.open];
}

class ActiveReportsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            isLoading: true,
            isError: false,
            errorMsg: "",
            showNewRepDialog: false,
            fieldError: { isDateError: false, isLocationError: true },
        }
        this.locations = {};
    }

    componentDidMount() {
        let { firebase } = this.props;
        var options = { };
        firebase.users.getAuthorizedLocations().then(locationPermissions => {
            if (!locationPermissions || locationPermissions.length === 0) {
                throw new Error('User has no permissions for reports');
            } else {
                options.locations = locationPermissions.map(location => location.id);
                options.statusFilter = statusFilter(locationPermissions[0].permissionLevel === 'admin');
            }

            this.reportListener = firebase.reports.listenToActive(this.onNewReports.bind(this), this.onNewReportsError.bind(this), options);
        }).catch(error => {
            console.error('Error loading active reports list', error);
            this.setState(applySetError(error));
        });

        firebase.locations.fetch().then(locs => {
            locs.forEach(location => {
                this.locations[location.id] = location.data;
            });
        }).catch(error => {
            console.error(error);
            this.setState(applySetError(error));
        });
    }

    componentWillUnmount() {
        this.reportListener && this.reportListener();
    }

    newReport_onClick = () => {
        this.setState({ showNewRepDialog: true });
    }

    genReport_onCancel = () => {
        this.setState({ showNewRepDialog: false });
    }

    statusChangeAction = (id, data, newStatus, inputText, onCompleteCallback) => {

        const currentStatus = data.status;
        let options = {};
        if (currentStatus === ReportStatus.open) {
            options.submittedBy = inputText || null;
        }

        return this.props.firebase.reports.update(id, newStatus, options).then(() => {
                onCompleteCallback();
                return;
        }).catch(error => {
            onCompleteCallback(error);
            this.setState({ isError: true, errorMsg: error.message });
        });

    }

    statusRevertAction = (id, data, newStatus, onCompleteCallback) => {

        return this.props.firebase.reports.revert(id, newStatus).then(() => {
            onCompleteCallback();
        }).catch(error => {
            onCompleteCallback(error);
            this.setState({ isError: true, errorMsg: error.message });
        });

    }

    onNewReports(updates) {
        let reportsToAdd = [];
        let reportsToModify = [];
        let reportsToDelete = [];

        updates.forEach(update => {
            switch (update.type) {
                case 'added':
                    reportsToAdd.push(update);
                    break;
                case 'modified':
                    reportsToModify.push(update);
                    break;
                case 'removed':
                    reportsToDelete.push(update);
                    break;
                default:
                    break;
            }
        })


        let modifiedReports = this.state.reports;
        reportsToDelete.forEach(report => {
            let index = modifiedReports.findIndex(r => r.id === report.id);
            if (index >= 0)
                modifiedReports = update(modifiedReports, { $splice: [[index, 1]] });
        });
        reportsToModify.forEach(report => {
            let index = modifiedReports.findIndex(r => r.id === report.id);
            if (index >= 0)
                modifiedReports = update(modifiedReports, {[index]: {$set: report}})
        }  );  
        modifiedReports = update(modifiedReports, { $unshift: reportsToAdd});

        this.setState(applyReports(modifiedReports));

    }

    onNewReportsError(error) {
        this.setState({ isError: true, errorMsg: error.message });
    }

    generateReport = (locations, date, uid) => {

        let dateString = Moment(date).format('YYYY-MM-DD');
        let earliestStart;

        let genreatePromises = locations.map(locationId => {
            let timezone = this.locations[locationId].timezone;
            let startDate = Moment.tz(dateString, timezone).utc().toDate();
            earliestStart = earliestStart? Math.min(earliestStart, startDate.getTime()) :  startDate.getTime();
            let endDate = Moment.tz(dateString, timezone).endOf('day').utc().toDate();
            return this.props.firebase.caazamAPI.createReport(
                locationId,
                {
                    from: startDate,
                    to: endDate
                },
                process.env.REACT_APP_DEFAULT_REPORT_TYPE,
            );
        });

        return Promise.all(genreatePromises).then(newReports => {

            if (newReports.length === 1) {
                this.props.history.push(ROUTES.REPORTS + '/' + newReports[0].newReportId);
            } else {
                this.setState({ showNewRepDialog: false, isError: false });
            }
            return;
        }).catch(error => {
            console.error('Error generating new reports', error);
            this.setState(applySetError(error));
            throw error;
        });
    }

    deleteReportAction = reportId => {
        this.props.firebase.reports.deleteThis(reportId).then(() => {
            return;
        }).catch(error => {
            console.error('Error deleteing report', error);
            this.setState(applySetError(error));
        });
    }

    flagReportAction = (reportId, message, remove) => {
        this.props.firebase.reports.flag(reportId, message, remove).then(() => {
            return;
        });
    }


    render() {
        const { isLoading, isError, showNewRepDialog, reports } = this.state;

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <div>
                        {authUser.isAdmin && <Button floated="right" disabled={showNewRepDialog} onClick={this.newReport_onClick}><Icon color="orange" name="add" />Create new report</Button>}
                        {showNewRepDialog && <GenerateReport onCancel={this.genReport_onCancel} generate={this.generateReport} user={authUser.uid} locations={this.locations} />}
                        <br />
                        {isError && (<ErrorMessage errorMsg={this.state.errorMsg} />)}
                        <ReportsTable
                            reports={reports}
                            onStatusChange={this.statusChangeAction}
                            onStatusRevert={this.statusRevertAction}
                            onReportDelete={this.deleteReportAction}
                            flagReport={this.flagReportAction}
                            isLoading={this.state.isLoading}

                        />


                        {(reports.length === 0 && !isLoading && !isError) &&
                            <NoResults
                                header="You're all done!"
                                content='There are no reports to show for now' />}

                        <Table basic="very" attached="bottom">
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='4'>
                                        <Menu pagination>
                                            <Menu.Item as='a' disabled={!this.state.nextPage} onClick={this.onPaginationSubmit} icon>
                                                <Icon name='chevron down' />
                                            </Menu.Item>
                                        </Menu>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </div>)}
            </AuthUserContext.Consumer>
        );
    }
}


const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withRouter,
    withFirebase
)(ActiveReportsList);