import React from 'react';
import { Header, Image, Grid, Segment, Modal } from 'semantic-ui-react';

import moment from 'moment';

const MAX_PRODUCT_COLUMNS = 5;

const CustomerRecentPurchases = (props) => {
    const { maxItems = MAX_PRODUCT_COLUMNS } = props;
    return (
        <Segment>
            <Header sub dividing>Recent Purchases</Header>
            <Grid columns={maxItems} padded>
                <Grid.Row >
                    {props.productHistory.slice(0, maxItems).map((product, index) =>
                        <Grid.Column key={index} textAlign='center' className='productColumn' style={{ cursor: "pointer" }}>
                            <Modal trigger={<Image src={product.imageUrl} fluid className='productImage' />}>
                                <Modal.Header>{props.person.name}</Modal.Header>
                                <Modal.Content image>
                                    <Image src={product.imageUrl} size='medium' wrapped />
                                    <Modal.Description>
                                        <Header>{product.name}</Header>
                                        <p>Purchased: {moment(product.date).format('l')}, {product.store}</p>
                                        <p>Amount: {product.amount.toLocaleString('en-US', { style: "currency", currency: "USD" })}</p>
                                        {product.size && <p>Size: {product.size}</p>}
                                        {product.color && <p>Color: {product.color}</p>}
                                        {product.link && <p><a href={product.link} target="_blank" rel="noopener noreferrer" className='VisitNameLink'>View on ruti.com</a></p>}
                                        {product.saleLink && <p><a href={product.saleLink} target="_blank" rel="noopener noreferrer" className='VisitNameLink'>View sale info</a></p>}
                                    </Modal.Description>
                                </Modal.Content>
                            </Modal>
                            <div className='productText'>
                                {product.name} {product.size}
                                <br />
                                {product.amount.toLocaleString('en-US', { style: "currency", currency: "USD" })}
                            </div>
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

export default CustomerRecentPurchases;