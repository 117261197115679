import React, { Component } from 'react';
import { compose } from "recompose";
import { withAdminAuthorization } from "../../Session";

//import { PropTypes } from 'prop-types';
import { Segment, Header, Grid, Input, Button } from 'semantic-ui-react';
import { withFirebase } from '../../Firebase';

const InputStyle = { borderBottom: '1px solid #F2711C', float: 'right', width: '100%' };

class RemoteConfigManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            config: {
                visit_after_transaction_match: '',
                visit_before_transaction_match: '',
            },
            isLoading: true,
        }
    }

    componentDidMount() {
        this.getConfig().then(()=>{
            this.setState({ isLoading: false });
        });
    }

    getConfig() {
        return this.props.firebase.caazamAPI.getClientRemoteConfig().then(configJson => {
            let stateUpdate = Object.assign(this.state.config, configJson);
            this.setState({ config: stateUpdate });
            return;
        }).catch(error => {
            console.error(`Failed to fetch client config from server`, error);
            return;
        })
    }

    onParamEdit = (param,e) => {
        let update = this.state.config;
        update[param] = e.target.value;
        this.setState({ config: update });
    }

    onSave() {

        this.setState({ isLoading: true });
        return this.props.firebase.caazamAPI.setClientRemoteConfigAll(this.state.config).then(()=>{
            return this.getConfig();
        }).then(()=>{
            this.setState({ isLoading: false });
        }).catch(error=>{
            this.setState({ isLoading: false });
            console.error(`Failed to save client config from server`, error);
        })

    }

    render() {
        const { config, isLoading } = this.state;
        const { visit_after_transaction_match, visit_before_transaction_match  } = config;
        const currentValues = this.props.firebase.remoteConfigStore.visitToTransactionMatching;

        return (
            <Segment basic>
                <Segment basic color='orange'><Header>Remote Config Parameters</Header></Segment>
                <Segment.Group>
                    <Segment>
                        <Grid>
                            <Grid.Column width='11'>
                                <Header>
                                    Earliest visit before sale (millis)
                                    <Header.Subheader>
                                        The earliest a visit can be before sale to be considered candidate
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width='5'>
                                <Header>
                                    <Input transparent disabled={isLoading} loading={isLoading} placeholder={'default'} value={visit_before_transaction_match} onChange={(e) => this.onParamEdit('visit_before_transaction_match',e)} style={InputStyle} />
                                </Header>
                                Operational value: {currentValues.visit_before_transaction_match}ms
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    <Segment>
                        <Grid>
                            <Grid.Column width='11'>
                                <Header>
                                    Latest visit after sale (millis)
                                    <Header.Subheader>
                                        The latest a visit can be after sale to be considered candidate
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width='5'>
                                <Header>
                                    <Input transparent disabled={isLoading} loading={isLoading} placeholder={'default'} value={visit_after_transaction_match} onChange={(e) => this.onParamEdit('visit_after_transaction_match',e)} style={InputStyle} />
                                </Header>
                                Operational value: {currentValues.visit_after_transaction_match}ms
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Segment.Group>
                <Button floated='right' color='orange' onClick={() => this.onSave()}> Save </Button>
            </Segment>
        );
    }
}
export default compose(
    withAdminAuthorization,
    withFirebase,
)(RemoteConfigManager);