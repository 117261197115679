import React, { Component } from 'react';
//import { PropTypes } from 'prop-types';
import Moment from 'moment-timezone';
import { Segment, Header, Button, Grid, Label } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import { ReportStatus, ReportStatusName, ReportType } from '../../../constants/reports';
import { PersonRoles } from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

import './reportHeaderFooter.css';

const ReportMetadata = (props) => {
    let { contentCount, reportData, children } = props;
    if (children) {
        throw new Error("'ReportMetadata' cannot render children components");
    }
    return (
        <Grid columns={2} stackable>
            <Grid.Column width="13" textAlign='left'>
                <Header as='h3'>
                    <Header.Content>
                        {reportData.type === ReportType.visits_with_sales ? 'Visits' : 'Transactions'} for {reportData.name()}
                    </Header.Content>
                    <Header.Subheader className="sub-metadata">
                        Total {contentCount} {`${reportData.type === ReportType.visits_with_sales ? 'visit' : 'transaction'}${contentCount !== 1 && 's'}`}
                    </Header.Subheader>
                    <Header.Subheader className="sub-metadata">
                        Created at {Moment(reportData.created.date.toDate()).tz(Moment.tz.guess(true)).format('l LT')}
                    </Header.Subheader>
                    <Header.Subheader className="sub-metadata">
                        Last saved at {Moment(reportData.lastSaved.date.toDate()).format('l LT')}
                    </Header.Subheader>
                    {reportData.submittedBy && <Header.Subheader className="sub-metadata">
                        Submitted by {reportData.submittedBy}
                    </Header.Subheader>}
                </Header>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" textAlign="right" width="3">
                <Label basic circular color={reportData.statusColor()} >{ReportStatusName[reportData.status]}</Label>
            </Grid.Column>
        </Grid>
    );
};

const ReportControls = (props) => {
    let { filter, onFilterToggle, reportData, isFeedbackDisabled, handleChangeStatusModal, toggleFeedbackSummary, isError, filterType, children } = props;

    const FILTER_TYPE = {
        rolesFilter: 'roles',
        dateFilter: 'date',
    }

    if (children) {
        throw new Error("'ReportControls' cannot render children components");
    }
    return (
        <Grid >
            <Grid.Column width={8}>
                {reportData.status !== ReportStatus.archived && <Button color='orange' disabled={isError} onClick={handleChangeStatusModal}>{reportData.statusAction()}</Button>}
                {toggleFeedbackSummary && <Button basic disabled={isFeedbackDisabled} color="grey" onClick={toggleFeedbackSummary}>Feedback Summary</Button>}
            </Grid.Column>
            {filter && <Grid.Column width={8}>
                {filterType === FILTER_TYPE.rolesFilter &&
                    <Button.Group size='tiny' floated='right'>
                        <Button basic={!filter.roles[PersonRoles.default]} color='grey' onClick={() => onFilterToggle(PersonRoles.default)}>Identified</Button>
                        <Button basic={!filter.roles[PersonRoles.anonymous]} color='grey' onClick={() => onFilterToggle(PersonRoles.anonymous)}>Anonymous</Button>
                        <Button basic={!filter.roles[PersonRoles.employee]} color='grey' onClick={() => onFilterToggle(PersonRoles.employee)} >Employee</Button>
                    </Button.Group>
                }
                {filterType === FILTER_TYPE.dateFilter &&
                    <div>
                        <input type="range" min="1" max="100" defaultValue="50" className="date-slider" />
                    </div>
                }
            </Grid.Column>}
        </Grid >
    )
};

class ReportBar extends Component {
    state = {}
    static Metadata = ReportMetadata;
    static Controls = ReportControls;
    render() {
        const { bottom = false, children } = this.props;
        return (
            <Segment>
                {bottom && children}
                <p><Link to={ROUTES.REPORTS}>Back to Reports</Link></p>
                {!bottom && children}
            </Segment>
        );
    }
}

export default ReportBar;