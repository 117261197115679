import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Table, Accordion, Segment } from 'semantic-ui-react';
import MissedVisitsList from './missedVisitsList';
import AddVisistItem from './addVisitsItem';

class MissedVisits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            missedCustomers: this.getMissedCustomers(props.feedbackData, props.transactions, props.candidates),
        }
    }

    static propTypes = {
        candidates:   PropTypes.object.isRequired,      
        transactions: PropTypes.object.isRequired,
        feedbackData: PropTypes.object.isRequired,     //feedbackData: We compare each visit's candidtaes against the feedback data. So this is the data.......
        missedVisits: PropTypes.array,        
        setMissedVisits: PropTypes.func.isRequired,      //getMissedVisits: Gets a function that we would call with the missed visits as a param
        reportData: PropTypes.object.isRequired,      //reportData: The data about the report to attach to the missedVisits
    };

    setCustomerEntry = (customer, customerIndex) => {
        const { missedCustomers } = this.state;
        const { reportData } = this.props;

        customer.reportDate = reportData.scope.timeScope.from;
        customer.reportLocation = {
            id: reportData.scope.location.id,
            name: reportData.scope.location.name,
            timezone: reportData.scope.location.timezone,
        };
        customer.reportId = reportData.id;

        let setCustomers = missedCustomers;
        if (!!setCustomers[customerIndex])
            setCustomers[customerIndex] = customer;
        else
            setCustomers.push(customer)
        this.setState({ missedCustomers: setCustomers });
        this.props.setMissedVisits(setCustomers);
    }

    unsetCustomerEntry = (customerIndex) => {
        const { missedCustomers } = this.state;
        let setCustomers = missedCustomers;
        setCustomers.splice(customerIndex, 1);
        this.setState({ missedCustomers: setCustomers });
        this.props.setMissedVisits(setCustomers);
    }

    getMissedCustomers = (feedbackData, transactions, candidates) => {
        let missedCustomers = [];

        // take all tarnsactions that were assigned to a visit
        var assignedTransactions = [];
        Object.keys(feedbackData).forEach(key => {
            const feedbackItem = feedbackData[key];
            if (feedbackItem.data && feedbackItem.data.candidateId) {
                assignedTransactions.push(feedbackItem.data.candidateId);
            }
        });

        // find all candidates that were auto identified
        Object.keys(candidates).forEach(visitId => {
            candidates[visitId].forEach(candidate => {
                if (candidate.strongMatch && (!feedbackData[visitId] || !feedbackData[visitId].type)) {
                    assignedTransactions.push(candidate.id);
                }
            })
        })

        Object.keys(transactions).forEach(transId => {
            if (assignedTransactions.findIndex(id => id === transId) < 0) {
                missedCustomers.push(transactions[transId].data);
            }
        });
        return missedCustomers;
    }

    render() {

        const { missedCustomers } = this.state;
        let timezone = this.props.reportData.scope.location.timezone;
        if (missedCustomers) {
            return (
                <Accordion
                    fluid
                    styled
                    panels={[{
                        key: 'missed-visits-panel', title: 'Add Missed Visits', content: {
                            content: (
                                <Segment>
                                    {
                                        missedCustomers && missedCustomers.length !== 0 &&
                                        <Table columns="5" basic="very">
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell width="4">
                                                        Customer Name
                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width="2" textAlign='center'>
                                                        Transaction Time
                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width="8">
                                                        Notes
                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width="2" />
                                                </Table.Row>
                                            </Table.Header>
                                            <MissedVisitsList
                                                missedCustomers={missedCustomers}
                                                timezone={timezone}
                                                setEntry={this.setCustomerEntry}
                                                unsetEntry={this.unsetCustomerEntry} />
                                        </Table>
                                    }
                                    {/* <Header size="tiny">Add a missing visit</Header> */}
                                    <Table columns="3" basic="very">
                                        <Table.Body>
                                            <AddVisistItem
                                                id={-1}
                                                setEntry={this.setCustomerEntry}
                                                unsetEntry={this.unsetCustomerEntry} />
                                        </Table.Body>
                                    </Table>
                                </Segment>
                            )
                        }
                    }]}
                />
            );
        }
        return <>No missed customers</>
    }
}

export default MissedVisits;