import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
      constructor(props) {
        super(props);
  
        this.state = {
          authUser: null,
        };
      }
  
      componentDidMount() {

        this.listener = this.props.firebase.auth.onAuthStateChanged(
          authUser => {
            console.log(`onAuthStateChanged: ${authUser ? authUser.uid : 'no valid user'}`);
            if (authUser) {
              this.props.firebase.auth.currentUser.getIdTokenResult().then(idTokenResult=>{
                console.log(`onAuthStateChanged: fetched custom claims`);
                authUser.isAdmin = !!idTokenResult.claims.admin;
                this.setState({ authUser });
              });
            } else {
              this.setState({ authUser: null })
            }            
          },
          error=>{
            console.log(`ERROR registering onAuthStateChanged:`, error);
          }
        );
        console.log('onAuthStateChanged listner is set');
      }
  
      componentWillUnmount() {
        this.listener();
      }
  
      render() {
        return (
          <AuthUserContext.Provider value={this.state.authUser}>
            <Component {...this.props} />
          </AuthUserContext.Provider>
        );
      }
    }
  
    return withFirebase(WithAuthentication);
  };
  
  export default withAuthentication;