import React from 'react';
import ReactGA from 'react-ga';

import { withFirebase } from '../Firebase';

class SignOutButton extends React.Component {

  doSignOut = () => {
    ReactGA.event({
      category: 'User',
      action: 'sign_out'
    });
    this.props.firebase.doSignOut();
  }

  render() {
    return (
      <div onClick={this.doSignOut}>
        Sign Out
  </div>
    );
  }
}

export default withFirebase(SignOutButton);