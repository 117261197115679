import React, { Component, useState } from 'react';
//import { PropTypes } from 'prop-types';
import { Card, Grid, Header, Table, Segment, Label, Modal, Input, Button } from 'semantic-ui-react';
import Moment from "moment-timezone";

import { compose } from "recompose";
import { withAuthorization } from "../../../Session";
import { withFirebase } from "../../../Firebase";

import "./index.css";
import VisitCandidateView from './visitCandidateView';

const getSelectedFromFeedback = (feedback) => {
    let selectedState = 'none';
    if (feedback && feedback.data && feedback.data.candidateId) {
        selectedState = feedback.data.candidateId;
    }

    return selectedState;
}
class VisitCandidatesBar extends Component {
    constructor(props) {
        super(props);

        const { reportFeedback } = props;

        this.state = {
            selected: getSelectedFromFeedback(reportFeedback),
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { reportFeedback } = nextProps;


        return {
            selected: getSelectedFromFeedback(reportFeedback),
        }
    }

    render() {
        const { visits, editMode, feedbackEdit, sale, visitDataDelegate, fbUpdateCallback } = this.props;
        const { selected } = this.state;

        if (visits.length > 0) {
            return (
                <div className='items-bar'>
                    <VisitCandidateView key='none' selected={selected} onSelectCallback={() => this.setState({ selected: 'none' })} updateFeedback={fbUpdateCallback} sale={sale} editMode={editMode} feedbackEdit={feedbackEdit} />
                    {
                        visits.map(visit => {
                            let visitCandidate = {
                                id: visit.id,
                                data: visitDataDelegate(visit.id),
                                strongMatch: visit.strongMatch || false,
                            }
                            return (
                                <VisitCandidateView key={visit.id}
                                    editMode={editMode}
                                    feedbackEdit={feedbackEdit}
                                    visitCandidate={visitCandidate}
                                    sale={sale}
                                    selected={selected}
                                    onSelectCallback={() => this.setState({ selected: visit.id })}
                                    updateFeedback={fbUpdateCallback} />)
                        })
                    }
                </div>
            );
        } else {
            return (
                <Segment>
                    <Header as='h4' textAlign="center">
                        No visits match this transaction
            </Header>
                </Segment>
            );
        }
    }
}

class TransactionReportTableItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            candidateSelected: null,
        }
    }

    updateFeedbackOnCandidateSelected = (feedbackObj, saleId) => {
        if (!feedbackObj) {
            this.setState({ candidateSelected: null }, () =>
                this.props.firebase.reports.visitFeedback.remove(this.props.reportId, saleId).catch(error => {
                    console.error('Report feedback has NOT been registerd', this.props.reportId, saleId, feedbackObj, error);
                })
            );
        }
        else {
            this.setState({ candidateSelected: saleId }, () =>
                this.props.firebase.reports.visitFeedback.set(this.props.reportId, saleId, feedbackObj).catch(error => {
                    console.error('Report feedback has NOT been registerd', this.props.reportId, saleId, feedbackObj, error);
                })
            );
        }
    }

    render() {
        const { sale, visitsCandidates, visitDataDelegate, reportData, editMode, reportFeedback, feedbackEdit } = this.props;
        const saleData = sale.data;
        const location = reportData.scope.location;
        const saleLines = saleData.saleLines;

        return (
            <Card fluid>
                <Card.Content>
                    <SaleHeader saleMoment={Moment(saleData.timestamp.toDate()).tz(location.timezone).format('l LT')} saleData={saleData} location={location} canEdit={feedbackEdit} />
                </Card.Content>
                <Card.Content>
                    <Card.Description>
                        <Grid>
                            <Grid.Column width='4' style={{ height: visitsCandidates.length > 0 ? '340px' : '220px', overflowY: 'auto' }}>
                                <Table basic='very' celled unstackable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width='14'>Product</Table.HeaderCell>
                                            <Table.HeaderCell width='2'>Price</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {saleLines &&
                                            saleLines.map((item, index) => (
                                                <Table.Row key={index}>
                                                    <Table.Cell>
                                                        {item.productName}
                                                    </Table.Cell>
                                                    <Table.Cell>{item.amount.toLocaleString('en-US', { style: "currency", currency: "USD" })}$</Table.Cell>
                                                </Table.Row>
                                            ))
                                        }
                                        <Table.Row>
                                            <Table.Cell>
                                                <Header as='h4'>
                                                    <Header.Content>Total</Header.Content>
                                                </Header>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Header as='h4'>
                                                    <Header.Content>{saleData.amount.toLocaleString('en-US', { style: "currency", currency: "USD" })}$</Header.Content>
                                                </Header>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                            <Grid.Column width='12'>
                                {visitsCandidates && <VisitCandidatesBar editMode={editMode} feedbackEdit={feedbackEdit} visits={visitsCandidates} sale={sale} visitDataDelegate={visitDataDelegate} fbUpdateCallback={this.updateFeedbackOnCandidateSelected} reportFeedback={reportFeedback[sale.id]} />}
                            </Grid.Column>
                        </Grid>
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    }
}

const onRenameSubmit = (closeModalCallback, newName) => {
    closeModalCallback(false);
    //123
}

const SaleHeader = props => {
    const [newNameState, setNewNameState] = useState(props.saleData.customerName);
    const [isModalOpen, setIsModalOpenState] = useState(false);
    return (
        <React.Fragment>
            <Card.Header>
                {props.saleMoment} | {props.saleData.customerName} &nbsp; 
                { false && props.canEdit &&
                <Modal open={isModalOpen} trigger={<Label basic horizontal style={{ cursor: 'pointer' }} onClick={() => setIsModalOpenState(true)}>Edit Name</Label>}>
                    <Modal.Header>Rename Customer</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>Rename this customer</Header>
                            <Input value={newNameState} placeholder={props.saleData.customerName} onChange={e => setNewNameState(e.target.value)} icon='user circle' iconPosition='left' />
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic onClick={() => { setNewNameState(props.saleData.customerName); setIsModalOpenState(false); }}>Cancel</Button>
                        <Button color='orange' onClick={() => onRenameSubmit(setIsModalOpenState, newNameState)}>Save</Button>
                    </Modal.Actions>
                </Modal>}
            </Card.Header>
            <Card.Meta>{props.saleData.saleLink ? <a href={props.saleData.saleLink} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'underline' }}>Transaction #{props.saleData.saleCustomerId}</a> : <>Transaction #{props.saleData.saleCustomerId}</>} | {props.location.name}, {props.location.region} </Card.Meta>
        </React.Fragment>
    );
}

const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withFirebase
)(TransactionReportTableItem);