import React, { Component } from 'react';
import { compose } from "recompose";

import { Table } from "semantic-ui-react";
import Moment from "moment-timezone";

import { TableFilter } from '../../UI';
import ReportListItem from "./reportListItem";

import { ReportStatusName, ReportStatusColor } from "../../../constants/reports";
import { withLoading, withPaginated } from '../../Lists';


class ReportsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            column: null,
            data: props.reports,
            direction: null,
            statusSortingOptions: [],
            locationSortingOptions: [],
            filters: {},
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const allStatuses = [...new Set(nextProps.reports.map(report => report.data.status))];
        const allLocations = [...new Set(nextProps.reports.map(report => report.data.scope.location.name))];
        let tempStatsOptions = [];
        allStatuses.forEach(status => {
            tempStatsOptions.push(
                {
                    key: status,
                    text: ReportStatusName[status],
                    value: status,
                    icon: { color: ReportStatusColor[status], name: "circle" },
                }
            );
        });
        let tempLocsOptions = [];
        allLocations.forEach(location => {
            tempLocsOptions.push(
                {
                    key: location,
                    text: location,
                    value: location,
                    icon: { name: 'map marker alternate' },
                }
            );
        });

        //Sets the state
        return {
            data: nextProps.reports,
            statusSortingOptions: tempStatsOptions,
            locationSortingOptions: tempLocsOptions,
        };
    }

    sortStatus = (a, b) => {
        if (a.data.status < b.data.status) {
            return -1;
        }
        if (a.data.status > b.data.status) {
            return 1;
        }
        return 0;
    }

    sortDate = (a, b) => {
        if (a.data.scope.timeScope.from < b.data.scope.timeScope.from) {
            return -1;
        }
        if (a.data.scope.timeScope.from > b.data.scope.timeScope.from) {
            return 1;
        }
        return 0;
    }

    sortLocation = (a, b) => {
        if (a.data.scope.location.name < b.data.scope.location.name) {
            return -1;
        }
        if (a.data.scope.location.name > b.data.scope.location.name) {
            return 1;
        }
        return 0;
    }

    sortSubmittedBy = (a, b) => {
        if (!a.data.submittedBy) {
            return 1;
        }
        if (!b.data.submittedBy) {
            return -1;
        }
        if (a.data.submittedBy < b.data.submittedBy) {
            return -1;
        }
        if (a.data.submittedBy > b.data.submittedBy) {
            return 1;
        }
        return 0;
    }


    handleSort = clickedColumn => () => {
        const { column, data, direction } = this.state;

        if (column !== clickedColumn) {
            switch (clickedColumn) {
                case 'status':
                    this.setState({
                        column: clickedColumn,
                        data: data.sort(this.sortStatus),
                        direction: 'ascending',
                    });
                    break;
                case 'date':
                    this.setState({
                        column: clickedColumn,
                        data: data.sort(this.sortDate),
                        direction: 'ascending',
                    });
                    break;
                case 'location':
                    this.setState({
                        column: clickedColumn,
                        data: data.sort(this.sortLocation),
                        direction: 'ascending',
                    });
                    break;
                case 'submittedBy':
                    this.setState({
                        column: clickedColumn,
                        data: data.sort(this.sortSubmittedBy),
                        direction: 'ascending',
                    });
                    break;
                default:
                    break;
            }

            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    onFilter = (filter, field) => {
        let filters = this.state.filters;
        filters[field] = filter;
        this.setState({ filteres: filters });
    }

    render() {
        let { onStatusChange, onStatusRevert, onReportDelete, flagReport } = this.props;
        const { column, data, direction, statusSortingOptions, locationSortingOptions, filters } = this.state;
        let displayData = data;
        if (filters.status) {
            displayData = displayData.filter(report => filters.status.includes(report.data.status));
        }
        if (filters.location) {
            displayData = displayData.filter(report => filters.location.includes(report.data.scope.location.name));
        }
        if (filters.date) {
            displayData = displayData.filter(report => Moment.tz(filters.date, report.data.scope.location.timezone).utc().toDate().getTime() === report.data.scope.timeScope.from.toDate().getTime());
        }

        return (
            <Table attached unstackable sortable basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell sorted={column === 'status' ? direction : null} onClick={this.handleSort('status')}>
                            Status <TableFilter field="status" options={statusSortingOptions} onFilter={filters => this.onFilter(filters, 'status')} />
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={column === 'date' ? direction : null} onClick={this.handleSort('date')}>
                            Date <TableFilter field="date" onFilter={filters => this.onFilter(filters, 'date')} />
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={column === 'location' ? direction : null} onClick={this.handleSort('location')}>
                            Location <TableFilter field="location" options={locationSortingOptions} onFilter={filters => this.onFilter(filters, 'location')} />
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={column === 'submittedBy' ? direction : null} onClick={this.handleSort('submittedBy')}>Submitted By</Table.HeaderCell>
                        <Table.HeaderCell colSpan="2" />
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {displayData.map(report => (
                        <ReportListItem key={report.id}
                            id={report.id}
                            data={report.data}
                            onStatusChange={onStatusChange}
                            onStatusRevert={onStatusRevert}
                            onDelete={onReportDelete}
                            flagReport={flagReport}
                        />
                    ))}
                </Table.Body>
            </Table>
        );
    }
}
const paginatedCondition = props =>
    props.page !== null && !props.isLoading && props.isError;


const loadingCondition = props =>
    props.isLoading;


export default compose(
    withPaginated(paginatedCondition),
    withLoading(loadingCondition)
)(ReportsTable);