import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import UIUtil from '../InterfaceUtilites';

import "./style/richParagraph.scss";

const DefinedClassNames = {
    bold: 'bold',
}

const Title = props => (
    <div className='title'>{props.children}</div>
)

class RichParagraph extends Component {
    static propTypes = {
        bold: PropTypes.bool,      //bold: Shows the text with bold font weight
    };

    static Title = Title;

    state = {}
    render() {
        let elementManagedClasses = "";
        const { bold, children, style } = this.props;
        if (bold) {
            elementManagedClasses = UIUtil.ClassManager.appendClass(elementManagedClasses, DefinedClassNames.bold);
        }
        return (
            <div className={`rich-paragraph${elementManagedClasses}`} style={style}>{children}</div>
        );
    }
}

export default RichParagraph;