import React, { Component } from 'react';
import { compose } from "recompose";
import { withAdminAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";

import { Segment, Header, Table, Icon, Menu, Message } from 'semantic-ui-react';
import ViewMissedVisitsList from './viewMissedVisitsList';


const applyPagnationResult = result => prevState => ({
    missedVisits: [...prevState.missedVisits, ...result.missedVisits],
    nextPage: result.nextPage,
    isError: false,
    isLoading: false
});

const applySetError = error => prevState => ({
    isError: true,
    isLoading: false,
    errorMsg: error,
});

class ViewMissedVisits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            missedVisits: [],
            nextPage: null,
            isLoading: true,
            isError: false,
            errorMsg: "",
        }
    }

    onPaginationSubmit = () => {
        if (!!this.state.nextPage) {
            this.setState({ isLoading: true });
            this.state
                .nextPage()
                .then(results => {
                    this.setState(applyPagnationResult(results));
                })
                .catch(error => {
                    console.error("Error fetching visits", error);
                    this.setState(applySetError(error.message));
                });
        }
    };

    componentDidMount() {
        let { firebase } = this.props;
        firebase.reports.missedVisits.fetch(70).then(data => {
            this.setState(applyPagnationResult(data));
        }).catch(error => {
            this.setState(applySetError(error.message));
        });
    }

    render() {
        const { missedVisits, isLoading, isError, errorMsg, nextPage } = this.state;
        return (
            <Segment basic>
                <Segment>
                    <Header as='h2'>Missed Visits</Header>
                </Segment>
                {!isError &&
                    <ViewMissedVisitsList
                        missedVisits={missedVisits}
                        isLoading={isLoading}
                        nextPage={nextPage}
                        onPaginatedSearch={this.onPaginationSubmit} />
                }
                {!isLoading && !isError && missedVisits.length === 0 && <NoResults />}
                {!isLoading && isError && <ErrorMessage errorMsg={errorMsg} />}
                {!isError && <Table attached="bottom">
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='6'>
                                <Menu pagination>
                                    <Menu.Item as='a' disabled={!nextPage} onClick={this.onPaginationSubmit} icon>
                                        <Icon name='chevron down' />
                                    </Menu.Item>
                                </Menu>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>}
            </Segment>
        );
    }
}

const ErrorMessage = props => {
    return (
        <Segment basic>
            <Message negative>
                <Message.Header>
                    OOPS! There was an error
                </Message.Header>
                <Message.Content>
                    {props.errorMsg}
                </Message.Content>
            </Message>
        </Segment>
    );
}

const NoResults = props => {
    return (
        <Segment basic>
            <Message
                info
                icon='info circle'
                header="No missed visits to show"
                content='Missed visits will be shown here when added by the store'
            />
        </Segment>
    );
}

export default compose(
    withAdminAuthorization,
    withFirebase
)(ViewMissedVisits);