import app from 'firebase/app';
import 'firebase/firestore';

const COLL_NAME_LOCATION_INPUT_SOURCES = 'locationInputSources';
const COLL_NAME_LOCATIONS = 'locations';
const COLL_NAME_STATUS = 'inputSourceStatus';
const DOC_NAME_STATUS = '1';

class SystemStatus {
    constructor(props) {
        this.db = app.firestore();
    }

    fetchAllInputSources() {
        let query = this.db;

        query = query.collectionGroup(COLL_NAME_LOCATION_INPUT_SOURCES);
        return query.get().then(snapshot => {
            //Assign results
            const devices = snapshot.docs.map(device => ({ id: device.id, data: device.data() }));
            //Return query results
            return devices;
        });

    }

    updateDeviceIP(deviceId, devLoc, newIP) {
        let deviceRef = this.db.collection(COLL_NAME_LOCATIONS).doc(devLoc).collection(COLL_NAME_LOCATION_INPUT_SOURCES).doc(deviceId);
        //Add IP validation here
        return deviceRef.update({ ipAddr: newIP }).then(() => {
            return true;
        }).catch(error => {
            throw error;
        });
    }

    listenToStatusChange(deviceId, locationId, onChange, onError) {
        let deviceStatusRef = this.db
            .collection(COLL_NAME_LOCATIONS)
            .doc(locationId)
            .collection(COLL_NAME_LOCATION_INPUT_SOURCES)
            .doc(deviceId)
            .collection(COLL_NAME_STATUS)
            .doc(DOC_NAME_STATUS);

        return deviceStatusRef.onSnapshot(statusDoc => {
            onChange({ deviceId: deviceId, locationId: locationId, status: statusDoc.data() });
        }, error => {
            onError({ deviceId: deviceId, locationId: locationId, error: error });
        });

    }

}

export default SystemStatus;