import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Table } from 'semantic-ui-react';
import ViewMissedVisitItem from './viewMissedVisitsItem';
import { compose } from "recompose";
import { withLoading, withPaginated } from '../../Lists';
import { sortCustomerName, sortDate, sortLocation, sortSale, sortSubmittedBy } from './sortables';
import { TableFilter } from '../../UI';
import Moment from "moment-timezone";

class ViewMissedVisitsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.missedVisits,
            column: null,
            direction: null,
            locationSortingOptions: [],
            submitterSortingOptions: [],
            customerSortingOptions: [],
            filters: {},
        }
    }

    static propTypes = {
        missedVisits: PropTypes.array.isRequired,      //missedVisits: The missed visits' data
        isLoading: PropTypes.bool.isRequired,      //isLoading: is the page in a loading state
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const allLocations = [...new Set(nextProps.missedVisits.map(mVisit => mVisit.data.reportLocation.name))];
        const allSubmitters = [...new Set(nextProps.missedVisits.map(mVisit => mVisit.data.submittedBy))];
        const allCustomers = [...new Set(nextProps.missedVisits.map(mVisit => mVisit.data.customerName))];
        let tempLocationOptions = [];
        allLocations.forEach(location => {
            tempLocationOptions.push(
                {
                    key: location,
                    text: location,
                    value: location,
                    icon: { name: 'map marker alternate' },
                }
            );
        });
        let tempSubmittersOptions = [];
        allSubmitters.forEach(submitter => {
            tempSubmittersOptions.push(
                {
                    key: submitter,
                    text: submitter,
                    value: submitter,
                    icon: { name: 'circle' },
                }
            );
        });
        let tempCustomersOptions = [];
        allCustomers.forEach(customer => {
            tempCustomersOptions.push(
                {
                    key: customer,
                    text: customer,
                    value: customer,
                    icon: { name: 'user circle' },
                }
            );
        });
        //Sets the state
        return {
            //column: null,
            data: nextProps.missedVisits,
            locationSortingOptions: tempLocationOptions,
            submitterSortingOptions: tempSubmittersOptions,
            customerSortingOptions: tempCustomersOptions,
            //direction: null,
        };
    }

    handleSort = clickedColumn => {
        const { column, data, direction } = this.state;
        if (column !== clickedColumn) {
            switch (clickedColumn) {
                case 'date':
                    this.setState({
                        column: clickedColumn,
                        data: data.sort(sortDate),
                        direction: 'ascending',
                    });
                    break;
                case 'location':
                    this.setState({
                        column: clickedColumn,
                        data: data.sort(sortLocation),
                        direction: 'ascending',
                    });
                    break;
                case 'sub_by':
                    this.setState({
                        column: clickedColumn,
                        data: data.sort(sortSubmittedBy),
                        direction: 'ascending',
                    });
                    break;
                case 'c_name':
                    this.setState({
                        column: clickedColumn,
                        data: data.sort(sortCustomerName),
                        direction: 'ascending',
                    });
                    break;
                case 'sale':
                    this.setState({
                        column: clickedColumn,
                        data: data.sort(sortSale),
                        direction: 'ascending',
                    });
                    break;
                default:
                    break;
            }

            return
        }
        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }

    onFilter = (filter, field) => {
        let filters = this.state.filters;
        filters[field] = filter;
        this.setState({ filteres: filters });
    }

    render() {
        const { data, column, direction, filters, locationSortingOptions, submitterSortingOptions, customerSortingOptions } = this.state;
        let displayData = data;
        if (filters.date) {
            displayData = displayData.filter(mVisit => Moment.tz(filters.date, mVisit.data.reportLocation.timezone).utc().toDate().getTime() === mVisit.data.reportDate.toDate().getTime());
        }
        if (filters.location) {
            displayData = displayData.filter(mVisit => filters.location.includes(mVisit.data.reportLocation.name));
        }
        if (filters.submitter) {
            displayData = displayData.filter(mVisit => filters.submitter.includes(mVisit.data.submittedBy));
        }
        if (filters.customer) {
            displayData = displayData.filter(mVisit => filters.customer.includes(mVisit.data.customerName));
        }
        return (
            <Table unstackable compact sortable celled attached='top'>
                <Table.Header>
                    <Table.Row textAlign="center">
                        <Table.HeaderCell
                            width='1'
                            sorted={column === 'date' ? direction : null}
                            onClick={() => this.handleSort('date')}>
                            Date  <TableFilter field="date" onFilter={filters => this.onFilter(filters, 'date')} />
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width='2'
                            sorted={column === 'location' ? direction : null}
                            onClick={() => this.handleSort('location')}>
                            Location <TableFilter field="location" options={locationSortingOptions} onFilter={filters => this.onFilter(filters, 'location')} />
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width='2'
                            sorted={column === 'sub_by' ? direction : null}
                            onClick={() => this.handleSort('sub_by')}>
                            Submitted By <TableFilter field="submitter" options={submitterSortingOptions} onFilter={filters => this.onFilter(filters, 'submitter')} />
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width='3'
                            sorted={column === 'c_name' ? direction : null}
                            onClick={() => this.handleSort('c_name')}>
                            Customer Name <TableFilter field="customer" options={customerSortingOptions} onFilter={filters => this.onFilter(filters, 'customer')} />
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width='3'
                            sorted={column === 'sale' ? direction : null}
                            onClick={() => this.handleSort('sale')}>
                            Sale
                        </Table.HeaderCell>
                        <Table.HeaderCell width='5'>Note</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {displayData.map(mVisit => (
                        <ViewMissedVisitItem key={mVisit.id} missedVisitData={mVisit.data} />
                    ))}
                </Table.Body>
            </Table>
        );
    }
}

const paginatedCondition = props =>
    props.page !== null && !props.isLoading && props.isError;


const loadingCondition = props =>
    props.isLoading;


export default compose(
    withPaginated(paginatedCondition),
    withLoading(loadingCondition)
)(ViewMissedVisitsList);