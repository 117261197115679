import app from 'firebase/app';
import 'firebase/firestore';
import Moment from 'moment-timezone';

import VisitsData from './visitsData';
import SalesData from './salesData';

import { ReportStatus, ReportStatusName, ReportType } from '../../constants/reports';

const COLL_NAME_REPORTS = "reports";
const COLL_NAME_REPORTS_FEEDBACK = "reportsVisitFeedback";
const COLL_NAME_REPORTS_NOTES = "reportsVisitNotes";
const COLL_NAME_REPORTS_MISSED_VISITS = "reportsMissedVisits";
const COLL_NAME_LOCATIONS = "locations";
const COLL_NAME_CANDIDATES_VWS = 'salesCandidates';
const COLL_NAME_CANDIDATES_SWV = 'visitsCandidates';

const isValidStateTransition = (currentState, newState) => {
    switch (currentState) {
        case ReportStatus.new:
            return newState === ReportStatus.open;
        case ReportStatus.open:
            return newState === ReportStatus.complete;
        case ReportStatus.complete:
            return newState === ReportStatus.archived;
        case ReportStatus.archived:
            return false;
        default:
            return false;
    }
}

const isValidRevertTransition = (currentState, newState) => {
    switch (currentState) {
        case ReportStatus.new:
            return false;
        case ReportStatus.open:
            return newState === ReportStatus.new;
        case ReportStatus.complete:
            return newState === ReportStatus.open;
        case ReportStatus.archived:
            return false;
        default:
            return false;
    }
}


class ReportAbsInterface {
    nextStatus = () => {
        switch (this.status) {
            case ReportStatus.new:
                return ReportStatus.open;
            case ReportStatus.open:
                return ReportStatus.complete;
            case ReportStatus.complete:
                return ReportStatus.archived;
            case ReportStatus.archived:
                return ReportStatus.archived;
            default:
                return ReportStatus.archived;
        }
    }


    previousStatus = () => {
        switch (this.status) {
            case ReportStatus.archived:
                return ReportStatus.complete;
            case ReportStatus.complete:
                return ReportStatus.open;
            case ReportStatus.open:
                return ReportStatus.new;
            case ReportStatus.new:
                return ReportStatus.new;
            default:
                return ReportStatus.new;
        }
    }

    statusColor = (status) => {
        let value = status || this.status;

        switch (value) {
            case ReportStatus.new:
                return 'grey';
            case ReportStatus.open:
                return 'orange';
            case ReportStatus.complete:
                return 'green';
            case ReportStatus.archived:
                return 'black';
            default:
                return 'black';
        }
    }

    statusAction = () => {
        switch (this.status) {
            case ReportStatus.new:
                return 'Send';
            case ReportStatus.open:
                return 'Submit';
            case ReportStatus.complete:
                return 'Archive';
            case ReportStatus.archived:
                return 'Action';
            default:
                return 'Action';
        }
    }

    statusHeader = () => {
        switch (this.status) {
            case ReportStatus.new:
                return `Send report to ${this.scope.location.name}?`
            case ReportStatus.open:
                return `Submit report?`
            case ReportStatus.complete:
                return `Finalize and close report?`
            case ReportStatus.archived:
                return 'Action';
            default:
                return 'Action';
        }
    }

    canRevert = () => {
        return [ReportStatus.open, ReportStatus.complete].includes(this.status);
    }

    url = () => {
        return `${window.location.protocol}//${window.location.hostname}/reports/${this.id}`;
    }

    name = () => {
        return Moment(this.scope.timeScope.from.toDate()).utc().format('LL') + ", " + this.scope.location.name + ", " + this.scope.location.region;
    }


}

class ReportVisitNotes {
    constructor() {
        this.db = app.firestore().collection(COLL_NAME_REPORTS);
    }

    listenAll(reportId, onNote) {
        let listen = this.db.doc(reportId).collection(COLL_NAME_REPORTS_NOTES);
        return listen.onSnapshot(querySnapshot => {
            let notes = {}
            querySnapshot.forEach(doc => {
                notes[doc.id] = doc.data();
            });

            onNote(notes);
        }, error => {
            console.error('ReportVisitNotes.listenAll():', error);
        });
    }

    set(reportId, visitId, noteText) {
        let note = {
            metadata: {
                timestamp: app.firestore.FieldValue.serverTimestamp(),
                author: app.auth().currentUser.uid,
            },
            text: noteText
        }
        if (noteText === "")
            return this.db.doc(reportId).collection(COLL_NAME_REPORTS_NOTES).doc(visitId).delete();
        return this.db.doc(reportId).collection(COLL_NAME_REPORTS_NOTES).doc(visitId).set(note);
    }

}

class ReportVisitFeedback {
    constructor() {
        this.fsdb = app.firestore().collection(COLL_NAME_REPORTS);
    }

    async getAll(reportId) {
        try {
            const querySS = await this.fsdb.doc(reportId).collection(COLL_NAME_REPORTS_FEEDBACK).get();
            let result = {};
            querySS.docs.forEach(doc => result[doc.id] = doc.data());
            return result;
        }
        catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    set(reportId, identifier, feedback) {
        feedback.metadata = {
            timestamp: app.firestore.FieldValue.serverTimestamp(),
            user: app.auth().currentUser.uid,
        }
        return this.fsdb.doc(reportId).collection(COLL_NAME_REPORTS_FEEDBACK).doc(identifier).set(feedback);
    }

    remove(reportId, identifier) {
        return this.fsdb.doc(reportId).collection(COLL_NAME_REPORTS_FEEDBACK).doc(identifier).delete();
    }

    updateOther(reportId, visitId, other) {
        return this.fsdb.doc(reportId).collection(COLL_NAME_REPORTS_FEEDBACK).doc(visitId).update({ data: other });
    }

    listenToFeedback(reportId, onFeedbackUpdate) {
        this.fsdb.doc(reportId).collection(COLL_NAME_REPORTS_FEEDBACK).onSnapshot(onFeedbackUpdate, error => {
            console.error('ReportVisitFeedback: listen to feedback:', error);
        });
    }

    getDbReference() {
        return { ref: this.fsdb, collection: COLL_NAME_REPORTS_FEEDBACK };
    }
}

class ReportCandidates {
    constructor() {
        this.db = app.firestore().collection(COLL_NAME_REPORTS);
    }

    listenToCandidates(reportID, reportType, onUpdate, onError) {
        const subcollection = (reportType === ReportType.visits_with_sales) ? COLL_NAME_CANDIDATES_VWS : COLL_NAME_CANDIDATES_SWV;
        this.db.doc(reportID).collection(subcollection).onSnapshot(snapshot => {
            onUpdate(snapshot.docs);
        }, error => {
            //...
        });
    }
    fetchCandidates(reportID, isVWS) {
        const subcollection = isVWS ? COLL_NAME_CANDIDATES_VWS : COLL_NAME_CANDIDATES_SWV;
        return this.db.doc(reportID).collection(subcollection).get().then(snapshot => {
            return snapshot.docs;
        })
    }
}

class ReportMissedVisits {
    constructor() {
        this.db = app.firestore();
    }

    addMissedVisits(reportId, missedVisitsArray) {
        let batch = this.db.batch();
        let missedVisitsRef = this.db.collection(COLL_NAME_REPORTS).doc(reportId).collection(COLL_NAME_REPORTS_MISSED_VISITS);
        missedVisitsArray.forEach(missedVisit => {
            let cleanMissedVisit = Object.assign({}, missedVisit);
            delete cleanMissedVisit.isInStore;
            let docRef = missedVisitsRef.doc();
            batch.set(docRef, cleanMissedVisit);
        });
        return batch.commit().catch(error => {
            throw error;
        });
    }

    fetch = (count, options) => {
        return this._fetchPaginated(count, options, null);
    }

    _fetchPaginated = (count, options, cursor) => {
        var nextPage = null, latest;
        let query = this.db;

        query = query.collectionGroup(COLL_NAME_REPORTS_MISSED_VISITS).orderBy('reportDate', 'desc');

        if (cursor) {
            query = query.startAfter(cursor);
        }
        return query.limit(count).get().then(snapshot => {
            //Assign results
            const missedVisits = snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));

            //Handle pagination
            if (snapshot.size === count) {
                let lastItem = snapshot.docs[snapshot.size - 1];
                nextPage = () => this._fetchPaginated(count, options, lastItem);
            }
            latest = snapshot.docs[0];
            //Return query results
            return { missedVisits: missedVisits, nextPage: nextPage, latest: latest };
        });

    }
}

const createDataObject = (doc) => {
    var data = Object.assign(new ReportAbsInterface(), doc.data());
    if (!data.type) // give default value to type for backwards compatability
        data.type = ReportType.visits_with_sales;
    data.id = doc.id;
    return ({ id: doc.id, data: data });
}

class ReportsData {
    constructor() {
        this.fsdb = app.firestore().collection(COLL_NAME_REPORTS);
        this.locdb = app.firestore().collection(COLL_NAME_LOCATIONS);
        this.visitFeedback = new ReportVisitFeedback();
        this.visitNotes = new ReportVisitNotes();
        this.missedVisits = new ReportMissedVisits();
        this.candidates = new ReportCandidates();

        this.visits = new VisitsData();
        this.sales = new SalesData();
    }

    update(reportId, status, options) {
        if (!ReportStatusName[status]) {
            return Promise.reject(new Error(`status ${ReportStatusName[status]} is not a valid report status`));
        }

        return app.firestore().runTransaction(t => {
            return t.get(this.fsdb.doc(reportId)).then(reportDoc => {
                if (!reportDoc.exists) {
                    return Promise.reject(new Error('Trying to transact on non exisiting report'));
                } else if (!isValidStateTransition(reportDoc.data().status, status)) {
                    return Promise.reject(new Error(`${ReportStatusName[reportDoc.data().status]}->${ReportStatusName[status]} is not a valid status transition`));
                } else {
                    let update = {
                        status: status,
                        lastSaved: {
                            by: app.auth().currentUser.uid,
                            date: app.firestore.FieldValue.serverTimestamp(),
                        }
                    };
                    if (options && options.submittedBy) {
                        update.submittedBy = options.submittedBy;
                    }

                    t.update(this.fsdb.doc(reportId), update);
                }
            });
        }).then(() => {
            if (options.missedVisits) {
                options.missedVisits.map(mVisit => {
                    mVisit.submittedBy = options.submittedBy;
                    return mVisit;
                })
                return this.missedVisits.addMissedVisits(reportId, options.missedVisits);
            }
        }).catch(error => {
            console.error(`transaction failure when updating report ${reportId} status to ${status}`, error);
            return Promise.reject(error);
        });
    }

    flag(reportId, message, remove) {
        if (remove) {
            let flag = {
                flag: {
                    isSet: false,
                    message: '',
                }
            }
            return this.fsdb.doc(reportId).update(flag).then(() => {
                return flag;
            }).catch(error => {
                console.error("Error updating (removing) flag: ", error);
            });
        } else {
            let flag = {
                flag: {
                    isSet: true,
                    message: !!message ? message : '',
                }
            }
            return this.fsdb.doc(reportId).update(flag).then(() => {
                return flag;
            }).catch(error => {
                console.error("Error updating (setting) flag: ", error);
            });
        }
    }

    revert(reportId, status) {
        if (!ReportStatusName[status]) {
            return Promise.reject(new Error(`status ${ReportStatusName[status]} is not a valid report status`));
        }

        return app.firestore().runTransaction(t => {
            return t.get(this.fsdb.doc(reportId)).then(reportDoc => {
                if (!reportDoc.exists) {
                    return Promise.reject(new Error('Trying to transact on non exisiting report'));
                } else if (!isValidRevertTransition(reportDoc.data().status, status)) {
                    return Promise.reject(new Error(`${ReportStatusName[reportDoc.data().status]}->${ReportStatusName[status]} is not a valid revert transition`));
                } else {
                    let update = {
                        status: status,
                        lastSaved: {
                            by: app.auth().currentUser.uid,
                            date: app.firestore.FieldValue.serverTimestamp(),
                        }
                    };

                    t.update(this.fsdb.doc(reportId), update);
                }
            })
        }).catch(error => {
            console.error(`transaction failure when reverting report ${reportId} status to ${status}`, error);
            return Promise.reject(error);
        });
    }

    deleteThis = reportId => {
        return this.fsdb.doc(reportId).delete().then(() => {
            return;
        }).catch(error => {
            throw error;
        });
    }

    listenToMetadata(RID, onLoaded, onError) {
        let data;
        return this.fsdb.doc(RID).onSnapshot(report => {
            if (report.exists) {
                data = Object.assign(new ReportAbsInterface(), report.data());
                data.id = RID;
                //TODO: Append scope of existing data to new data on "client"
                onLoaded(data);
            } else {
                onError(new Error(`Looks like "${RID}" is not a valid report id`));
            }
        }, error => {
            console.error(error);
            onError(error);
        });
    }

    listenToContent(reportScope, onVisitsUpdate, onSalesUpdate, onError) {

        const options = {
            locationId: reportScope.location.id,
            startDate: reportScope.timeScope.from,
            endDate: reportScope.timeScope.to,
        }

        const visitOptions = Object.assign({}, options, { includeIdentifications: true });

        let visitListener = this.visits.listenToVisits(visitOptions, onVisitsUpdate, onError);
        let salesListerner = this.sales.listenToSales(options, onSalesUpdate, onError);

        return () => {
            visitListener();
            salesListerner();
        }
    }


    fetchThis = (reportId) => {
        var reportData;
        return this.fsdb.doc(reportId).get().then(reportDoc => {
            if (reportDoc.exists) {
                reportData = createDataObject(reportDoc).data;
                let locationData = this.getLocationData(reportData.scope.location);
                return Promise.resolve(locationData);
            } else {
                throw new Error(`Looks like "${reportId}" is not a valid report id`);
            }
        }).then(locationData => {
            locationData.id = reportData.scope.location;
            reportData.scope.location = locationData;
            return reportData;
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    fetch = (options) => {
        return this._fetchPaginated(options, null);
    }

    _fetchPaginated = (options, cursor) => {

        const _options = Object.assign({}, {
            locations: [],
            statusFilter: null,
            count: null,
        }, options);

        var nextPage = null, latest;
        var reports = [];

        const { count, statusFilter } = _options;
        let singleLocation = _options.locations.length === 1;
        let mulitpleLocations = _options.locations.length > 1;

        let query = this.fsdb;

        if (singleLocation) {
            query = query.where('scope.location', "==", _options.locations[0]);
            if (statusFilter) {
                query = query.where('status', 'in', statusFilter);
            }
            query = query.orderBy('scope.timeScope.from', 'desc');
        } else {
            if (statusFilter) {
                query = query.where('status', "in", statusFilter);
            }

            query = query.orderBy('scope.timeScope.from', 'desc').orderBy('scope.location');
        }

        if (cursor) {
            query = query.startAfter(cursor);
        }

        if (count) {
            query = query.limit(count);
        }
        return query.get().then(snapshot => {

            if (mulitpleLocations) {
                let locFilter = (doc) => _options.locations.includes(doc.data().scope.location);
                reports = snapshot.docs.filter(locFilter).map(createDataObject);
            } else {
                reports = snapshot.docs.map(createDataObject)
            }

            if (snapshot.size === count) {
                let lastItem = snapshot.docs[snapshot.size - 1];
                nextPage = () => this._fetchPaginated(_options, lastItem);
            }
            latest = snapshot.docs[0];
            let locationData = reports.map(entry => this.getLocationData(entry.data.scope.location));
            return Promise.all(locationData);
        }).then(locations => {
            if (locations) {
                reports.forEach((entry, index) => {
                    entry.data.scope.location = locations[index];
                })
            }

            return { reports: reports, nextPage: nextPage, latest: latest };
        }).catch(error => {
            return Promise.reject(error);
        });
    }

    listenToActive(onReportChange, onError, options) {

        const _options = Object.assign({}, {
            locations: [],
            statusFilter: [ReportStatus.open],
        }, options);

        let query = this.fsdb;
        let singleLocation = _options.locations.length === 1;
        let statusFilter = _options.statusFilter.filter(status => status !== ReportStatus.archived);

        if (singleLocation) {
            query = query.where('scope.location', "==", _options.locations[0]);
            if (statusFilter) {
                query = query.where('status', 'in', statusFilter);
            }
            query = query.orderBy('scope.timeScope.from', 'desc');
        } else {
            if (statusFilter) {
                query = query.where('status', "in", statusFilter);
            }

            query = query.orderBy('scope.timeScope.from', 'desc').orderBy('scope.location');
        }

        let listener = query.onSnapshot(snapshot => {
            var reports = [];
            var locationPromises = [];

            snapshot.docChanges().forEach(change => {
                if (_options.locations.includes(change.doc.data().scope.location)) {

                    let reportData = createDataObject(change.doc);
                    reportData.type = change.type;
                    reports.push(reportData);

                    if (change.type === 'added' || change.type === 'modified') {
                        locationPromises.push(this.getLocationData(change.doc.data().scope.location));
                    } else { //change.type === 'modified'
                        locationPromises.push(Promise.resolve());
                    }
                }
            });

            return Promise.all(locationPromises).then(locations => {
                if (locations) {
                    reports.forEach((report, index) => {
                        report.data.scope.location = locations[index];
                    })
                }

                onReportChange(reports);
            })
        }, onError);

        return listener;
    }

    getLocationData(locationId) {
        return this.locdb.doc(locationId).get().then(doc => {
            return doc.data();
        })
            .catch(error => {
                throw error;
            });
    }

    getFeedback() {
        return this.visitFeedback;
    }



}

export default ReportsData;