import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Message, Grid, Icon, Container, Form, TextArea, Button, Modal, Label, Header } from 'semantic-ui-react';

class Note extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noteText: (props.notes && props.notes.text) ? props.notes.text : "",
            isNoteModalShown: false,
        }
    }

    static propTypes = {
        notes: PropTypes.object,            // current notes
        editMode: PropTypes.bool,           //editMode: enables note editing
        onEdit: PropTypes.func,             //onEdit: what happens after a note was edited
        compact: PropTypes.bool,            //compact: Indicates the note should be shown in compact mode
        silent: PropTypes.bool,             //silent: Determins weather the component will show color when there is a note displayed
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState.isNoteModalShown) {
            return { noteText: (nextProps.notes && nextProps.notes.text) ? nextProps.notes.text : "" };
        }
        return null;
    }


    cancelNoteEdit = () => {
        this.setState({ isNoteModalShown: false, noteText: (this.props.notes && this.props.notes.text) ? this.props.notes.text : "" });
    }

    onNoteAdded = note => {
        this.props.onEdit(note)
        this.setState({ isNoteModalShown: false });
    }

    render() {
        let { noteText, isNoteModalShown } = this.state;
        let { editMode, notes, compact, silent } = this.props;
        if (compact) {
            return (
                <Label basic style={{ width: "100%" }} color={noteText && !silent ? "orange" : null}>
                    <Icon name="chevron right" /> {noteText} &nbsp;
                        {
                        editMode &&
                        <Modal open={isNoteModalShown} trigger={<Header as="a" onClick={() => this.setState({ isNoteModalShown: true })} style={{ fontSize: "small" }}>&nbsp;<u>{notes && notes.text && notes.text !== '' ? "(Edit note)" : "Add note"}</u></Header>}>
                            <Modal.Header>Add notes</Modal.Header>
                            <Modal.Content>
                                <Form>
                                    <TextArea onChange={e => this.setState({ noteText: e.target.value })} placeholder='Write down your notes here' style={{ minHeight: 100 }} value={this.state.noteText} />
                                </Form>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button basic color="red" onClick={this.cancelNoteEdit}>Cancel</Button>
                                <Button onClick={() => this.onNoteAdded(noteText)}>Done</Button>
                            </Modal.Actions>
                        </Modal>
                    }
                </Label>
            );
        }
        return (
            <Message>
                <Message.Header>Notes</Message.Header>
                <br />
                <Grid>
                    <Grid.Row>
                        {notes && notes.text &&
                            <Grid.Column verticalAlign="middle" width="11">
                                <Container> <Icon name="chevron right" /> {noteText}</Container>
                            </Grid.Column>
                        }
                        {
                            editMode &&
                            <Grid.Column width={notes && notes.text ? "5" : "16"}>
                                <Modal open={isNoteModalShown} trigger={<Button floated='right' onClick={() => this.setState({ isNoteModalShown: true })}>{notes ? "Edit note" : "Add note"}</Button>}>
                                    <Modal.Header>Add notes</Modal.Header>
                                    <Modal.Content>
                                        <Form>
                                            <TextArea onChange={e => this.setState({ noteText: e.target.value })} placeholder='Write down your notes here' style={{ minHeight: 100 }} value={this.state.noteText} />
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color="red" onClick={this.cancelNoteEdit}>Cancel</Button>
                                        <Button onClick={() => this.onNoteAdded(noteText)}>Done</Button>
                                    </Modal.Actions>
                                </Modal>
                            </Grid.Column>
                        }
                    </Grid.Row>
                </Grid>
            </Message>
        );
    }
}

export default Note;